import React from 'react';
import { View, ScrollView, Pressable, Image, useWindowDimensions } from 'react-native';
import { create, useDeviceContext } from 'twrnc';
import { Heading, PrimaryNav, Span, TextInput, Button, AssetItem, SelectMenu, Modal, Notification, HorizontalRule, AttachmentUploadInput, AddNewAssetButtons } from '../elements';
import { useSpring, animated } from 'react-spring';
import { goPrevScreen, getLabel, getOptionsByLabelGroup, getPropertiesByGroup, truncate, now, date_DMJYYYY, getUnreadNotificationsCount, sort_by, getRouteFilter, updateRouteFilter, processAttachmentInput, uploadAttachment, canIhazIcon } from '../helpers';
import { GlobalContext } from '../global';
import { useToast } from 'react-native-toast-notifications';

function YachtAttachmentsView ({route, navigation})
{
    const AnimatedView = animated(View);
    const tw = create( require(`../../tailwind.config.js`) );
    useDeviceContext(tw);
    const {height, width} = useWindowDimensions();
    const formHeight = height - 100;
    const toast = useToast();

    let {
        apiUrl,
        apiVersion,
        setLastUpdate,
        loggedIn, setLoggedIn,
        userYachts,
        userNotifications
    } = React.useContext(GlobalContext);

    React.useEffect(()=>
    {
        if ( ! loggedIn ) { navigation.navigate('loading'); }
    },[loggedIn, navigation]);

    const [getFilter, setFilter] = React.useState(null);
    const [filterVisible, setFilterVisible] = React.useState(false);

    const labels = React.useRef(null);
    React.useEffect(()=>
    {
        if ( ! labels.current )
        {
            labels.current = {
                _ASSETS: getLabel('ATTACHMENTS'),
                _ASSET: getLabel('ATTACHMENT'),
                _404_TEXT: getLabel('ATTACHMENT_404_TEXT'),

                VIEW: getLabel('VIEW'),
                EDIT: getLabel('EDIT'),
                DELETE: getLabel('DELETE'),
                CANCEL: getLabel('CANCEL'),
                NOTIFICATIONS: getLabel('NOTIFICATIONS'),
                CONTENT_ADD: getLabel('CONTENT_ADD'),
                ADD: getLabel('ADD'),
                UPDATE: getLabel('UPDATE'),
                SORT: getLabel('SORT'),
                ALL: getLabel('ALL'),
                REQUIRED: getLabel('REQUIRED'),
                TYPE: getLabel('TYPE'),
                SELECT: getLabel('SELECT'),

                ATTACHMENT_FILE: getLabel('ATTACHMENT_FILE'),
                ATTACHMENT_TOO_LARGE_BODY: getLabel('ATTACHMENT_TOO_LARGE_BODY'),

                DELETE_CONFIRM_TITLE: getLabel('DELETE_CONFIRM_TITLE'),
                DELETE_CONFIRM_BODY: getLabel('DELETE_CONFIRM_BODY'),
                PERMANENT_ACTION: getLabel('PERMANENT_ACTION'),

                TOAST_ADD: getLabel('TOAST_ADD'),
                TOAST_UPDATE: getLabel('TOAST_UPDATE'),
                TOAST_DELETE: getLabel('TOAST_DELETE'),
            };
        }
        setFilter(getRouteFilter('attachments') || getLabel('ALL'));
    },[]);

    const [notificationCount, setNotificationCount] = React.useState(0);
    React.useEffect(()=>
    {
        setNotificationCount(getUnreadNotificationsCount())
    },[userNotifications]);

    const { yachtID } = route.params;
    const [ yacht, setYacht ] = React.useState(0);

    // ref for photo type
    const photoObjectType = React.useRef('obj_vessel');

    const [primaryMsg, setPrimaryMsg] = React.useState('');
    const [primaryMsgType, setPrimaryMsgType] = React.useState('warning');
    const [modalActive, setModalActive] = React.useState(false);
    const [modalAffectID, setModalAffectID] = React.useState('');

    const [addingNew, setAddingNew] = React.useState(false);
    const [updateAttachmentID, setUpdateAttachmentID] = React.useState(0);

    const [viewingAsset, setViewingAsset] = React.useState(false);
    const [viewingAssetObj, setViewingAssetObj] = React.useState({});

    const [newAssetMsg, setNewAssetMsg] = React.useState('');
    const [newAssetMsgType, setNewAssetMsgType] = React.useState('warning');
    const [newAssetValidation, doNewAssetValidation] = React.useState(false);
    const [getAssets, setAssets] = React.useState([]);

    // attachment specific inputs
    const [attachmentDesc, setAttachmentDesc] = React.useState('');
    const [attachmentType, setAttachmentType] = React.useState(0);
    const [attachmentTypeSelectVisible, setAttachmentTypeSelectVisible] = React.useState(false);
    const newAttachmentInput = React.useRef(null);
    const attachmentData = React.useRef('');
    const attachmentMimeType = React.useRef('');
    const attachmentFileNameInput = React.useRef(null);
    const newAttachmentDescInput = React.useRef(null);
    const attachmentTypeOptions = getOptionsByLabelGroup('types.attachment.types', true);
    const attachmentTypeIcons = getPropertiesByGroup( 'value', 'types.attachment.types', 'icon', false, true );

    // only for this attachments view
    const attachmentTypeFilterOptions = [{value: getLabel('ALL'), label: getLabel('ALL')}].concat(attachmentTypeOptions);

    React.useEffect(() => {
        let assets = [];

        if ( yachtID )
        {
            let yachtObj = false;
            userYachts.every((value, index) => {
                if ( yachtID == value.id ) { setYacht(value); yachtObj = value; return false; }
                return true;
            });

            let attachments = [];

            if ( yachtObj.attachments )
            {
                switch(getFilter)
                {
                    case labels.current?.ALL:
                        updateRouteFilter('attachments', labels.current?.ALL);
                        attachments = [...yachtObj.attachments].sort(sort_by('modified_date', true));
                        break;

                    default:
                        updateRouteFilter('attachments', getFilter);
                        attachments = yachtObj.attachments.filter(function (asset)
                        {
                            return asset.type_id === getFilter;
                        });
                        attachments = [...attachments].sort(sort_by('modified_date', true));
                        break;
                }

                attachments.forEach((asset, index) => {
                    let icon = asset.type_id ? attachmentTypeIcons.find(obj => {return obj.value == asset.type_id})?.icon : 'miscellaneous';
                    let icon_variant = '_white';
                    icon = icon + icon_variant;
                    icon = canIhazIcon( icon ) ? icon : 'miscellaneous' + icon_variant;

                    assets.push(
                        <AssetItem
                            key={asset.key}
                            minHeight={73}
                            color='blue'
                            icon={{ uri: require('../svg/'+icon+'.svg') }}
                            title={ asset.type_id ? attachmentTypeOptions.find(obj => {return obj.value == asset.type_id})?.label : '' }
                            description={ asset.description }
                            // description2={ asset.mime_type }
                            menu={[
                                { name:labels.current?.EDIT, color:'green', onPress: ()=>{
                                    setUpdateAttachmentID(asset.key);
                                    setAttachmentDesc(asset.description); newAttachmentDescInput.current.value = asset.description;
                                    setAttachmentType(asset.type_id);
                                    setAddingNew(true);
                                }},
                                { name:labels.current?.DELETE, color:'red', onPress: ()=>{ setModalAffectID(asset.key); attachmentMimeType.current = asset.mime_type; setModalActive(true) } }
                            ]}
                            onPress={() => {
                                window.open(asset.uri, '_blank');
                            }}
                            viewMore={labels.current?.VIEW}
                        />
                    )
                });
            }
        }

        setAssets(assets);
    },[getFilter, yachtID, userYachts, navigation, setAssets, labels]);


    let addNewPaneAnimation = useSpring(
    {
        from: {
            willChange: 'transform',
            zIndex: 91,
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            backgroundColor: tw.color('gray0'),
            boxShadow: 'rgb(0 0 0 / 50%) 0px 0px 24px',
            transform: 'translateX(110%)',
        },
        to: {
            transform: addingNew ? 'translateX(0%)' : 'translateX(110%)',
        },
        config: addingNew ? { mass: 1, tension: 190, friction: 30 } : { mass: 1, tension: 190, friction: 15 }
    });

    let addNewPaneAnimationDesktop = useSpring(
    {
        from: {
            willChange: 'opacity',
            zIndex: 91,
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            opacity: 0,
            justifyContent: 'center',
            alignItems: 'center',
            pointerEvents: 'none',
            backdropFilter: 'blur(3px)',
        },
        to: {
            opacity: addingNew ? 1 : 0,
            pointerEvents: addingNew ? 'auto' : 'none',
        },
        config: addingNew ? { mass: 1, tension: 190, friction: 30 } : { mass: 1, tension: 190, friction: 15 }
    });

    addNewPaneAnimation = tw.prefixMatch('md') ? addNewPaneAnimationDesktop : addNewPaneAnimation;

    return (
        <View label="yachtAttachments" style={tw`bg-gray0 h-screen md:flex-row overflow-hidden max-w-screen`}>

            <PrimaryNav navigation={navigation} setLoggedIn={setLoggedIn} hideOnMobile={true} yachtID={yachtID} />

            <View style={tw`bg-blue h-20 flex-row md:hidden`}>
                <View style={tw`bg-blue h-20 p-5 pt-6.5 flex-row`}>
                    <Pressable
                        style={tw`p-2 -ml-2 -mt-2`}
                        onPress={() => { goPrevScreen(navigation, 'yacht', {yachtID: yachtID}) }}
                    >
                        <Image
                            accessibilityLabel={ labels.current?.CANCEL }
                            source={{ uri: require('../svg/back_white.svg') }}
                            resizeMode="contain"
                            style={tw`h-6 w-6 top-px mr-1`}
                        />
                    </Pressable>
                    <Span style={tw`bg-${yacht.color || 'gray6'} text-white uppercase rounded-full text-sm font-semibold shrink-0 w-8 h-8 mr-3 text-center leading-[31px] -mt-[2px]`}>{yacht.initials}</Span>
                    <Heading style={tw`mt-1.5 leading-4 mb-0 text-white`} hlevel={1} styleHlevel={4}>
                        { labels.current?._ASSETS }
                    </Heading>
                </View>

                <View style={tw`grow`}></View>

                <View style={tw`w-18 justify-center md:order-2 md:w-full`}>
                    <Pressable
                        onPress={()=>{navigation.navigate('notifications')}}
                        style={tw`p-5 relative md:flex-row md:pl-15 md:py-3 md:my-2`}
                    >
                        { notificationCount > 0 &&
                            <Span style={tw`bg-white text-blue rounded-full min-w-4 h-4 text-center text-xs font-bold absolute bottom-1/2 left-1/2 md:left-18 z-10 pointer-events-none`}>
                                { notificationCount }
                            </Span>
                        }

                        <Image
                            accessibilityLabel={ labels.current?.NOTIFICATIONS }
                            source={{ uri: require('../svg/notifications.svg') }}
                            resizeMode="contain"
                            style={tw`h-5 md:h-8 w-full md:w-8 relative z-0 -left-1`}
                        />

                        <Span style={tw`hidden md:flex font-bold text-base text-white leading-8 ml-4 tracking-wide`}>{ labels.current?.NOTIFICATIONS }</Span>

                    </Pressable>
                </View>
            </View>

            <View style={tw`bg-blue h-8 md:h-full md:w-10 z-0 -mb-3 md:mb-0 md:-mr-3`}>
                <View style={tw`bg-gray0 h-full w-full rounded-t-3xl md:rounded-l-3xl md:rounded-r-0 z-10 md:shadow-black md:shadow-opacity-10 md:shadow-radius-4`}></View>
            </View>

            <View style={[tw`bg-gray0 grow md:pr-8 md:pl-4 z-20`, {width: tw.prefixMatch('md') ? 'calc(100% - 320px)' : '100%', height: tw.prefixMatch('md') ? '100%' : 'calc(100% - 112px)'}]}>

                { ! tw.prefixMatch('md') && 'object' === typeof yacht && yacht.attachments?.length > 0 &&
                    <View style={tw`h-13 px-5 pb-5 md:py-0 md:px-0 md:h-24 lg:h-33 flex-row justify-end md:items-center z-10`}>

                        { /* <TextInput
                            style={tw`shrink-1 mr-5 p-1.5 max-w-60`}
                            image={{ uri: require('../svg/search_gray.svg') }}
                            placeholder="Search"
                            // value={filterText}
                            // onChangeText={(value)=>{setFilterText(value)}}
                        /> */ }

                        <Pressable
                            style={tw`border border-gray3 py-1 px-2 md:py-1.5 md:px-3 rounded flex-row md:mr-4 md:min-w-60`}
                            onPress={()=>{setFilterVisible(true)}}
                        >
                            <Image
                                accessibilityHidden={true}
                                source={{ uri: require('../svg/sort.svg') }}
                                resizeMode="contain"
                                style={tw`h-4 w-4 top-0.5 md:top-1 mr-2`}
                            />

                            <Span style={tw`text-sm md:text-base leading-4`}>{ labels.current?.VIEW }: <Span style={tw`text-sm md:text-base font-medium`}>{labels.current?.ALL !== getFilter ? attachmentTypeFilterOptions.find(obj => {return obj.value == getFilter})?.label : getFilter}</Span></Span>

                        </Pressable>

                    </View>
                }

                <View style={! getAssets.length ? tw`grow` : tw`shrink`}>
                    <ScrollView
                        centerContent={getAssets.length ? false : true}
                        style={{width:'100%',overflowX:'visible'}}
                        contentContainerStyle={tw`w-full min-h-full justify-start`}
                    >
                        <View style={tw`hidden md:flex`}>
                            <View style={tw`flex-row w-full items-center justify-between lg:justify-start mt-2 md:mt-6 lg:mt-10 mb-0 md:mb-1 lg:mb-5 md:px-1`}>
                                <Span style={tw`bg-${yacht.color || 'gray6'} text-white uppercase rounded-full text-lg font-semibold shrink-0 w-14 h-14 mr-3 text-center leading-[56px]`}>{yacht.initials}</Span>

                                <Heading style={tw`leading-4 hidden md:flex`} hlevel={1} styleHlevel={2}>
                                    {yacht.name}
                                </Heading>

                                { 'object' === typeof yacht && yacht.attachments?.length > 0 &&
                                    <View style={tw`px-5 md:py-0 md:px-0 flex-row md:items-center z-10`}>

                                        { /* <TextInput
                                            style={tw`shrink-1 mr-5 p-1.5 max-w-60`}
                                            image={{ uri: require('../svg/search_gray.svg') }}
                                            placeholder="Search"
                                            // value={filterText}
                                            // onChangeText={(value)=>{setFilterText(value)}}
                                        /> */ }

                                        <Pressable
                                            style={tw`border border-gray3 py-1 px-2 md:py-1.5 md:px-3 rounded flex-row md:mr-4 md:min-w-60`}
                                            onPress={()=>{setFilterVisible(true)}}
                                        >
                                            <Image
                                                accessibilityHidden={true}
                                                source={{ uri: require('../svg/sort.svg') }}
                                                resizeMode="contain"
                                                style={tw`h-4 w-4 top-0.5 md:top-1 mr-2`}
                                            />

                                            <Span style={tw`text-sm md:text-base leading-4`}>{ labels.current?.VIEW }: <Span style={tw`text-sm md:text-base font-medium`}>{labels.current?.ALL !== getFilter ? attachmentTypeFilterOptions.find(obj => {return obj.value == getFilter})?.label : getFilter}</Span></Span>

                                        </Pressable>

                                    </View>
                                }
                            </View>
                        </View>

                        { '' !== primaryMsg &&
                            <View style={tw`w-full px-4 md:px-1`}>
                                <Notification
                                    style={tw`my-4`}
                                    type={primaryMsgType}
                                >{primaryMsg}</Notification>
                            </View>
                        }
                        { ! getAssets.length &&
                            <View style={tw`justify-center grow items-center`}>

                                <Image
                                    accessibilityHidden={true}
                                    source={{ uri: require('../svg/attachment_lightgray.svg') }}
                                    resizeMode="contain"
                                    style={tw`h-32 w-32 md:h-48 md:w-48`}
                                />
                                <Heading style={tw`text-gray5 text-center p-5 mb-26`} hlevel={tw.prefixMatch('md') ? 2 : 3}>{ yacht?.attachments?.length > 0 ? getLabel('SEARCH_NOTFOUND_TITLE') : labels.current?._404_TEXT }</Heading>

                                <View style={tw`fixed bottom-9 md:bottom-18 right-18 pointer-events-none`}>

                                    <Span style={tw`text-sm md:text-base font-medium font-sans mb-2 mr-6`}>{ labels.current?.CONTENT_ADD }</Span>

                                    <Image
                                        accessibilityHidden={true}
                                        source={{ uri: require('../svg/arrow_organic.svg') }}
                                        resizeMode="contain"
                                        style={tw`h-9 w-18 left-14 md:left-16`}
                                    />
                                </View>
                            </View>
                        }

                        { getAssets.length > 0 &&
                            <View>
                                <Heading hlevel={2} styleHlevel={4} style={tw`hidden md:flex pl-1`}>{ labels.current?._ASSETS }</Heading>

                                <View style={tw`md:pl-1 md:flex-wrap md:flex-row mb-20 md:justify-start md:items-start`}>
                                    {getAssets}
                                </View>
                            </View>
                        }

                    </ScrollView>

                    <AttachmentUploadInput
                        passedRef={newAttachmentInput}
                        onChange={(e)=>{
                            if ( ! e.target.files[0] ) { return; }
                            // if size is > 30 MB
                            if ( e.target.files[0].size > 31457280 ) { toast.show(labels.current?.ATTACHMENT_TOO_LARGE_BODY.replace('%size%', '30 MB'), {type: 'danger', duration:10000}); return; }

                            attachmentFileNameInput.current.value = e.target.files[0].name;
                            attachmentMimeType.current = e.target.files[0].type;
                            processAttachmentInput(e.target.files[0]).then((result)=>{
                                attachmentData.current = result;
                            }).catch((error)=>{
                                toast.show(error, {type: 'danger', duration:10000});
                            });

                        }}
                    />

                    <AddNewAssetButtons addNewLabel={ labels.current?.ADD +' '+ labels.current?._ASSET } onAddNew={()=>{
                        setUpdateAttachmentID(0);
                        setNewAssetMsg('');

                        attachmentData.current = '';
                        attachmentFileNameInput.current.value = '';
                        attachmentMimeType.current = '';
                        setAttachmentType(0);
                        setAttachmentDesc(''); newAttachmentDescInput.current.value = '';

                        setAddingNew(true);

                        /* trigger click on #image-picker */
                        // newAttachmentInput.current.click();
                    }} />
                </View>
            </View>

            <AnimatedView style={addNewPaneAnimation}>

                <Pressable style={tw`hidden md:flex bg-blue/75 w-screen h-screen left-0 top-0 fixed`}
                    onPress={() => {setNewAssetMsg(''); setAddingNew(false)}}
                ></Pressable>

                <View style={tw`md:w-108 md:rounded-md md:shadow-black md:shadow-opacity-25 md:shadow-radius-8`}>

                    <View style={tw`bg-blue md:bg-gray0 md:border-b-2 md:border-gray1 md:items-center h-20 p-5 md:px-9 pt-6.5 flex-row md:rounded-t-md`}>
                        <Pressable
                            style={tw`p-2 -ml-2 -mt-2`}
                            onPress={() => {setNewAssetMsg(''); setAddingNew(false)}}
                        >
                            <Image
                                accessibilityLabel={ labels.current?.CANCEL }
                                source={{ uri: tw.prefixMatch('md') ? require('../svg/back.svg') : require('../svg/back_white.svg') }}
                                resizeMode="contain"
                                style={tw`h-6 w-6 top-px md:top-1 md:mr-4`}
                            />
                        </Pressable>
                        <Heading style={tw`mt-1.5 md:mt-0 leading-4 mb-0 text-white md:text-ink`} hlevel={1} styleHlevel={tw.prefixMatch('md') ? 2 : 4}>{ ( 0 !== updateAttachmentID ? labels.current?.EDIT : labels.current?.ADD ) +' '+ labels.current?._ASSET }</Heading>
                    </View>

                    <View style={tw`bg-blue h-8 z-0 -mb-3 md:hidden`}>
                        <View style={tw`bg-gray0 h-full w-full rounded-t-3xl`}></View>
                    </View>

                    <View style={[tw`bg-gray0 grow md:pr-4 md:pb-5 md:pt-8 md:pl-4 z-20 md:rounded-b-md w-full`, { height: tw.prefixMatch('md') ? 'max-content' : formHeight}]}>
                        <View style={tw`h-full md:h-max`}>
                            <ScrollView
                                style={{width:'100%',overflowX:tw.prefixMatch('md') ? 'visible' : 'hidden'}}
                                contentContainerStyle={tw`w-full justify-start px-5 pb-5 md:pb-0`}
                            >
                                { '' !== newAssetMsg &&
                                    <View style={tw`w-full md:pr-2`}>
                                        <Notification
                                            style={tw`my-2`}
                                            type={newAssetMsgType}
                                        >{newAssetMsg}</Notification>
                                    </View>
                                }

                                <View style={tw`md:flex-row md:flex-wrap md:justify-between`}>
                                    { 0 === updateAttachmentID &&
                                        <Pressable
                                            style={tw`mt-2 w-full`}
                                            onPress={()=>{newAttachmentInput.current.click();}}
                                        >
                                            <TextInput
                                                style={tw`pointer-events-none`}
                                                placeholder={ labels.current?.SELECT +' '+ labels.current?.ATTACHMENT_FILE +'*' }
                                                image={ attachmentData.current ? { uri: require('../svg/upload.svg') } : { uri: require('../svg/upload_gray.svg') }}
                                                // defaultValue={attachmentType}
                                                passedRef={attachmentFileNameInput}
                                                validateGroup={newAssetValidation}
                                                required
                                            />

                                            <Image
                                                accessibilityHidden={true}
                                                source={{ uri: require('../svg/chevron_down_blue.svg') }}
                                                resizeMode="contain"
                                                style={tw`h-3 w-3 absolute top-1/2 -translate-y-1/2 right-4`}
                                            />
                                        </Pressable>
                                    }

                                    <Pressable
                                        style={tw`mt-2 w-full`}
                                        onPress={()=>{setAttachmentTypeSelectVisible(true)}}
                                    >
                                        <TextInput
                                            style={tw`pointer-events-none`}
                                            placeholder={ labels.current?.TYPE +'*' }
                                            image={ attachmentType ? { uri: require('../svg/attachment.svg') } : { uri: require('../svg/attachment_gray.svg') }}
                                            // defaultValue={attachmentType}
                                            value={attachmentType ? attachmentTypeOptions.find(obj => {return obj.value === attachmentType})?.label : ''}
                                            validateGroup={newAssetValidation}
                                            required
                                        />

                                        <Image
                                            accessibilityHidden={true}
                                            source={{ uri: require('../svg/chevron_down_blue.svg') }}
                                            resizeMode="contain"
                                            style={tw`h-3 w-3 absolute top-1/2 -translate-y-1/2 right-4`}
                                        />
                                    </Pressable>

                                    <Span style={tw`text-sm text-gray6 my-2 w-full`}>{ '* ' + labels.current?.REQUIRED }</Span>

                                    <TextInput
                                        style={tw`mt-2 w-full`}
                                        autoCapitalize="on"
                                        placeholder={ labels.current?.DESCRIPTION }
                                        image={ attachmentDesc ? { uri: require('../svg/description.svg') } : { uri: require('../svg/description_gray.svg') }}
                                        pattern="^.{2,}$"
                                        validatedValue={(value) => {setAttachmentDesc(value)}}
                                        validateOnChange={true}
                                        validateGroup={newAssetValidation}
                                        stateValue={attachmentDesc}
                                        passedRef={newAttachmentDescInput}
                                    />
                                </View>

                            </ScrollView>

                            <HorizontalRule style={tw`md:hidden`} />

                            <Button
                                style={tw`m-5`}
                                title={0 !== updateAttachmentID ? labels.current?.UPDATE : labels.current?.ADD}
                                disabled={0 !== updateAttachmentID && attachmentType ? false : ( attachmentData.current && attachmentType ? false : true )}
                                validateGroup={newAssetValidation}
                                onPress={()=>{

                                    if ( newAssetValidation ) { return; }

                                    doNewAssetValidation(true);
                                    setNewAssetMsg('');

                                    setTimeout(()=>
                                    {
                                        // check if fields are valid

                                        if ( 0 === updateAttachmentID && ! attachmentData.current
                                            || ! attachmentType
                                        ){
                                            let errorMsg = [];

                                            if ( 0 === updateAttachmentID && ! attachmentData.current )
                                                { errorMsg.push(labels.current?.REQUEST_VALID +' '+labels.current?.ATTACHMENT_FILE); }
                                            if ( ! attachmentType )
                                                { errorMsg.push(labels.current?.REQUEST +' '+labels.current?._ASSET +' '+labels.current?.TYPE); }

                                            setNewAssetMsgType('warning');
                                            setNewAssetMsg(errorMsg.join('\n'));
                                            doNewAssetValidation(false);
                                        }
                                        else
                                        {
                                            if ( 0 === updateAttachmentID )
                                            {
                                                uploadAttachment(
                                                    attachmentData.current,
                                                    attachmentType,
                                                    attachmentDesc,
                                                    photoObjectType.current,
                                                    yachtID,
                                                    yachtID,
                                                    toast,
                                                    0 === updateAttachmentID ? labels.current?.TOAST_ADD.replace('{object}',labels.current?._ASSET) : labels.current?.TOAST_UPDATE.replace('{object}',labels.current?._ASSET),
                                                    {apiUrl:apiUrl, apiVersion:apiVersion},
                                                    (status)=>{
                                                        doNewAssetValidation(false);
                                                        if ( 'success' === status )
                                                        {
                                                            setLastUpdate(now());

                                                            setAddingNew(false);
                                                            setTimeout(()=>{
                                                                setUpdateAttachmentID(0);
                                                                attachmentData.current = '';
                                                                attachmentFileNameInput.current.value = '';
                                                                attachmentMimeType.current = '';
                                                                setAttachmentType(0);
                                                                setAttachmentDesc(''); newAttachmentDescInput.current.value = '';
                                                            }, 1500);
                                                        }
                                                    }
                                                );
                                            }
                                            else {
                                                let payload = {
                                                    object_type: photoObjectType.current,
                                                    object_id: yachtID,
                                                    type_id: attachmentType,
                                                    description: attachmentDesc,
                                                    key: updateAttachmentID,
                                                };

                                                fetch(apiUrl, {
                                                    method: 'POST',
                                                    cache: 'no-cache',
                                                    headers: {
                                                        'Content-Type': 'application/json'
                                                    },
                                                    body: JSON.stringify({
                                                        endpoint: 'attachments/update', v: apiVersion,
                                                        device_id: localStorage.getItem('deviceID'),
                                                        client_key: localStorage.getItem('clientKey'),
                                                        payload: payload
                                                    }),
                                                })
                                                .then((response) => {
                                                    return response.text().then(function(text) {
                                                        const result = JSON.parse(text);
                                                        if ( result.error )
                                                        {
                                                            if ( '401' === result.error ){ setLoggedIn(false); result.error = 'Your session token has expired, please log in again.'; }
                                                            setNewAssetMsgType('warning');
                                                            setNewAssetMsg(result.error);
                                                            doNewAssetValidation(false);
                                                        }
                                                        else
                                                        {
                                                            setAddingNew(false);
                                                            toast.show((0 !== updateAttachmentID ? labels.current?.TOAST_UPDATE : labels.current?.TOAST_ADD).replace('{object}',labels.current?._ASSET), {type: 'success', duration:2000});

                                                            setTimeout(()=>{
                                                                setLastUpdate(now()); // forces app to refetch all account data
                                                            }, 500);

                                                            setTimeout(()=>{
                                                                doNewAssetValidation(false);
                                                                setUpdateAttachmentID(0);
                                                                attachmentData.current = '';
                                                                attachmentFileNameInput.current.value = '';
                                                                attachmentMimeType.current = '';
                                                                setAttachmentType(0);
                                                                setAttachmentDesc(''); newAttachmentDescInput.current.value = '';
                                                            }, 1500);
                                                        }
                                                    });
                                                })
                                                .catch(error => {
                                                    console.warn(error);
                                                    setNewAssetMsgType('warning');
                                                    if ( ! window.navigator.onLine ) { setNewAssetMsg('Internet connection failed. Please try again.'); }
                                                    else { setNewAssetMsg('An error ocurred. See console log.'); }
                                                    doNewAssetValidation(false);
                                                });
                                            }
                                        }
                                    }, 250);


                                }}
                            />
                        </View>
                    </View>
                </View>
            </AnimatedView>

            <SelectMenu
                badge={false}
                title={ labels.current?.SELECT +' '+ labels.current?.TYPE }
                optionSet="radio"
                buttonTitle={ labels.current?.SELECT }
                active={attachmentTypeSelectVisible}
                setActive={setAttachmentTypeSelectVisible}
                selectedValue={attachmentType}
                options={attachmentTypeOptions}
                onValueChange={(value) => {setAttachmentType(value)}}
                scrollable={attachmentTypeOptions.length > 8}
                searchable={attachmentTypeOptions.length > 8}
            />

            <SelectMenu
                badge={false}
                title={ labels.current?.VIEW }
                optionSet="radio"
                buttonTitle={ labels.current?.SORT }
                active={filterVisible}
                setActive={setFilterVisible}
                selectedValue={getFilter}
                options={attachmentTypeFilterOptions}
                onValueChange={(value) => {setFilter(value)}}
                scrollable={attachmentTypeFilterOptions.length > 8}
                searchable={attachmentTypeFilterOptions.length > 8}
            />

            <Modal
                active={modalActive}
                setActive={setModalActive}
            >
                <Heading hlevel={2} styleHlevel={3}>{ labels.current?.DELETE_CONFIRM_TITLE }</Heading>
                <Span>{ labels.current?.DELETE_CONFIRM_BODY }</Span>
                <Span style={tw`text-red mt-2`}>{ labels.current?.PERMANENT_ACTION }</Span>
                <View style={tw`flex-row mt-5`}>
                    <Button
                        title={ labels.current?.CANCEL }
                        style={tw`w-full shrink py-2 bg-white border border-blue`}
                        styleHover={tw`bg-white`}
                        styleText={tw`text-blue`}
                        styleHoverText={tw`text-blue`}
                        onPress={()=>{setModalActive(false)}}
                    />
                    <Button
                        title={ labels.current?.DELETE }
                        image={{ uri: require('../svg/delete_white.svg') }}
                        style={tw`ml-2 w-full shrink py-2 bg-red border border-red`}
                        styleHover={tw`bg-red`}
                        styleText={tw`text-white`}
                        styleHoverText={tw`text-white`}
                        onPress={()=>{
                            setPrimaryMsg('');

                            fetch(apiUrl, {
                                method: 'POST',
                                cache: 'no-cache',
                                headers: {
                                    'Content-Type': 'application/json'
                                },
                                body: JSON.stringify({
                                    endpoint: 'attachments/delete', v: apiVersion,
                                    client_key: localStorage.getItem('clientKey'),
                                    device_id: localStorage.getItem('deviceID'),
                                    payload: {
                                        object_type: photoObjectType.current,
                                        object_id: yachtID,
                                        vessel_id: yachtID,
                                        key: modalAffectID,
                                        mime_type: attachmentMimeType.current,
                                    }
                                }),
                            })
                            .then((response) => {
                                return response.text().then(function(text) {
                                    const result = JSON.parse(text);
                                    if ( result.error )
                                    {
                                        if ( '401' === result.error ){ setLoggedIn(false); result.error = 'Your session token has expired, please log in again.'; }
                                        toast.show(result.error, {type: 'danger', duration:10000});
                                    }
                                    else
                                    {
                                        setModalAffectID('');
                                        attachmentMimeType.current = '';
                                        setModalActive(false);
                                        setViewingAsset(false);
                                        toast.show((labels.current?.TOAST_DELETE).replace('{object}',labels._ASSET), {type: 'success', duration:2000});

                                        setTimeout(()=>{
                                            setLastUpdate(now()); // forces app to refetch all account data
                                        }, 250);
                                    }
                                });
                            })
                            .catch(error => {
                                console.warn(error);
                                if ( ! window.navigator.onLine ) { toast.show('Internet connection failed. Please try again.', {type: 'danger', duration:10000}); }
                                else { toast.show('An error ocurred. See console log.', {type: 'danger', duration:10000}); }
                            });
                        }}
                    />
                </View>
            </Modal>
        </View>
    )
}

export default YachtAttachmentsView;
