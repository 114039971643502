import React from 'react';
import { View, ScrollView, Pressable, Image } from 'react-native';
import { create, useDeviceContext } from 'twrnc';
import { Heading, PrimaryNav, Span, AssetItem, SelectMenu, Notification, HorizontalRule, Tag, AddNewAssetButtons } from '../elements';
import { useSpring, animated } from 'react-spring';
import { getLabel, truncate, goPrevScreen, now, dateStr_to_timeAgo, date_DMJYYYY, getColorByID, sort_by, getRouteFilter, updateRouteFilter } from '../helpers';
import { GlobalContext } from '../global';
import { useToast } from 'react-native-toast-notifications';

function NotificationsView ({navigation})
{
    const AnimatedView = animated(View);
    const tw = create( require(`../../tailwind.config.js`) );    
    useDeviceContext(tw);
    const toast = useToast();
    
    let {
        apiUrl,
        apiVersion,
        setLastUpdate,
        loggedIn, setLoggedIn,
        userNotifications
    } = React.useContext(GlobalContext);

    React.useEffect(()=> 
    {        
        if ( ! loggedIn ) { navigation.navigate('loading'); }
    },[loggedIn, navigation]);

    const [getFilter, setFilter] = React.useState(null);
    const [filterVisible, setFilterVisible] = React.useState(false);

    const labels = React.useRef(null);
    React.useEffect(()=> 
    {
        if ( ! labels.current )
        {
            labels.current = {
                _ASSETS: getLabel('NOTIFICATIONS'),
                _ASSET: getLabel('NOTIFICATION'),
                _404_TEXT: getLabel('NOTIFICATIONS_404_TEXT'),

                DELETE: getLabel('DELETE'),
                CANCEL: getLabel('CANCEL'),
                NOTIFICATIONS: getLabel('NOTIFICATIONS'),
                DELETE_CONFIRM_TITLE: getLabel('DELETE_CONFIRM_TITLE'),
                DELETE_CONFIRM_BODY: getLabel('DELETE_CONFIRM_BODY'),
                PERMANENT_ACTION: getLabel('PERMANENT_ACTION'),
                VIEW: getLabel('VIEW'),
                SORT: getLabel('SORT'),
                MARK: getLabel('MARK'),
                ALL: getLabel('ALL'),
                READ: getLabel('READ'),
                UNREAD: getLabel('UNREAD'),
                SELECT: getLabel('SELECT'),

                TOAST_UPDATE: getLabel('TOAST_UPDATE'),
                TOAST_ADD: getLabel('TOAST_ADD'),
                TOAST_DELETE: getLabel('TOAST_DELETE'),
            };
        }
        setFilter(getRouteFilter('notifications') || getLabel('ALL'));
    },[]);
    
    const [primaryMsg, setPrimaryMsg] = React.useState('');
    const [primaryMsgType, setPrimaryMsgType] = React.useState('warning');

    const [viewingAsset, setViewingAsset] = React.useState(false);
    const [viewingAssetObj, setViewingAssetObj] = React.useState({});
    
    // const [addingNew, setAddingNew] = React.useState(false);
    const [updateID, setUpdateID] = React.useState(0);

    const [getAssets, setAssets] = React.useState([]);

    React.useEffect(()=> 
    {
        let assets = [];

        if ( userNotifications.length )
        {
            let notifications = [];
            
            switch(getFilter)
            {
                case labels.current?.READ:
                    updateRouteFilter('notifications', labels.current?.READ);
                    notifications = userNotifications.filter(function (el)
                    {
                        return el.is_viewed;
                    });
                    notifications = [...notifications].sort(sort_by('create_date', true));
                    break;

                case labels.current?.UNREAD:
                    updateRouteFilter('notifications', labels.current?.UNREAD);
                    notifications = userNotifications.filter(function (el)
                    {
                        return ! el.is_viewed;
                    });
                    notifications = [...notifications].sort(sort_by('create_date', true));
                    break;
                
                case labels.current?.ALL:
                default:
                    updateRouteFilter('notifications', labels.current?.ALL);
                    notifications = [...userNotifications].sort(sort_by('create_date', true));
                    break;
            }

            // Show unread first
            notifications.sort((a, b) => {
                if (a.is_viewed && !b.is_viewed) return 1;
                if (!a.is_viewed && b.is_viewed) return -1;
            });
                
            notifications.forEach((asset, index) => {
                let actions = [];
                if ( ! asset.is_viewed ) {
                    actions.push({
                        name: labels.current?.MARK +' '+ labels.current?.READ,
                        color: 'green',
                        onPress: ()=>{
                            fetch(apiUrl, {
                                method: 'POST',
                                cache: 'no-cache',
                                headers: {
                                    'Content-Type': 'application/json'
                                },
                                body: JSON.stringify({
                                    endpoint: 'notifications/update', v: apiVersion,
                                    client_key: localStorage.getItem('clientKey'),
                                    device_id: localStorage.getItem('deviceID'),
                                    payload: {
                                       id: asset.id,
                                       is_viewed: true,
                                    }
                                }),
                            })
                            .then((response) => {
                                return response.text().then(function(text) {
                                    const result = JSON.parse(text);
                                    if ( result.error )
                                    {
                                        if ( '401' === result.error ){ setLoggedIn(false); result.error = 'Your session token has expired, please log in again.'; }
                                        setPrimaryMsgType('warning');
                                        setPrimaryMsg(result.error);
                                    }
                                    else
                                    {
                                        setLastUpdate(now()); // forces app to refetch all account data
                                    }
                                });
                            })
                            .catch(error => {
                                console.warn(error);
                                setPrimaryMsgType('warning');
                                if ( ! window.navigator.onLine ) { setPrimaryMsg('Internet connection failed. Please try again.'); }
                                else { setPrimaryMsg('An error ocurred. See console log.'); }
                            });
                        }
                    });
                }
                actions.push(
                {
                    name: labels.current?.DELETE,
                    color:'red',
                    onPress: ()=>
                    {
                        setPrimaryMsg('');

                        fetch(apiUrl, {
                            method: 'POST',
                            cache: 'no-cache',
                            headers: {
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify({
                                endpoint: 'notifications/delete', v: apiVersion,
                                client_key: localStorage.getItem('clientKey'),
                                device_id: localStorage.getItem('deviceID'),
                                payload: {
                                   id: asset.id,
                                }
                            }),
                        })
                        .then((response) => {
                            return response.text().then(function(text) {
                                const result = JSON.parse(text);
                                if ( result.error )
                                {
                                    if ( '401' === result.error ){ setLoggedIn(false); result.error = 'Your session token has expired, please log in again.'; }
                                    setPrimaryMsg(result.error);
                                }
                                else
                                {
                                    toast.show((labels.current?.TOAST_DELETE).replace('{object}',labels.current?._ASSET), {type: 'success', duration:2000});
                                    setLastUpdate(now()); // forces app to refetch all account data
                                }
                            });
                        })
                        .catch(error => {
                            console.warn(error);
                            if ( ! window.navigator.onLine ) { setPrimaryMsg('Internet connection failed. Please try again.'); }
                            else { setPrimaryMsg('An error ocurred. See console log.'); }
                        });
                    }
                });

                assets.push(
                    <AssetItem
                        key={asset.id}
                        footerText={dateStr_to_timeAgo(asset.create_date)}
                        // badge={getInitials(asset.detail)}
                        color='blue'
                        fullWidth={true}
                        bladeColor={ asset.is_viewed ? 'gray6' : 'orange' }
                        title={ asset.name }
                        titleTag={ asset.tag }
                        titleTagColor={ asset.is_viewed ? 'gray6' : getColorByID(asset.tag_color_id) }
                        unread={ ! asset.is_viewed }
                        menu={actions}
                        description={<Span>{ truncate(asset.detail.replace(/(\r\n|\n|\r)/gm, ' '), (tw.prefixMatch('md') ? 96 : 60) ) }</Span>}
                        onPress={() => {
                            setViewingAssetObj(asset);
                            setViewingAsset(true);

                            setPrimaryMsg('');

                            if ( ! asset.is_viewed )
                            {
                                fetch(apiUrl, {
                                    method: 'POST',
                                    cache: 'no-cache',
                                    headers: {
                                        'Content-Type': 'application/json'
                                    },
                                    body: JSON.stringify({
                                        endpoint: 'notifications/update', v: apiVersion,
                                        client_key: localStorage.getItem('clientKey'),
                                        device_id: localStorage.getItem('deviceID'),
                                        payload: {
                                           id: asset.id,
                                           is_viewed: true,
                                        }
                                    }),
                                })
                                .then((response) => {
                                    return response.text().then(function(text) {
                                        const result = JSON.parse(text);
                                        if ( result.error )
                                        {
                                            if ( '401' === result.error ){ setLoggedIn(false); result.error = 'Your session token has expired, please log in again.'; }
                                            setPrimaryMsgType('warning');
                                            setPrimaryMsg(result.error);
                                        }
                                        else
                                        {
                                            setTimeout(()=>{setLastUpdate(now());},1000); // forces app to refetch all account data
                                        }
                                    });
                                })
                                .catch(error => {
                                    console.warn(error);
                                    setPrimaryMsgType('warning');
                                    if ( ! window.navigator.onLine ) { setPrimaryMsg('Internet connection failed. Please try again.'); }
                                    else { setPrimaryMsg('An error ocurred. See console log.'); }
                                });
                            }
                        }}
                    />
                )
            });         
        }
        
        setAssets(assets);
        
    },[getFilter, navigation, userNotifications, setAssets, labels]);

    let viewPaneAnimation = useSpring(
    {
        from: {
            willChange: 'transform',
            zIndex: 91,
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            backgroundColor: tw.color('gray0'),
            boxShadow: 'rgb(0 0 0 / 50%) 0px 0px 24px',
            transform: 'translateX(110%)',
        },
        to: {
            transform: viewingAsset ? 'translateX(0%)' : 'translateX(110%)',
        },
        config: viewingAsset ? { mass: 1, tension: 190, friction: 30 } : { mass: 1, tension: 190, friction: 15 }
    });

    let viewPaneAnimationDesktop = useSpring(
    {
        from: {
            willChange: 'opacity',
            zIndex: 91,
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            opacity: 0,
            justifyContent: 'center',
            alignItems: 'center',
            pointerEvents: 'none',
            backdropFilter: 'blur(3px)',
        },
        to: {
            opacity: viewingAsset ? 1 : 0,
            pointerEvents: viewingAsset ? 'auto' : 'none',
        },
        config: viewingAsset ? { mass: 1, tension: 190, friction: 30 } : { mass: 1, tension: 190, friction: 15 }
    });

    viewPaneAnimation = tw.prefixMatch('md') ? viewPaneAnimationDesktop : viewPaneAnimation;

    return (
        <View label="notifications" style={tw`bg-gray0 h-screen md:flex-row overflow-hidden max-w-screen`}>

            <PrimaryNav navigation={navigation} setLoggedIn={setLoggedIn} hideOnMobile={true} />

            <View style={tw`bg-blue h-20 flex-row md:hidden`}>
                <View style={tw`bg-blue h-20 p-5 pt-6.5 flex-row`}>
                    <Pressable
                        style={tw`p-2 -ml-2 -mt-2`}
                        onPress={() => { goPrevScreen(navigation, 'yachts') }}
                    >
                        <Image
                            accessibilityLabel={ labels.current?.CANCEL }
                            source={{ uri: require('../svg/back_white.svg') }}
                            resizeMode="contain"
                            style={tw`h-6 w-6 top-px mr-1`}
                        />
                    </Pressable>
                    <Heading style={tw`mt-1.5 leading-4 mb-0 text-white`} hlevel={1} styleHlevel={4}>
                        { labels.current?._ASSETS }
                    </Heading>
                </View>

                <View style={tw`grow`}></View>
            </View>
        
            <View style={tw`bg-blue h-8 md:h-full md:w-10 z-0 -mb-3 md:mb-0 md:-mr-3`}>
                <View style={tw`bg-gray0 h-full w-full rounded-t-3xl md:rounded-l-3xl md:rounded-r-0 z-10 md:shadow-black md:shadow-opacity-10 md:shadow-radius-4`}></View>
            </View>

            <View style={[tw`bg-gray0 grow md:pr-8 md:pl-4 z-20`, {width: tw.prefixMatch('md') ? 'calc(100% - 320px)' : '100%', height: tw.prefixMatch('md') ? '100%' : 'calc(100% - 112px)'}]}>

                <View style={tw`h-13 px-5 pb-5 md:py-0 md:px-0 md:h-33 flex-row md:items-center z-10`}>
                    
                    <Heading style={tw`mt-1.5 md:mt-0 leading-4 mb-0`} hlevel={1} styleHlevel={tw.prefixMatch('md') ? 2 : 4}>{ labels.current?._ASSETS }</Heading>

                    { userNotifications.length > 0 &&
                        <Pressable
                            style={tw`border border-gray3 py-1 px-2 md:py-1.5 md:px-3 rounded flex-row md:mr-4 md:min-w-60`}
                            onPress={()=>{setFilterVisible(true)}}
                        >
                            <Image
                                accessibilityHidden={true}
                                source={{ uri: require('../svg/sort.svg') }}
                                resizeMode="contain"
                                style={tw`h-4 w-4 top-0.5 md:top-1 mr-2`}
                            />
                            
                            <Span style={tw`text-sm md:text-base leading-4`}>{ labels.current?.VIEW }: <Span style={tw`text-sm md:text-base font-medium`}>{getFilter}</Span></Span>
                            
                        </Pressable>
                    }
                    
                </View>
                
                <View style={! getAssets.length ? tw`grow` : tw`shrink`}>
                    <ScrollView
                        centerContent={getAssets.length ? false : true}
                        style={{width:'100%',overflowX:'visible'}}
                        contentContainerStyle={tw`w-full min-h-full justify-start`}
                    >
                        { '' !== primaryMsg &&
                            <View style={tw`w-full md:pr-2`}>
                                <Notification
                                    style={tw`my-4`}
                                    type={primaryMsgType}
                                >{primaryMsg}</Notification>
                            </View>
                        }

                        { ! getAssets.length &&
                            <View style={tw`justify-center h-full items-center`}>
                                
                                <Image
                                    accessibilityHidden={true}
                                    source={{ uri: require('../svg/circleCheck_lightGray.svg') }}
                                    resizeMode="contain"
                                    style={tw`h-32 w-32 md:h-48 md:w-48`}
                                />                            
                                <Heading style={tw`text-gray5 text-center p-5 mb-26`} hlevel={tw.prefixMatch('md') ? 2 : 3}>{ userNotifications.length > 0 ? getLabel('SEARCH_NOTFOUND_TITLE') : labels.current?._404_TEXT }</Heading>
                                
                            </View>
                        }
    
                        { getAssets.length > 0 &&
                            <View>                                
                                <View style={tw`mb-20 md:px-1 md:mr-2 md:justify-start md:items-start`}>
                                    {getAssets}
                                </View>
                            </View>
                        }
                        
                    </ScrollView>

                    <AddNewAssetButtons hideAddNew={true} />
                </View>
            </View> 

            <AnimatedView style={viewPaneAnimation}>

                <Pressable style={tw`hidden md:flex bg-blue/75 w-screen h-screen left-0 top-0 fixed`}
                    onPress={() => {setViewingAsset(false)}}
                ></Pressable>

                <View style={tw`md:w-188 md:rounded-md md:shadow-black md:shadow-opacity-25 md:shadow-radius-8`}>

                    <View style={tw`bg-blue md:bg-gray0 md:border-b-2 md:border-gray1 md:items-center h-20 p-5 md:px-9 pt-6.5 flex-row md:rounded-t-md`}>
                        <Pressable
                            style={tw`p-2 -ml-2 -mt-2`}
                            onPress={() => {setViewingAsset(false)}}
                        >
                            <Image
                                accessibilityLabel={ labels.current?.CANCEL }
                                source={{ uri: tw.prefixMatch('md') ? require('../svg/back.svg') : require('../svg/back_white.svg') }}
                                resizeMode="contain"
                                style={tw`h-6 w-6 top-px md:top-1 md:mr-4`}
                            />
                        </Pressable>
                        <Heading style={tw`mt-1.5 leading-4 mb-0 text-white md:hidden`} hlevel={1} styleHlevel={tw.prefixMatch('md') ? 2 : 4}>{ labels.current?._ASSET }</Heading>
                        <View style={tw`justify-between hidden md:flex md:flex-row grow mt-0 mb-0 items-start h-full`}>
                            <View style={tw`self-center`}>
                                <Heading style={tw`mt-0 mb-0 leading-6 text-ink`} hlevel={1} styleHlevel={2}>{ viewingAssetObj.name }</Heading>
                            </View>

                            <View style={tw`self-center`}>
                                { /* 0 !== viewingAssetObj.is_private &&
                                    <Span style={tw`text-gray6 text-sm shrink-0 text-right`}>{ labels.current?.PRIVATE }</Span>
                                */ }

                                <Tag style={tw`self-end mb-0`} color={getColorByID(viewingAssetObj.tag_color_id)}>{ viewingAssetObj.tag }</Tag>

                                <View style={tw`flex-row items-center`}>
                                    <Image
                                        accessibilityHidden={true}
                                        source={{ uri: require('../svg/date.svg') }}
                                        resizeMode="contain"
                                        style={tw`h-4 w-4 mr-2`}
                                    />
                                    <Span style={tw`text-gray6 text-sm leading-6 text-right`}>{ date_DMJYYYY( viewingAssetObj.modified_date ) }</Span>
                                </View>
                            </View>
                        </View>
                    </View>

                    <View style={tw`bg-blue h-8 z-0 -mb-3 md:hidden`}>
                        <View style={tw`bg-gray0 h-full w-full rounded-t-3xl`}></View>
                    </View>

                    <View style={[tw`bg-gray0 grow md:pr-4 md:pb-5 md:pt-8 md:pl-4 z-20 md:rounded-b-md w-full`, { height: tw.prefixMatch('md') ? 'max-content' : 'calc(100vh - 100px)'}]}>
                        <View style={tw`h-full md:h-max`}>
                            <View style={tw`w-full justify-between px-5 mb-4 flex-row md:hidden`}>
                                <View>
                                    <Heading style={tw`mt-0 mb-2 text-ink`} hlevel={1} styleHlevel={4}><Tag style={tw`mr-2`} color={getColorByID(viewingAssetObj.tag_color_id)}>{ viewingAssetObj.tag }</Tag> { viewingAssetObj.name }</Heading>
                                    <View style={tw`flex-row items-center`}>
                                        <Image
                                            accessibilityHidden={true}
                                            source={{ uri: require('../svg/date.svg') }}
                                            resizeMode="contain"
                                            style={tw`h-4 w-4 mr-2`}
                                        />
                                        <Span style={tw`text-gray6 text-sm leading-6`}>{ date_DMJYYYY( viewingAssetObj.modified_date ) }</Span>
                                    </View>
                                </View>

                                { /* false !== viewingAssetObj.is_private &&
                                    <View>
                                        <Span style={tw`text-gray6 text-sm md:mr-5 shrink-0`}>{ labels.current?.PRIVATE }</Span>
                                    </View>
                                */ }
                            </View>
                            <HorizontalRule style={tw`mb-4 md:hidden`} />
                            <ScrollView
                                style={{width:'100%',overflowX:tw.prefixMatch('md') ? 'visible' : 'hidden'}}
                                contentContainerStyle={tw`w-full justify-start px-5`}
                            >
                                <View style={tw`md:flex-row md:flex-wrap md:justify-between md:min-h-[300px]`}>
                                    <Span>{ viewingAssetObj.detail }</Span>
                                </View>
                            </ScrollView>
                        </View>
                    </View>
                </View>
            </AnimatedView>
                    
            <SelectMenu
                badge={false}
                title="Sort by"
                optionSet="radio"
                buttonTitle="Sort"
                active={filterVisible}
                setActive={setFilterVisible}
                selectedValue={getFilter}
                options={[
                    labels.current?.ALL,
                    labels.current?.READ,
                    labels.current?.UNREAD,
                ]}
                onValueChange={(value) => {setFilter(value)}}
            />
        </View>
    )
}

export default NotificationsView;
