import React from 'react';
import { useNavigationState, getStateFromPath } from '@react-navigation/native';
import { linking } from '../../App';

function RootClassManager() {
  const routeName = useNavigationState(state => {
    if ( ! state?.routes ) { return ''; }
    const route = state.routes[state.index];
    return route?.name || '';
  });

  React.useEffect(() => {
    const rootElement = document.getElementById('root');
    if (rootElement) {
      let initialRouteName = routeName;

      // If routeName is not available (initial load), use getStateFromPath
      if (!routeName) {
        const pathname = window.location.pathname;
        const state = getStateFromPath(pathname, linking.config);

        if (state && state.routes.length > 0) {
          const currentRoute = state.routes[state.routes.length - 1];
          initialRouteName = currentRoute.name;
        }
      }

      if (initialRouteName) {
        rootElement.className = `view-${initialRouteName}`;
      }
    }
  }, [routeName]);

  return null;
}

export default RootClassManager;
