import React from 'react';
import { Image, View, ScrollView, Pressable } from 'react-native';
import { create, useDeviceContext } from 'twrnc';
import { Button, Heading, Span, PinInput, Notification } from '../elements';
import { mask } from "react-native-mask-text";
import { getLabel } from '../helpers';
import { GlobalContext } from '../global';


function LoginCodeView ({route, navigation})
{
    const tw = create( require(`../../tailwind.config.js`) );    
    useDeviceContext(tw);
    
    let {
        version,
        apiUrl,
        apiVersion,
        recaptchaKey,
        loggedIn, setLoggedIn,
        setShownIntro,
        userEmail,
    } = React.useContext(GlobalContext);

    React.useEffect(()=> 
    {
        if ( loggedIn ) {
            setTimeout(()=>
            {
                // console.log('loginCode is navigating to yachts');
                // delay for our initial account data fetch
                let attempted_view = localStorage.getItem('attempted-view');
                let view = attempted_view || 'yachts';
                localStorage.removeItem('attempted-view');

                navigation.replace(view);
            },2000);
        }
    },[loggedIn, navigation]);

    const { verbiage, sid_mobile, mobile_masked } = route.params;

    if ( ! userEmail ) { navigation.navigate('welcome'); }
   
    const [userEmailCode, setUserEmailCode] = React.useState('');
    const [emailValidation, doEmailValidation] = React.useState(false);
    const [invalidPhone, invalidatePhone] = React.useState(false);
    const [invalidLoginMsg, setInvalidLoginMsg] = React.useState('Please review your code');
    const [codeResent, setCodeResent] = React.useState(false);
    const [codeResentStatus, setCodeResentStatus] = React.useState('success');
    const [codeResentMsg, setCodeResentMsg] = React.useState('Verification code resent');

    return (
        <View label="verifyAccount" style={tw`bg-blue min-h-screen`}>
            <View style={tw`flex-row p-5 md:p-8`}>
                <Pressable
                    style={tw`-mr-6`}
                    onPress={() => {navigation.navigate('welcome')}}
                >
                    <Image
                        accessibilityLabel={ getLabel('LOGIN_RETURN_LINK') + getLabel('LOGIN_RETURN_TEXT') }
                        source={{ uri: require('../svg/back_white.svg') }}
                        resizeMode="contain"
                        style={tw`h-6 w-6 pointer-events-none`}
                    />
                </Pressable>
            </View>
            <ScrollView
                centerContent={true}
                style={{width:'100%'}}
                contentContainerStyle={tw`w-full md:items-center relative`}
            >                
                <View style={tw`bg-blue h-full w-full p-5 md:p-8 items-center`}>
                    <View style={tw`h-full w-full md:w-80 justify-start md:justify-center`}>
                        { codeResent && 
                            <Notification 
                                style={tw`my-4 md:w-80`}
                                    type={codeResentStatus}
                            >{codeResentMsg}</Notification>
                        }
                        { invalidPhone && ! codeResent &&
                            <Notification 
                                style={tw`my-4 md:w-80`}
                                type="warning"
                            >{invalidLoginMsg}</Notification>
                        }
                        <Heading style={tw`text-white mt-0 md:mt-4 mb-7`} hlevel={2} >
                            { getLabel('VERIFY_LOGIN_TITLE') }
                        </Heading>
                        <Span style={tw`text-white mb-7`}>{verbiage}</Span>
                        <PinInput
                            style={tw`w-full md:w-80`}
                            styleInput={tw`bg-[#0360a3] text-white my-4 w-full border-transparent`}
                            styleFocus={tw`border-white`}
                            keyboardType="numeric"
                            pattern="^\d{4}$"
                            validateOnChange={true}
                            validateGroup={emailValidation}
                            validatedValue={(value) => {setUserEmailCode(value)}}
                            required
                        />
                        <Pressable
                            onPress={() => {

                                window.grecaptcha.ready(function() {
                                    window.grecaptcha.execute(recaptchaKey, {action: 'login/sms'}).then(function(token)
                                    {
                                        fetch(apiUrl, {
                                            method: 'POST',
                                            cache: 'no-cache',
                                            headers: {
                                                'Content-Type': 'application/json'
                                            },
                                            body: JSON.stringify({
                                                endpoint: 'user/login', v: apiVersion,
                                                device_id: localStorage.getItem('deviceID'),
                                                payload: {
                                                    email: userEmail,
                                                    captcha: token,
                                                }
                                            }),
                                        })
                                        .then((response) => {
                                            return response.text().then(function(text) {
                                                const result = JSON.parse(text);
                                                if ( result.error )
                                                {
                                                    setCodeResentMsg(result.error);
                                                    setCodeResentStatus('warning');
                                                    setCodeResent(true);
                                                }
                                                else
                                                {
                                                    setCodeResentMsg( getLabel('VERIFY_RESEND_CONFIRM_MOBILE') );
                                                    setCodeResentStatus('success');
                                                    setCodeResent(true);
                                                }
                                            });
                                        })
                                        .catch(error => {
                                            console.warn(error);
                                            setCodeResentMsg('An Error Occurred');
                                            setCodeResentStatus('warning');
                                            setCodeResent(true);
                                        });
                                    });
                                });
                            }}
                        >
                            <Span style={tw`text-sm text-white mt-6`}>{ getLabel('VERIFY_RESEND_CODE_LINK') }</Span>
                        </Pressable>
                    </View>
                </View>
            </ScrollView>
            <View style={tw`flex-row p-5 md:p-8 mb-7 mg:mb-0`}>
                <Button
                    style={tw`bg-white w-full mx-auto md:w-80 border border-transparent`}
                    styleText={tw`text-blue`}
                    styleHover={tw`bg-blue border-white`}
                    styleHoverText={tw`text-white`}
                    styleLoading={tw`bg-blue border-white`}
                    validateGroup={emailValidation}
                    onPress={() => { 
                        if ( emailValidation ) { return; }

                        doEmailValidation(true);
                        setTimeout(() => 
                        {
                            let payload = {
                                email: userEmail,
                                code: userEmailCode,
                                sid: sid_mobile,
                            };

                            if ( apiVersion > 1 )
                            {
                                payload.device_id = localStorage.getItem('deviceID');
                            }

                            if ( userEmailCode )
                            {
                                window.grecaptcha.ready(function() {
                                    window.grecaptcha.execute(recaptchaKey, {action: 'login/email'}).then(function(token)
                                    {
                                        payload.captcha = token;

                                        fetch(apiUrl, {
                                            method: 'POST',
                                            cache: 'no-cache',
                                            headers: {
                                                'Content-Type': 'application/json'
                                            },
                                            body: JSON.stringify({
                                                endpoint: 'user/login', v: apiVersion,
                                                device_id: localStorage.getItem('deviceID'),
                                                payload: payload
                                            }),
                                        })
                                        .then((response) => {
                                            return response.text().then(function(text) {
                                                const result = JSON.parse(text);
                                                if ( result.error )
                                                {
                                                    setCodeResent(false);
                                                    setInvalidLoginMsg(result.error);
                                                    invalidatePhone(true);
                                                    setTimeout(()=>{doEmailValidation(false);}, 2000);
                                                }
                                                else
                                                {
                                                    localStorage.setItem( 'clientKey', result['x-bearer-token'] );
                                                    if ( typeof window.singularSdk === 'function' ) {
                                                        window.singularSdk.login(result['obj_user']['id']);
                                                        window.singularSdk.event("sng_login");
                                                    }
                                                    invalidatePhone(false);
                                                    setLoggedIn(true);
                                                    setShownIntro(false);
                                                    setTimeout(()=>{doEmailValidation(false);}, 1990);
                                                }
                                            });
                                        })
                                        .catch(error => {
                                            console.warn(error);
                                            setCodeResent(false);
                                            setInvalidLoginMsg('An Error Occurred');
                                            invalidatePhone(true);
                                            setTimeout(()=>{doEmailValidation(false);}, 2000);
                                        });
                                    });
                                });
                            }
                            else 
                            {
                                setTimeout(()=>{
                                    setCodeResent(false);
                                    invalidatePhone(true);
                                    doEmailValidation(false);
                                }, 2000);
                            }
                        }, 250);
                    }}
                    title="Next"
                />                
            </View>
            <Span style={tw`absolute bottom-4 left-6 text-white/50 text-xs pointer-events-none`}>{version}</Span>
        </View>
    );
}

export default LoginCodeView;
