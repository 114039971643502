import React from 'react';
import { View, ScrollView, Image, Pressable/*, Switch*/, Linking, Platform } from 'react-native';
import { create, useDeviceContext } from 'twrnc';
import { PrimaryNav, Modal, Heading, Span, HorizontalRule, Button, TextInput } from '../elements';
import { getInitials, getLabel, getSubscription, makeCopyright } from '../helpers';
import { GlobalContext } from '../global';

function AIServiceView ({navigation})
{
    const tw = create( require(`../../tailwind.config.js`) );    
    useDeviceContext(tw);
    
    let {
        version,
        apiUrl,
        apiVersion,
        loggedIn, setLoggedIn,
    } = React.useContext(GlobalContext);

    React.useEffect(()=> 
    {        
        if ( ! loggedIn ) { navigation.navigate('loading'); }
        // if ( ! getSubscription('feature_ai') ) { navigation.navigate('yachts'); }
    },[loggedIn, navigation]);
    
    const [ labels, setLabels ] = React.useState(false);
    React.useEffect(()=> 
    {
        if ( ! labels ) 
        {
            setLabels({
                _ASSETS: getLabel('AI_SERVICE'),
                FEATURE_MOBILE_ONLY_TITLE: getLabel('FEATURE_MOBILE_ONLY_TITLE'),
                FEATURE_MOBILE_ONLY_BODY: getLabel('FEATURE_MOBILE_ONLY_BODY'),
            });
        }
    },[]);

    const [getAssets, setAssets] = React.useState([]);

    return (
        <View label="aiServiceView" style={tw`bg-gray0 h-screen md:flex-row overflow-hidden max-w-screen`}>

            <PrimaryNav navigation={navigation} setLoggedIn={setLoggedIn} />
        
            <View style={tw`bg-blue h-8 md:h-full md:w-10 z-0 -mb-3 md:mb-0 md:-mr-3`}>
                <View style={tw`bg-gray0 h-full w-full rounded-t-3xl md:rounded-l-3xl md:rounded-r-0 z-10 md:shadow-black md:shadow-opacity-10 md:shadow-radius-4`}></View>
            </View>

            <View style={[tw`bg-gray0 flex-grow md:pr-8 md:pl-4 z-20`, {width: tw.prefixMatch('md') ? 'calc(100% - 320px)' : '100%', height: tw.prefixMatch('md') ? '100%' : 'calc(100% - 112px)'}]}>
                <View style={tw`h-13 px-5 pb-5 md:py-0 md:px-0 md:h-24 lg:h-33 flex-row md:items-center z-10`}>
                    <Heading style={tw`mt-1.5 md:mt-0 leading-4 mb-0`} hlevel={1} styleHlevel={tw.prefixMatch('md') ? 2 : 4}>
                        { labels._ASSETS }
                    </Heading>
                </View>

                <View style={! getAssets.length ? tw`grow` : tw`shrink`}>
                    <ScrollView
                        centerContent={getAssets.length ? false : true}
                        style={{width:'100%',overflowX:'visible'}}
                        contentContainerStyle={tw`w-full min-h-full justify-start`}
                    >
                        { ! getAssets.length &&
                            <View style={tw`justify-center h-full items-center`}>
                                <Image
                                    accessibilityHidden={true}
                                    source={{ uri: require('../svg/ai_service_lightgray.svg') }}
                                    resizeMode="contain"
                                    style={tw`h-32 w-32 md:h-48 md:w-48`}
                                />
                                <Heading style={tw`text-gray5 text-center p-5`} hlevel={tw.prefixMatch('md') ? 2 : 3}>{ labels.FEATURE_MOBILE_ONLY_TITLE }</Heading>
                                <Span style={tw`text-gray5_5 text-center font-bold md:text-[18px] px-10`}>{ labels.FEATURE_MOBILE_ONLY_BODY }</Span>
                                <View style={tw`flex md:flex-row max-w-full w-[500px] mt-10 mb-26 px-10 items-center justify-center`}>
                                    <Button
                                        onPress={()=>{Linking.openURL('https://apps.apple.com/us/app/yachtwave/id6447794172')}}
                                        style={tw`py-4 shrink-0 w-full md:w-1/2 mx-3 mb-4`}
                                        styleText={tw`text-sm`}
                                        title="Apple App Store"
                                    />
                                    <Button
                                        onPress={()=>{Linking.openURL('https://play.google.com/store/apps/details?id=com.yachtwave.app')}}
                                        style={tw`py-4 shrink-0 w-full md:w-1/2 mx-3 mb-4`}
                                        styleText={tw`text-sm`}
                                        title="Google Play Store"
                                    />
                                </View>
                            </View>
                        }


                        { /*getAssets.length > 0 &&
                            <View style={tw`md:flex-wrap md:flex-row mb-20 md:justify-start md:items-start`}>
                                <>{getAssets}</>
                            </View>
                        */ }
                    </ScrollView>

                    {/*<AddNewAssetButtons addNewLabel={getLabel('YACHTS_ADD')} onAddNew={()=>{ setAddingNew(true); }} />*/}
                </View>
            </View>
        </View>
    )
}

export default AIServiceView;
