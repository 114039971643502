import React from 'react';
import { View, ScrollView, Pressable, Image, useWindowDimensions } from 'react-native';
import { create, useDeviceContext } from 'twrnc';
import { Heading, PrimaryNav, Span, TextInput, Button, AssetItem, SelectMenu, Modal, Notification, HorizontalRule, PillMenu, ImagesUploadInput, AddNewAssetButtons, AttachmentUploadInput, DocumentUploadInput, SpecsCard, Tag } from '../elements';
import { useSpring, animated } from 'react-spring';
import { goPrevScreen, getLabel, getOptionsByLabelGroup, getPropertiesByGroup, getEngineOptions, getEquipmentOptions, getLocationFromAssetID, truncate, now, is_date_in_past, is_date_within_30_days, date_DMJYYYY, YYYYMMDD_to_DMJYYYY, YYYYMMDD_to_MMDDYYYY, MMDDYYYY_to_YYYYMMDD, getUnreadNotificationsCount, sort_by, getRouteFilter, updateRouteFilter, processImagesInput, uploadImages, canIhazIcon, processAttachmentInput, uploadAttachment, processDocumentInput, uploadDocument, getAssetDocuments } from '../helpers';
import { GlobalContext } from '../global';
import { useToast } from 'react-native-toast-notifications';

function YachtLogsView ({route, navigation})
{
    const AnimatedView = animated(View);
    const tw = create( require(`../../tailwind.config.js`) );    
    useDeviceContext(tw);
    const {height, width} = useWindowDimensions();
    const formHeight = height - 100;
    const toast = useToast();
    
    let {
        apiUrl,
        apiVersion,
        setLastUpdate,
        loggedIn, setLoggedIn,
        userYachts,
        userNotifications,
        enabledFeatures
    } = React.useContext(GlobalContext);

    React.useEffect(()=> 
    {        
        if ( ! loggedIn ) { navigation.navigate('loading'); }
    },[loggedIn, navigation]);

    const [getFilter, setFilter] = React.useState(null);
    const [filterVisible, setFilterVisible] = React.useState(false);
    
    const labels = React.useRef(null);
    React.useEffect(()=> 
    {
        if ( ! labels.current )
        {
            labels.current = {
                _ASSETS: getLabel('MAINTENANCE_LOGS'),
                _ASSET: getLabel('MAINTENANCE_LOG'),
                _404_TEXT: getLabel('MAINTENANCE_404_TEXT'),
                PRIVATE: getLabel('PRIVATE'),
                EDIT: getLabel('EDIT'),
                DELETE: getLabel('DELETE'),
                CANCEL: getLabel('CANCEL'),
                NOTIFICATIONS: getLabel('NOTIFICATIONS'),
                CONTENT_ADD: getLabel('CONTENT_ADD'),
                ADD: getLabel('ADD'),
                UPDATE: getLabel('UPDATE'),
                NAME: getLabel('NAME'),
                DETAIL: getLabel('DETAIL'),
                TYPE: getLabel('TYPE'),
                LOCATION: getLabel('LOCATION'),
                EQUIPMENT: getLabel('EQUIPMENT'),
                ENGINE: getLabel('ENGINE'),
                HOURS: getLabel('HOURS'),
                HOURS_SHORT: getLabel('HOURS_SHORT'),
                NOTES: getLabel('NOTES'),
                DATE: getLabel('DATE'),
                INVALID_DATE: getLabel('INVALID_DATE'),
                SELECTED: getLabel('SELECTED'),
                REQUEST: getLabel('REQUEST'),
                REQUEST_VALID: getLabel('REQUEST_VALID'),
                DELETE_CONFIRM_TITLE: getLabel('DELETE_CONFIRM_TITLE'),
                DELETE_CONFIRM_BODY: getLabel('DELETE_CONFIRM_BODY'),
                DELETE_CONFIRM2_BODY: getLabel('DELETE_CONFIRM2_BODY'),
                PERMANENT_ACTION: getLabel('PERMANENT_ACTION'),
                VIEW: getLabel('VIEW'),
                SORT: getLabel('SORT'),
                ALL: getLabel('ALL'),
                SELECT: getLabel('SELECT'),
                REQUIRED: getLabel('REQUIRED'),

                TOAST_UPDATE: getLabel('TOAST_UPDATE'),
                TOAST_ADD: getLabel('TOAST_ADD'),
                TOAST_DELETE: getLabel('TOAST_DELETE'),

                DETAILS: getLabel('DETAILS'),
                PHOTOS: getLabel('PHOTOS'),
                PHOTO: getLabel('PHOTO'),
                _404_PHOTOS_TEXT: getLabel('PHOTOS_404_TEXT'),

                ATTACHMENTS: getLabel('ATTACHMENTS'),
                ATTACHMENT: getLabel('ATTACHMENT'),
                _404_ATTACHMENTS_TEXT: getLabel('ATTACHMENT_404_TEXT'),
                ATTACHMENT_FILE: getLabel('ATTACHMENT_FILE'),
                ATTACHMENT_TOO_LARGE_BODY: getLabel('ATTACHMENT_TOO_LARGE_BODY'),
                // TYPE: getLabel('TYPE'),
                DESCRIPTION: getLabel('DESCRIPTION'),

                DOCUMENTS: getLabel('DOCUMENTS'),
                DOCUMENT: getLabel('DOCUMENT'),
                _404_DOCUMENTS_TEXT: getLabel('DOCUMENT_404_TEXT'),
                DOCUMENT_FILE: getLabel('ATTACHMENT_FILE'),
                DOCUMENT_TOO_LARGE_BODY: getLabel('ATTACHMENT_TOO_LARGE_BODY'),
                DATE: getLabel('DATE'),
                NAME: getLabel('NAME'),
                EXPIRES: getLabel('EXPIRES'),
                EXPIRING: getLabel('EXPIRING'),
                EXPIRED: getLabel('EXPIRED'),
                EXPIRATION_NONE: getLabel('EXPIRATION_NONE'),
                EXPIRATION_VALID: getLabel('EXPIRATION_VALID'),
                EXPIRATION: getLabel('EXPIRATION'),
                // TYPE: getLabel('TYPE'),
                // DETAILS: getLabel('DETAILS'),
                // VIEW: getLabel('VIEW'),
                DETAIL: getLabel('DETAIL'),
            };
        }
        setFilter(getRouteFilter('logs') || getLabel('ALL'));
    },[]);

    const [notificationCount, setNotificationCount] = React.useState(0);
    React.useEffect(()=>
    {
        setNotificationCount(getUnreadNotificationsCount())
    },[userNotifications]);
    
    const { yachtID } = route.params;
    const [ yacht, setYacht ] = React.useState(0);
    
    const [primaryMsg, setPrimaryMsg] = React.useState('');
    const [primaryMsgType, setPrimaryMsgType] = React.useState('warning');
    const [modalActive, setModalActive] = React.useState(false);
    const [modalAffectID, setModalAffectID] = React.useState(0);
    
    const [addingNew, setAddingNew] = React.useState(false);
    const [updateID, setUpdateID] = React.useState(0);
    
    const [viewingAsset, setViewingAsset] = React.useState(false);
    const [viewingAssetObj, setViewingAssetObj] = React.useState({});

    const [newAssetMsg, setNewAssetMsg] = React.useState('');
    const [newAssetMsgType, setNewAssetMsgType] = React.useState('warning');
    const [newAssetValidation, doNewAssetValidation] = React.useState(false);
    const [getAssets, setAssets] = React.useState([]);

    // asset specific input parameters
    const [newDesc, setNewDesc] = React.useState('');
    const [newType, setNewType] = React.useState('');
    const [newLocation, setNewLocation] = React.useState('');
    const [newLocationVisible, setNewLocationVisible] = React.useState(true);
    const [newDate, setNewDate] = React.useState('');
    const [newDateMasked, setNewDateMasked] = React.useState('');
    const [newHours, setNewHours] = React.useState('');
    const [newNotes, setNewNotes] = React.useState('');
    const [newMechanicalID, setNewMechanicalID] = React.useState('');
    const [newEquipmentID, setNewEquipmentID] = React.useState('');
    const [newMechanicalIDVisible, setNewMechanicalVisible ] = React.useState(false);
    const [mechanicalOptions, setMechanicalOptions] = React.useState([]);
    const [typeSelectVisible, setTypeSelectVisible] = React.useState(false);
    const [locationSelectVisible, setLocationSelectVisible] = React.useState(false);
    const [mechanicalSelectVisible, setMechanicalSelectVisible] = React.useState(false);
    const [equipmentSelectVisible, setEquipmentSelectVisible] = React.useState(false);

    // input refs for asset specific inputs
    const newDescInput = React.useRef(null);
    const newDateInput = React.useRef(null);
    const newHoursInput = React.useRef(null);
    const newNotesInput = React.useRef(null);

    // select menu options
    const typeOptions = getOptionsByLabelGroup('types.maintenance.types', true);
    const typeFilterOptions = [{value: getLabel('ALL'), label: getLabel('ALL')}].concat(typeOptions);
    const typeIcons = getPropertiesByGroup( 'value', 'types.maintenance.types', 'icon', false, true );
    const locationOptions = getOptionsByLabelGroup('types.inventory.locations', true);
    const allMechanicalOptions = getEngineOptions(yachtID);
    const allEquipmentOptions = getEquipmentOptions(yachtID);

    const logUseLocation = getPropertiesByGroup('value', 'types.maintenance.types', 'use_location_field', false, true);
    const logUseMechanical = getPropertiesByGroup('value', 'types.maintenance.types', 'use_mechanical_field', false, true);

    React.useEffect(()=>
    {
        if ( ! newType ) { return; }

        const useLocation = newType ? ( logUseLocation.find(obj => {return obj.value == newType})?.use_location_field === 1 ? true : false ) : true;
        const useMechanical = newType ? ( logUseMechanical.find(obj => {return obj.value == newType})?.use_mechanical_field === 1 ? true : false ) : false;

        setNewLocationVisible( useLocation );
        setNewMechanicalVisible( useMechanical );

        let theseEngineOptions = [];

        if ( useMechanical )
        {
            theseEngineOptions = getEngineOptions(yachtID, newType);
            setMechanicalOptions(theseEngineOptions);

            /*console.log({
                useLocation: useLocation,
                useMechanical: useMechanical,
                mechanicalOptions: getEngineOptions(yachtID, newType),
            });*/
        }
        else
        {
            setMechanicalOptions(theseEngineOptions);
        }

    },[newType, yachtID, setMechanicalOptions, setNewLocationVisible, setNewMechanicalVisible]);

    // ref for photo type
    const photoObjectType = React.useRef('obj_log_maintenance');
    const newPhotosInput = React.useRef(null);
    const [contentType, setContentType] = React.useState(getLabel('DETAILS'));
    const [photos, setPhotos] = React.useState([]);
    const [viewingPhoto, setViewingPhoto] = React.useState(false);
    const [viewingPhotoObj, setViewingPhotoObj] = React.useState({});
    const [deleteFileModalActive, setDeleteFileModalActive] = React.useState(false);
    const [deleteFileModalAffectID, setDeleteFileModalAffectID] = React.useState('');
    const [isImageUploading, setIsImageLoading] = React.useState(false);
    const iDsContainingFiles = React.useRef([]);
    const prepareAssetPhotos = React.useCallback((asset)=>
    {
        let photoAssets = [];

        if ( asset?.images?.length )
        {
            let images = [...asset.images].sort(sort_by('modified_date', true));
            let imageWidth = (width - 75)/3;
            images.forEach((photo, index) => {
                photoAssets.push(
                    <Pressable
                        key={index}
                        style={tw`h-[${imageWidth}px] w-[${imageWidth}px] md:h-32 md:w-32`}
                        onPress={()=>{
                            // recalculate optimal image display width and height given the max height should be 80vh for md devices and height - 142 for sm devices, and a max width for md devices being 80vw and 100vw for sm devices
                            let displayWidth = width;
                            let displayHeight = height - 142;
                            if ( tw.prefixMatch('md') ) { displayWidth = Math.floor(width * 0.8); displayHeight = Math.floor(height * 0.8); }
                            if ( photo.width > displayWidth ) {
                                photo.height = Math.ceil((displayWidth / photo.width) * photo.height);
                                photo.width = displayWidth;
                            }
                            if ( photo.height > displayHeight ) {
                                photo.width = Math.floor((displayHeight / photo.height) * photo.width);
                                photo.height = displayHeight;
                            }
                            attachmentMimeType.current = '';
                            setViewingPhotoObj(photo);
                            setViewingPhoto(true);
                        }}
                    >
                        <Image
                            source={{ uri: photo.uri }}
                            resizeMode="cover"
                            style={[tw`bg-gray1 w-full h-full border-b border-white`, (index + 1) % 3 === 0 ? tw`border-r-0 md:border-r` : tw`border-r`]}
                        />
                    </Pressable>
                )
            });
        }

        return photoAssets;
    },[width, height, labels, setViewingPhoto, setViewingPhotoObj]);
    let viewPhotoAnimation = useSpring(
    {
        from: {
            willChange: 'transform',
            zIndex: 91,
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            backgroundColor: tw.color('gray0'),
            boxShadow: 'rgb(0 0 0 / 50%) 0px 0px 24px',
            transform: 'translateX(110%)',
        },
        to: {
            transform: viewingPhoto ? 'translateX(0%)' : 'translateX(110%)',
        },
        config: viewingPhoto ? { mass: 1, tension: 190, friction: 30 } : { mass: 1, tension: 190, friction: 15 }
    });
    let viewPhotoAnimationDesktop = useSpring(
    {
        from: {
            willChange: 'opacity',
            zIndex: 91,
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            opacity: 0,
            justifyContent: 'center',
            alignItems: 'center',
            pointerEvents: 'none',
        },
        to: {
            opacity: viewingPhoto ? 1 : 0,
            pointerEvents: viewingPhoto ? 'auto' : 'none',
        },
        config: viewingPhoto ? { mass: 1, tension: 190, friction: 30 } : { mass: 1, tension: 190, friction: 15 }
    });
    viewPhotoAnimation = tw.prefixMatch('md') ? viewPhotoAnimationDesktop : viewPhotoAnimation;

    // attachment specific inputs
    const [attachmentDesc, setAttachmentDesc] = React.useState('');
    const [attachmentType, setAttachmentType] = React.useState(0);
    const [attachmentTypeSelectVisible, setAttachmentTypeSelectVisible] = React.useState(false);
    const [updateAttachment, setUpdateAttachment] = React.useState(false);
    const [updateAttachmentID, setUpdateAttachmentID] = React.useState(0);
    const [attachments, setAttachments] = React.useState([]);
    const newAttachmentInput = React.useRef(null);
    const attachmentData = React.useRef('');
    const attachmentMimeType = React.useRef('');
    const attachmentFileNameInput = React.useRef(null);
    const newAttachmentDescInput = React.useRef(null);
    const attachmentTypeOptions = getOptionsByLabelGroup('types.attachment.types', true);
    const attachmentTypeIcons = getPropertiesByGroup( 'value', 'types.attachment.types', 'icon', false, true );
    const prepareAssetAttachments = React.useCallback((asset, editable)=>
    {
        let attachmentAssets = [];

        if ( asset?.attachments?.length )
        {
            let attachments = [...asset.attachments].sort(sort_by('modified_date', true));
            attachments.forEach((file, index) => {
                let icon = file.type_id ? attachmentTypeIcons.find(obj => {return obj.value == file.type_id})?.icon : 'miscellaneous';
                let icon_variant = '_white';
                icon = icon + icon_variant;
                icon = canIhazIcon( icon ) ? icon : 'miscellaneous' + icon_variant;
                attachmentAssets.push(
                    <AssetItem
                        key={index}
                        style={[editable === true && {width:'calc(100% - 18px)'}, tw`md:w-76`]}
                        minHeight={73}
                        color='blue'
                        icon={{ uri: require('../svg/'+icon+'.svg') }}
                        title={ file.type_id ? attachmentTypeOptions.find(obj => {return obj.value == file.type_id})?.label : '' }
                        description={ file.description }
                        // description2={ file.mime_type }
                        menu={editable === true && [
                            { name:labels.current?.EDIT, color:'green', onPress: ()=>{
                                setUpdateAttachmentID(file.key);
                                setAttachmentDesc(file.description); newAttachmentDescInput.current.value = file.description;
                                setAttachmentType(file.type_id);
                                setUpdateAttachment(true);
                            }},
                            { name:labels.current?.DELETE, color:'red', onPress: ()=>{
                                setDeleteFileModalAffectID(file.key);
                                attachmentMimeType.current = file.mime_type;
                                setDeleteFileModalActive(true);
                            }}
                        ]}
                        onPress={() => {
                            window.open(file.uri, '_blank');
                        }}
                        viewMore={labels.current?.VIEW}
                    />
                )
            });
        }

        return attachmentAssets;
    },[labels, setUpdateAttachmentID, setAttachmentDesc, setAttachmentType, setUpdateAttachment, newAttachmentDescInput, setDeleteFileModalAffectID, setDeleteFileModalActive]);
    let addNewAttachmentPaneAnimation = useSpring(
    {
        from: {
            willChange: 'transform',
            zIndex: 91,
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            backgroundColor: tw.color('gray0'),
            boxShadow: 'rgb(0 0 0 / 50%) 0px 0px 24px',
            transform: 'translateX(110%)',
        },
        to: {
            transform: updateAttachment ? 'translateX(0%)' : 'translateX(110%)',
        },
        config: updateAttachment ? { mass: 1, tension: 190, friction: 30 } : { mass: 1, tension: 190, friction: 15 }
    });

    let addNewAttachmentPaneAnimationDesktop = useSpring(
    {
        from: {
            willChange: 'opacity',
            zIndex: 91,
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            opacity: 0,
            justifyContent: 'center',
            alignItems: 'center',
            pointerEvents: 'none',
            backdropFilter: 'blur(3px)',
        },
        to: {
            opacity: updateAttachment ? 1 : 0,
            pointerEvents: updateAttachment ? 'auto' : 'none',
        },
        config: updateAttachment ? { mass: 1, tension: 190, friction: 30 } : { mass: 1, tension: 190, friction: 15 }
    });

    addNewAttachmentPaneAnimation = tw.prefixMatch('md') ? addNewAttachmentPaneAnimationDesktop : addNewAttachmentPaneAnimation;

    // document specific inputs
    const [documentName, setDocumentName] = React.useState('');
    const [documentDesc, setDocumentDesc] = React.useState('');
    const [documentType, setDocumentType] = React.useState(0);
    const [documentExpiration, setDocumentExpiration] = React.useState('');
    const [documentExpirationMasked, setDocumentExpirationMasked] = React.useState('');
    const [documentTypeSelectVisible, setDocumentTypeSelectVisible] = React.useState(false);
    const [updateDocument, setUpdateDocument] = React.useState(false);
    const [updateDocumentID, setUpdateDocumentID] = React.useState(0);
    const [documents, setDocuments] = React.useState([]);
    const [documentData, setDocumentData] = React.useState('');
    const documentMimeType = React.useRef('');
    const [viewingDocument, setViewingDocument] = React.useState(false);
    const [viewingDocumentObj, setViewingDocumentObj] = React.useState({});
    const documentSize = React.useRef(0);
    const documentInput = React.useRef(null);
    const documentNameInput = React.useRef(null);
    const documentFileNameInput = React.useRef(null);
    const documentDescInput = React.useRef(null);
    const documentExpirationInput = React.useRef(null);
    const documentTypeOptions = getOptionsByLabelGroup('types.attachment.types', true);
    const documentTypeIcons = getPropertiesByGroup( 'value', 'types.attachment.types', 'icon', false, true );
    const prepareAssetDocuments = React.useCallback((asset, editable)=>
    {
        if ( ! enabledFeatures.documents ) { return false; }

        let yachtObj = false;
        userYachts.every((value, index) => {
            if ( yachtID == value.id ) { yachtObj = value; return false; }
            return true;
        });

        let documentAssets = [];

        if ( getAssetDocuments(yachtID, asset.id, userYachts).length )
        {
            let documents = getAssetDocuments(yachtID, asset.id, userYachts).sort(sort_by('modified_date', true));
            documents.forEach((file, index) => {
                let icon = file.type_id ? documentTypeIcons.find(obj => {return obj.value == file.type_id})?.icon : 'miscellaneous';
                let icon_variant = '_white';
                icon = icon + icon_variant;
                icon = canIhazIcon( icon ) ? icon : 'miscellaneous' + icon_variant;
                file.expiration_tag = file.expiration_date ? ( is_date_within_30_days(file.expiration_date) ? ((is_date_in_past(file.expiration_date) ? labels?.current?.EXPIRED : labels?.current?.EXPIRING) ) : labels?.current?.EXPIRATION_VALID ) : labels?.current?.EXPIRATION_NONE;
                file.expiration_color = file.expiration_date ? ( is_date_within_30_days(file.expiration_date) ? ((is_date_in_past(file.expiration_date) ? 'red' : 'orange') ) : 'green' ) : 'gray5_5';
                documentAssets.push(
                    <AssetItem
                        key={index}
                        style={[editable === true && {width:'calc(100% - 18px)'}, tw`md:w-76`]}
                        minHeight={73}
                        color='blue'
                        icon={{ uri: require('../svg/'+icon+'.svg') }}
                        title={ file.type_id ? documentTypeOptions.find(obj => {return obj.value == file.type_id})?.label : '' }
                        description={ file.name }
                        description2={ file.expiration_date ? file.expiration_tag +': '+YYYYMMDD_to_DMJYYYY(file.expiration_date) : ' ' }
                        tag={ file.expiration_tag }
                        tagColor={ file.expiration_color }
                        // description2={ file.mime_type }
                        menu={editable === true && [
                            { name:labels.current?.EDIT, color:'green', onPress: ()=>{
                                setUpdateDocumentID(file.id);
                                setDocumentName(file.name); documentNameInput.current.value = file.name;
                                setDocumentDesc(file.details); documentDescInput.current.value = file.details;
                                setDocumentExpiration(YYYYMMDD_to_MMDDYYYY(file.expiration_date)); documentExpirationInput.current.value = YYYYMMDD_to_MMDDYYYY(file.expiration_date);
                                setDocumentExpirationMasked(YYYYMMDD_to_MMDDYYYY(file.expiration_date));
                                setDocumentType(file.type_id);
                                setUpdateDocument(true);
                            }},
                            { name:labels.current?.DELETE, color:'red', onPress: ()=>{
                                setDeleteFileModalAffectID(file.id);
                                documentMimeType.current = file.mime_type;
                                setDeleteFileModalActive(true);
                            }}
                        ]}
                        onPress={() => {
                            setViewingDocumentObj(file);
                            setViewingDocument(true);
                        }}
                        viewMore={labels.current?.VIEW}
                    />
                )
            });
        }

        return documentAssets;
    },[labels, userYachts, setUpdateDocumentID, setDocumentName, setDocumentExpiration, setDocumentDesc, setDocumentType, setUpdateDocument, documentNameInput, documentExpirationInput, documentDescInput, setDeleteFileModalAffectID, setDeleteFileModalActive, enabledFeatures]);
    let addNewDocumentPaneAnimation = useSpring(
    {
        from: {
            willChange: 'transform',
            zIndex: 91,
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            backgroundColor: tw.color('gray0'),
            boxShadow: 'rgb(0 0 0 / 50%) 0px 0px 24px',
            transform: 'translateX(110%)',
        },
        to: {
            transform: updateDocument ? 'translateX(0%)' : 'translateX(110%)',
        },
        config: updateDocument ? { mass: 1, tension: 190, friction: 30 } : { mass: 1, tension: 190, friction: 15 }
    });

    let addNewDocumentPaneAnimationDesktop = useSpring(
    {
        from: {
            willChange: 'opacity',
            zIndex: 91,
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            opacity: 0,
            justifyContent: 'center',
            alignItems: 'center',
            pointerEvents: 'none',
            backdropFilter: 'blur(3px)',
        },
        to: {
            opacity: updateDocument ? 1 : 0,
            pointerEvents: updateDocument ? 'auto' : 'none',
        },
        config: updateDocument ? { mass: 1, tension: 190, friction: 30 } : { mass: 1, tension: 190, friction: 15 }
    });

    addNewDocumentPaneAnimation = tw.prefixMatch('md') ? addNewDocumentPaneAnimationDesktop : addNewDocumentPaneAnimation;

    let viewDocumentAnimation = useSpring(
    {
        from: {
            willChange: 'transform',
            zIndex: 91,
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            backgroundColor: tw.color('gray0'),
            boxShadow: 'rgb(0 0 0 / 50%) 0px 0px 24px',
            transform: 'translateX(110%)',
        },
        to: {
            transform: viewingDocument ? 'translateX(0%)' : 'translateX(110%)',
        },
        config: viewingDocument ? { mass: 1, tension: 190, friction: 30 } : { mass: 1, tension: 190, friction: 15 }
    });
    let viewDocumentAnimationDesktop = useSpring(
    {
        from: {
            willChange: 'opacity',
            zIndex: 91,
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            opacity: 0,
            justifyContent: 'center',
            alignItems: 'center',
            pointerEvents: 'none',
        },
        to: {
            opacity: viewingDocument ? 1 : 0,
            pointerEvents: viewingDocument ? 'auto' : 'none',
        },
        config: viewingDocument ? { mass: 1, tension: 190, friction: 30 } : { mass: 1, tension: 190, friction: 15 }
    });
    viewDocumentAnimation = tw.prefixMatch('md') ? viewDocumentAnimationDesktop : viewDocumentAnimation;

    React.useEffect(() => {
        let assets = [];
        
        if ( yachtID )
        {
            let yachtObj = false;
            userYachts.every((value, index) => {
                if ( yachtID == value.id ) { setYacht(value); yachtObj = value; return false; }
                return true;
            });

            let logs = [];

            if ( yachtObj.log_maintenance )
            {
                switch(getFilter)
                {
                    case labels.current?.ALL:
                        updateRouteFilter('logs', labels.current?.ALL);
                        logs = [...yachtObj.log_maintenance].sort(sort_by('modified_date', true));
                        break;

                    default:
                        updateRouteFilter('logs', getFilter);
                        logs = yachtObj.log_maintenance.filter(function (el)
                        {
                            return el.type_id == getFilter;
                        });
                        logs = [...logs].sort(sort_by('modified_date', true));
                        break;
                }

                logs.forEach((asset, index) => {
                    let icon = asset.type_id ? typeIcons.find(obj => {return obj.value == asset.type_id}).icon.trim() : 'miscellaneous';
                    if ( 'engines' === icon ) { icon = 'engine'; }
                    let icon_variant = '_white';
                    icon = icon + icon_variant;
                    icon = canIhazIcon( icon ) ? icon : 'miscellaneous' + icon_variant;
                    let formattedDate = YYYYMMDD_to_DMJYYYY(asset.date);
                    let inputDate = YYYYMMDD_to_MMDDYYYY(asset.date);
                    photos[asset.id] = prepareAssetPhotos(asset);
                    if ( asset.images?.length > 0 && ! iDsContainingFiles.current.includes(asset.id) ) { iDsContainingFiles.current.push(asset.id); }
                    attachments[asset.id] = prepareAssetAttachments(asset, 0 !== updateID);
                    if ( asset.attachments?.length > 0 && ! iDsContainingFiles.current.includes(asset.id) ) { iDsContainingFiles.current.push(asset.id); }
                    documents[asset.id] = prepareAssetDocuments(asset, 0 !== updateID);
                    if ( asset.documents?.length > 0 && ! iDsContainingFiles.current.includes(asset.id) ) { iDsContainingFiles.current.push(asset.id); }

                    assets.push(
                        <AssetItem
                            key={asset.id}
                            icon={{ uri: require('../svg/'+icon+'.svg') }}
                            color="blue"
                            title={ asset.type_id ? typeOptions.find(obj => {return obj.value == asset.type_id})?.label + ( asset.mechanical_id ? ' ('+ allMechanicalOptions.find(obj => {return obj.value == asset.mechanical_id})?.description?.replace('Location: ', '') +')' : '' ) : '' }
                            subtitle={ ( asset.images?.length > 0 || asset.attachments?.length > 0 || getAssetDocuments(yachtID, asset.id, userYachts)?.length > 0 ) &&
                                <View style={tw`flex-row`}>
                                    { asset.images?.length > 0 && enabledFeatures.photos &&
                                        <Image
                                            accessibilityHidden={true}
                                            source={{ uri: require('../svg/photos_lightgray.svg') }}
                                            resizeMode="contain"
                                            style={tw`h-4 w-4 mt-1 ml-2`}
                                        />
                                    }
                                    { asset.attachments?.length > 0 && enabledFeatures.attachments &&
                                        <Image
                                            accessibilityHidden={true}
                                            source={{ uri: require('../svg/attachment_lightgray.svg') }}
                                            resizeMode="contain"
                                            style={tw`h-4 w-4 mt-1 ml-2`}
                                        />
                                    }
                                    { getAssetDocuments(yachtID, asset.id, userYachts)?.length > 0 && enabledFeatures.documents &&
                                        <Image
                                            accessibilityHidden={true}
                                            source={{ uri: require('../svg/attachment_lightgray.svg') }}
                                            resizeMode="contain"
                                            style={tw`h-4 w-4 mt-1 ml-2`}
                                        />
                                    }
                                </View>
                            }
                            // description={ asset.location_id ? locationOptions.find(obj => {return obj.value == asset.location_id})?.label : '' }
                            date={ formattedDate }
                            info={ truncate(asset.detail, 30) }
                            // hours={asset.hours || '0'}
                            menu={[
                                { name:labels.current?.EDIT, color:'green', onPress: ()=>{
                                    setUpdateID(asset.id);
                                    setContentType(labels.current?.DETAILS);
                                    attachments[asset.id] = prepareAssetAttachments(asset, true);
                                    documents[asset.id] = prepareAssetDocuments(asset, true);
                                    setNewDesc(asset.detail); newDescInput.current.value = asset.detail;
                                    setNewType(asset.type_id);
                                    setNewLocation(asset.location_id);
                                    setNewDate(inputDate); newDateInput.current.value = inputDate;
                                    setNewDateMasked(inputDate);
                                    setNewHours(String(asset.hours || '')); newHoursInput.current.value = asset.hours || '';
                                    setNewNotes(asset.notes); newNotesInput.current.value = asset.notes;
                                    setNewMechanicalID(asset.mechanical_id || '');
                                    setNewEquipmentID(asset.equipment_id || '');

                                    setAddingNew(true);
                                }},
                                { name:labels.current?.DELETE, color:'red', onPress: ()=>{ setModalAffectID(asset.id); setModalActive(true) } }
                            ]}
                            onPress={ () => {
                                attachments[asset.id] = prepareAssetAttachments(asset, false);
                                documents[asset.id] = prepareAssetDocuments(asset, false);
                                setContentType(labels.current?.DETAILS);
                                setViewingAssetObj(asset);
                                setViewingAsset(true);
                            }}
                        />
                    )
                });
            }
        }
            
        setAssets(assets);
    },[getFilter, yachtID, userYachts, labels]);

    let addNewPaneAnimation = useSpring(
    {
        from: {
            willChange: 'transform',
            zIndex: 91,
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            backgroundColor: tw.color('gray0'),
            boxShadow: 'rgb(0 0 0 / 50%) 0px 0px 24px',
            transform: 'translateX(110%)',
        },
        to: {
            transform: addingNew ? 'translateX(0%)' : 'translateX(110%)',
        },
        config: addingNew ? { mass: 1, tension: 190, friction: 30 } : { mass: 1, tension: 190, friction: 15 }
    });

    let addNewPaneAnimationDesktop = useSpring(
    {
        from: {
            willChange: 'opacity',
            zIndex: 91,
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            opacity: 0,
            justifyContent: 'center',
            alignItems: 'center',
            pointerEvents: 'none',
            backdropFilter: 'blur(3px)',
        },
        to: {
            opacity: addingNew ? 1 : 0,
            pointerEvents: addingNew ? 'auto' : 'none',
        },
        config: addingNew ? { mass: 1, tension: 190, friction: 30 } : { mass: 1, tension: 190, friction: 15 }
    });

    addNewPaneAnimation = tw.prefixMatch('md') ? addNewPaneAnimationDesktop : addNewPaneAnimation;

    let viewPaneAnimation = useSpring(
    {
        from: {
            willChange: 'transform',
            zIndex: 91,
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            backgroundColor: tw.color('gray0'),
            boxShadow: 'rgb(0 0 0 / 50%) 0px 0px 24px',
            transform: 'translateX(110%)',
        },
        to: {
            transform: viewingAsset ? 'translateX(0%)' : 'translateX(110%)',
        },
        config: viewingAsset ? { mass: 1, tension: 190, friction: 30 } : { mass: 1, tension: 190, friction: 15 }
    });

    let viewPaneAnimationDesktop = useSpring(
    {
        from: {
            willChange: 'opacity',
            zIndex: 91,
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            opacity: 0,
            justifyContent: 'center',
            alignItems: 'center',
            pointerEvents: 'none',
            backdropFilter: 'blur(3px)',
        },
        to: {
            opacity: viewingAsset ? 1 : 0,
            pointerEvents: viewingAsset ? 'auto' : 'none',
        },
        config: viewingAsset ? { mass: 1, tension: 190, friction: 30 } : { mass: 1, tension: 190, friction: 15 }
    });

    viewPaneAnimation = tw.prefixMatch('md') ? viewPaneAnimationDesktop : viewPaneAnimation;

    return (
        <View label="yachtNotes" style={tw`bg-gray0 h-screen md:flex-row overflow-hidden max-w-screen`}>

            <PrimaryNav navigation={navigation} setLoggedIn={setLoggedIn} hideOnMobile={true} yachtID={yachtID} />

            <View style={tw`bg-blue h-20 flex-row md:hidden`}>
                <View style={tw`bg-blue h-20 p-5 pt-6.5 flex-row`}>
                    <Pressable
                        style={tw`p-2 -ml-2 -mt-2`}
                        onPress={() => { goPrevScreen(navigation, 'yacht', {yachtID: yachtID}) }}
                    >
                        <Image
                            accessibilityLabel={ labels.current?.CANCEL }
                            source={{ uri: require('../svg/back_white.svg') }}
                            resizeMode="contain"
                            style={tw`h-6 w-6 top-px mr-1`}
                        />
                    </Pressable>
                    <Span style={tw`bg-${yacht.color || 'gray6'} text-white uppercase rounded-full text-sm font-semibold shrink-0 w-8 h-8 mr-3 text-center leading-[31px] -mt-[2px]`}>{yacht.initials}</Span>
                    <Heading style={tw`mt-1.5 leading-4 mb-0 text-white`} hlevel={1} styleHlevel={4}>
                        { labels.current?._ASSETS }
                    </Heading>
                </View>

                <View style={tw`grow`}></View>
                
                <View style={tw`w-18 justify-center md:order-2 md:w-full`}>
                    <Pressable
                        onPress={()=>{navigation.navigate('notifications')}}
                        style={tw`p-5 relative md:flex-row md:pl-15 md:py-3 md:my-2`}
                    >
                        { notificationCount > 0 &&
                            <Span style={tw`bg-white text-blue rounded-full min-w-4 h-4 text-center text-xs font-bold absolute bottom-1/2 left-1/2 md:left-18 z-10 pointer-events-none`}>
                                { notificationCount }
                            </Span>
                        }
                        
                        <Image
                            accessibilityLabel={ labels.current?.NOTIFICATIONS }
                            source={{ uri: require('../svg/notifications.svg') }}
                            resizeMode="contain"
                            style={tw`h-5 md:h-8 w-full md:w-8 relative z-0 -left-1`}
                        />
                        
                        <Span style={tw`hidden md:flex font-bold text-base text-white leading-8 ml-4 tracking-wide`}>{ labels.current?.NOTIFICATIONS }</Span>
                        
                    </Pressable>
                </View>
            </View>
        
            <View style={tw`bg-blue h-8 md:h-full md:w-10 z-0 -mb-3 md:mb-0 md:-mr-3`}>
                <View style={tw`bg-gray0 h-full w-full rounded-t-3xl md:rounded-l-3xl md:rounded-r-0 z-10 md:shadow-black md:shadow-opacity-10 md:shadow-radius-4`}></View>
            </View>

            <View style={[tw`bg-gray0 grow md:pr-8 md:pl-4 z-20`, {width: tw.prefixMatch('md') ? 'calc(100% - 320px)' : '100%', height: tw.prefixMatch('md') ? '100%' : 'calc(100% - 112px)'}]}>

                { ! tw.prefixMatch('md') && 'object' === typeof yacht && yacht.log_maintenance.length > 0 &&
                    <View style={tw`h-13 px-5 pb-5 md:py-0 md:px-0 md:h-24 lg:h-33 flex-row justify-end md:items-center z-10`}>

                        { /* <TextInput
                            style={tw`shrink-1 mr-5 p-1.5 max-w-60`}
                            image={{ uri: require('../svg/search_gray.svg') }}
                            placeholder="Search"
                            // value={filterText}
                            // onChangeText={(value)=>{setFilterText(value)}}
                        /> */ }

                        <Pressable
                            style={tw`border border-gray3 py-1 px-2 md:py-1.5 md:px-3 rounded flex-row md:mr-4 md:min-w-60`}
                            onPress={()=>{setFilterVisible(true)}}
                        >
                            <Image
                                accessibilityHidden={true}
                                source={{ uri: require('../svg/sort.svg') }}
                                resizeMode="contain"
                                style={tw`h-4 w-4 top-0.5 md:top-1 mr-2`}
                            />

                            <Span style={tw`text-sm md:text-base leading-4`}>{ labels.current?.VIEW }: <Span style={tw`text-sm md:text-base font-medium`}>{labels.current?.ALL !== getFilter ? typeFilterOptions.find(obj => {return obj.value == getFilter})?.label : getFilter}</Span></Span>

                        </Pressable>

                    </View>
                }

                <View style={! getAssets.length ? tw`grow` : tw`shrink`}>
                    <ScrollView
                        centerContent={getAssets.length ? false : true}
                        style={{width:'100%',overflowX:'visible'}}
                        contentContainerStyle={tw`w-full min-h-full justify-start`}
                    >
                        <View style={tw`hidden md:flex`}>
                                <View style={tw`flex-row w-full items-center justify-between lg:justify-start mt-2 md:mt-6 lg:mt-10 mb-0 md:mb-1 lg:mb-5 md:px-1`}>
                                    <Span style={tw`bg-${yacht.color || 'gray6'} text-white uppercase rounded-full text-lg font-semibold shrink-0 w-14 h-14 mr-3 text-center leading-[56px]`}>{yacht.initials}</Span>

                                    <Heading style={tw`leading-4 hidden md:flex`} hlevel={1} styleHlevel={2}>
                                    {yacht.name}
                                </Heading>

                                { 'object' === typeof yacht && yacht.log_maintenance.length > 0 &&
                                    <View style={tw`px-5 md:py-0 md:px-0 flex-row md:items-center z-10`}>

                                        { /* <TextInput
                                            style={tw`shrink-1 mr-5 p-1.5 max-w-60`}
                                            image={{ uri: require('../svg/search_gray.svg') }}
                                            placeholder="Search"
                                            // value={filterText}
                                            // onChangeText={(value)=>{setFilterText(value)}}
                                        /> */ }

                                        <Pressable
                                            style={tw`border border-gray3 py-1 px-2 md:py-1.5 md:px-3 rounded flex-row md:mr-4 md:min-w-60`}
                                            onPress={()=>{setFilterVisible(true)}}
                                        >
                                            <Image
                                                accessibilityHidden={true}
                                                source={{ uri: require('../svg/sort.svg') }}
                                                resizeMode="contain"
                                                style={tw`h-4 w-4 top-0.5 md:top-1 mr-2`}
                                            />

                                            <Span style={tw`text-sm md:text-base leading-4`}>{ labels.current?.VIEW }: <Span style={tw`text-sm md:text-base font-medium`}>{labels.current?.ALL !== getFilter ? typeFilterOptions.find(obj => {return obj.value == getFilter})?.label : getFilter}</Span></Span>

                                        </Pressable>

                                    </View>
                                }
                            </View>
                        </View>

                        { '' !== primaryMsg &&
                            <View style={tw`w-full px-4 md:px-1`}>
                                <Notification
                                    style={tw`my-4`}
                                    type={primaryMsgType}
                                >{primaryMsg}</Notification>
                            </View>
                        }
                        { ! getAssets.length &&
                            <View style={tw`justify-center grow items-center`}>
                                
                                <Image
                                    accessibilityHidden={true}
                                    source={{ uri: require('../svg/maintenance_gray.svg') }}
                                    resizeMode="contain"
                                    style={tw`h-32 w-32 md:h-48 md:w-48`}
                                />                            
                                <Heading style={tw`text-gray5 text-center p-5 mb-26`} hlevel={tw.prefixMatch('md') ? 2 : 3}>{ yacht?.log_maintenance?.length > 0 ? getLabel('SEARCH_NOTFOUND_TITLE') : labels.current?._404_TEXT }</Heading>
                                
                                <View style={tw`fixed bottom-9 md:bottom-18 right-18 pointer-events-none`}>
                                    
                                    <Span style={tw`text-sm md:text-base font-medium font-sans mb-2 mr-6`}>{ labels.current?.CONTENT_ADD }</Span>
                                    
                                    <Image
                                        accessibilityHidden={true}
                                        source={{ uri: require('../svg/arrow_organic.svg') }}
                                        resizeMode="contain"
                                        style={tw`h-9 w-18 left-14 md:left-16`}
                                    />
                                </View>
                            </View>
                        }
                        
    
                        { getAssets.length > 0 &&
                            <View>
                                <Heading hlevel={2} styleHlevel={4} style={tw`hidden md:flex pl-1`}>{ labels.current?._ASSETS }</Heading>
                                
                                <View style={tw`md:pl-1 md:flex-wrap md:flex-row mb-20 md:justify-start md:items-start`}>
                                    {getAssets}
                                </View>
                            </View>
                        }
                        
                    </ScrollView>

                    <AddNewAssetButtons addNewLabel={ labels.current?.ADD +' '+ labels.current?._ASSET } onAddNew={()=>{
                        setUpdateID(0);
                        setContentType(labels.current?.DETAILS);
                        setNewAssetMsg('');

                        newDescInput.current.value = '';
                        newDateInput.current.value = '';
                        newHoursInput.current.value = '';
                        newNotesInput.current.value = '';
                        setNewDesc('');
                        setNewType('');
                        setNewLocation('');
                        setNewDate('');
                        setNewDateMasked('');
                        setNewHours('');
                        setNewNotes('');
                        setNewMechanicalID('');
                        setNewEquipmentID('');

                        setAddingNew(true);
                    }} />
                </View>
            </View>

            <AnimatedView style={addNewPaneAnimation}>

                <Pressable style={tw`hidden md:flex bg-blue/75 w-screen h-screen left-0 top-0 fixed`}
                    onPress={() => {setNewAssetMsg(''); setAddingNew(false)}}
                ></Pressable>

                <View style={tw`md:w-188 md:rounded-md md:shadow-black md:shadow-opacity-25 md:shadow-radius-8`}>
                
                    <View style={tw`bg-blue md:bg-gray0 md:border-b-2 md:border-gray1 md:items-center h-20 p-5 md:px-9 pt-6.5 flex-row md:rounded-t-md`}>
                        <Pressable
                            style={tw`p-2 -ml-2 -mt-2`}
                            onPress={() => {setNewAssetMsg(''); setAddingNew(false)}}
                        >
                            <Image
                                accessibilityLabel={ labels.current?.CANCEL }
                                source={{ uri: tw.prefixMatch('md') ? require('../svg/back.svg') : require('../svg/back_white.svg') }}
                                resizeMode="contain"
                                style={tw`h-6 w-6 top-px md:top-1 md:mr-4`}
                            />
                        </Pressable>
                        <Heading style={tw`mt-1.5 md:mt-0 leading-4 mb-0 text-white md:text-ink`} hlevel={1} styleHlevel={tw.prefixMatch('md') ? 2 : 4}>{ ( 0 !== updateID ? labels.current?.EDIT : labels.current?.ADD ) +' '+ labels.current?._ASSET }</Heading>
                    </View>
                
                    <View style={tw`bg-blue h-8 z-0 -mb-3 md:hidden`}>
                        <View style={tw`bg-gray0 h-full w-full rounded-t-3xl`}></View>
                    </View>
        
                    <View style={[tw`bg-gray0 grow md:pr-4 md:pb-5 md:pt-8 md:pl-4 z-20 md:rounded-b-md w-full`, { height: tw.prefixMatch('md') ? 'max-content' : formHeight}]}>
                        <View style={tw`h-full md:h-max`}>

                            { 0 !== updateID &&
                                <PillMenu
                                    style={tw`w-auto mx-5.5 mb-3 md:mb-4 md:-mt-2`}
                                    selected={ contentType }
                                    menu={[
                                        { name:labels.current?.DETAILS, icon:{ uri: require('../svg/notes_white.svg') }, inactiveIcon:{ uri: require('../svg/notes.svg') }, onPress:()=>{ setContentType(labels.current?.DETAILS); } },
                                        ...(enabledFeatures.photos ? [{ name:labels.current?.PHOTOS, icon:{ uri: require('../svg/photos_white.svg') }, inactiveIcon:{ uri: require('../svg/photos.svg') }, onPress:()=>{ setContentType(labels.current?.PHOTOS); } }] : []),
                                        ...(enabledFeatures.attachments ? [{ name: labels.current?.ATTACHMENTS, icon: { uri: require('../svg/attachment_white.svg') }, inactiveIcon: { uri: require('../svg/attachment.svg') }, onPress: () => { setContentType(labels.current?.ATTACHMENTS); } }] : []),
                                        ...(enabledFeatures.documents ? [{ name: labels.current?.DOCUMENTS, icon: { uri: require('../svg/attachment_white.svg') }, inactiveIcon: { uri: require('../svg/attachment.svg') }, onPress: () => { setContentType(labels.current?.DOCUMENTS); } }] : [])
                                    ]}
                                />
                            }

                            <ScrollView
                                style={{width:'100%',overflowX:tw.prefixMatch('md') ? 'visible' : 'hidden'}}
                                contentContainerStyle={tw`w-full justify-start px-5 pb-5 md:pb-0`}
                            >
                                { '' !== newAssetMsg &&
                                    <View style={tw`w-full md:pr-2`}>
                                        <Notification
                                            style={tw`my-2`}
                                            type={newAssetMsgType}
                                        >{newAssetMsg}</Notification>
                                    </View>
                                }
                                
                                <View style={[tw`md:flex-row md:flex-wrap md:justify-between`, labels?.current?.DETAILS !== contentType && tw`hidden`]}>

                                    <TextInput
                                        style={tw`mt-2 md:w-48.5/100`}
                                        autoCapitalize="off"
                                        placeholder={ labels.current?.DETAIL + '*' }
                                        image={ newDesc ? { uri: require('../svg/generalinfo.svg') } : { uri: require('../svg/generalinfo_gray.svg') }}
                                        pattern="^[\w\d\s\S]{1,36}$"
                                        validatedValue={(value) => {setNewDesc(value)}}
                                        validateOnChange={true}
                                        passedRef={newDescInput}
                                        stateValue={newDesc}
                                        required
                                    />

                                    <Pressable
                                        style={tw`mt-2 md:w-48.5/100`}
                                        onPress={()=>{setTypeSelectVisible(true)}}
                                    >
                                        <TextInput
                                            style={tw`pointer-events-none`}
                                            placeholder={ labels.current?.TYPE + '*' }
                                            image={ newType ? { uri: require('../svg/maintenanceType_blue.svg') } : { uri: require('../svg/maintenanceType_gray.svg') }}
                                            value={newType ? typeOptions.find(obj => {return obj.value == newType})?.label : ''}
                                            required
                                        />
    
                                        <Image
                                            accessibilityHidden={true}
                                            source={{ uri: require('../svg/chevron_down_blue.svg') }}
                                            resizeMode="contain"
                                            style={tw`h-3 w-3 absolute top-1/2 -translate-y-1/2 right-4`}
                                        />
                                    </Pressable>
                                    
                                    <TextInput
                                        keyboardType="numeric"
                                        mask="99/99/9999"
                                        style={tw`mt-2 md:mt-4 md:w-full`}
                                        autoCapitalize="off"
                                        placeholder="MM/DD/YYYY*"
                                        image={ newDate ? { uri: require('../svg/calendar_blue.svg') } : { uri: require('../svg/calendar_gray.svg') }}
                                        pattern="^(0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])[- /.](19|20)[0-9]{2}$"
                                        validatedValue={(value) => {setNewDate(value)}}
                                        validateOnChange={true}
                                        passedRef={newDateInput}
                                        // stateValue={newDate}
                                        value={newDateMasked}
                                        onChangeText={(value)=>{setNewDateMasked(value)}}
                                        required
                                    />

                                    <Span style={tw`text-sm text-gray6 my-2 w-full`}>{ '* ' + labels.current?.REQUIRED }</Span>

                                    <Pressable
                                        style={[tw`mt-2 md:mt-4 md:w-48.5/100`, true !== newLocationVisible ? tw`hidden` : ( newEquipmentID ? tw`grayscale opacity-80 cursor-not-allowed` : {} )]}
                                        onPress={newEquipmentID ? ()=>{return false} : ()=>{setLocationSelectVisible(true)}}
                                    >
                                        <TextInput
                                            style={tw`pointer-events-none`}
                                            placeholder={ labels.current?.LOCATION }
                                            image={ newLocation ? { uri: require('../svg/location_blue.svg') } : { uri: require('../svg/location_gray.svg') }}
                                            value={newLocation ? locationOptions.find(obj => {return obj.value == newLocation})?.label : ''}
                                            disabled={newEquipmentID ? true : false}
                                        />

                                        <Image
                                            accessibilityHidden={true}
                                            source={{ uri: require('../svg/chevron_down_blue.svg') }}
                                            resizeMode="contain"
                                            style={tw`h-3 w-3 absolute top-1/2 -translate-y-1/2 right-4`}
                                        />
                                    </Pressable>

                                    <Pressable
                                        style={[tw`mt-2 md:mt-4 md:w-48.5/100`, true !== newMechanicalIDVisible ? tw`hidden` : {}]}
                                        onPress={()=>{setMechanicalSelectVisible(true)}}
                                    >
                                        <TextInput
                                            style={tw`pointer-events-none`}
                                            placeholder={ labels.current?.ENGINE }
                                            image={ newMechanicalID ? { uri: require('../svg/engine.svg') } : { uri: require('../svg/engine_gray.svg') }}
                                            value={newMechanicalID ? mechanicalOptions.find(obj => {return obj.value == newMechanicalID})?.label : ''}
                                        />

                                        <Image
                                            accessibilityHidden={true}
                                            source={{ uri: require('../svg/chevron_down_blue.svg') }}
                                            resizeMode="contain"
                                            style={tw`h-3 w-3 absolute top-1/2 -translate-y-1/2 right-4`}
                                        />
                                    </Pressable>

                                    <Pressable
                                        style={[tw`mt-2 md:mt-4 md:w-48.5/100`, true === newMechanicalIDVisible ? tw`hidden` : {}]}
                                        onPress={()=>{setEquipmentSelectVisible(true)}}
                                    >
                                        <TextInput
                                            style={tw`pointer-events-none`}
                                            placeholder={ labels.current?.EQUIPMENT }
                                            image={ newEquipmentID ? { uri: require('../svg/equipment.svg') } : { uri: require('../svg/equipment_gray.svg') }}
                                            value={newEquipmentID ? allEquipmentOptions.find(obj => {return obj.value == newEquipmentID})?.label : ''}
                                        />

                                        <Image
                                            accessibilityHidden={true}
                                            source={{ uri: require('../svg/chevron_down_blue.svg') }}
                                            resizeMode="contain"
                                            style={tw`h-3 w-3 absolute top-1/2 -translate-y-1/2 right-4`}
                                        />
                                    </Pressable>
                                    
                                    <TextInput
                                        style={[tw`mt-2 md:mt-4`, true === newMechanicalIDVisible ? tw`md:w-48.5/100` : tw`hidden`]}
                                        autoCapitalize="off"
                                        placeholder={ labels.current?.HOURS }
                                        image={ newHours ? { uri: require('../svg/enginehours_blue.svg') } : { uri: require('../svg/enginehours_gray.svg') }}
                                        pattern="^[0-9]+(\.[0-9]+)?$"
                                        keyboardType="numeric"
                                        validatedValue={(value) => {setNewHours(value)}}
                                        validateOnChange={true}
                                        passedRef={newHoursInput}
                                        stateValue={newHours}
                                    />
                                    
                                    <TextInput
                                        style={tw`mt-2 md:mt-4 md:w-full`}
                                        autoCapitalize="off"
                                        placeholder={ labels.current?.NOTES }
                                        image={ newNotes ? { uri: require('../svg/notes_blue.svg') } : { uri: require('../svg/notes_gray.svg') }}
                                        multiline={true}
                                        numberOfLines={8}
                                        pattern="^[\s\S]{0,500}$"
                                        validatedValue={(value) => {setNewNotes(value)}}
                                        validateOnChange={true}
                                        passedRef={newNotesInput}
                                        stateValue={newNotes}
                                    />
                                    <View style={tw`flex-row justify-end w-full mt-1 mr-1`}>
                                        <Span style={[tw`text-sm text-gray6`, newNotesInput.current?.value.length > 500 && tw`text-red`]}>{`${newNotesInput.current?.value.length}/500`}</Span>
                                    </View>

                                </View>

                                <View style={[tw`md:flex-row md:flex-wrap md:justify-between md:pb-5`, ( labels?.current?.PHOTOS !== contentType || ! updateID ) && tw`hidden`]}>

                                    <ScrollView
                                        style={[{width:'100%',overflowX:tw.prefixMatch('md') ? 'visible' : 'hidden'}, tw`pt-4 rounded bg-white border border-gray4 mt-2`, photos[updateID]?.length && tw`pl-4`]}
                                        contentContainerStyle={tw`w-full justify-start h-[${height - 200}px] md:h-[272px]`}
                                    >
                                        { photos[updateID]?.length ? <View style={tw`flex-wrap flex-row justify-start items-start`}>{ photos[updateID] }</View> : <View></View> }
                                        { ! photos[updateID]?.length &&
                                            <View style={tw`justify-center grow items-center mt-4`}>
                                                <Image
                                                    accessibilityHidden={true}
                                                    source={{ uri: require('../svg/photos_lightgray.svg') }}
                                                    resizeMode="contain"
                                                    style={tw`h-28 w-28 md:h-40 md:w-40`}
                                                />
                                                <Heading style={tw`text-gray5 text-center p-5`} hlevel={tw.prefixMatch('md') ? 2 : 3}>{ labels.current?._404_PHOTOS_TEXT }</Heading>
                                            </View>
                                        }
                                    </ScrollView>

                                    <ImagesUploadInput
                                        passedRef={newPhotosInput}
                                        onChange={(e)=>{
                                            if ( ! e.target.files[0] ) { return; }

                                            let imagesArray = processImagesInput(e.target.files).then((imagesArray)=>{
                                                if ( 'string' === typeof imagesArray )
                                                {
                                                    toast.show(imagesArray, {type: 'danger', duration:10000});
                                                    return;
                                                }
                                                setIsImageLoading(true);
                                                uploadImages(
                                                    imagesArray,
                                                    photoObjectType.current,
                                                    updateID,
                                                    yachtID,
                                                    toast,
                                                    labels.current?.TOAST_ADD.replace('{object}',labels.current?.PHOTOS),
                                                    {apiUrl:apiUrl, apiVersion:apiVersion},
                                                    function (status) {
                                                        setIsImageLoading(false);
                                                        if ( 'success' === status )
                                                        {
                                                            setLastUpdate(now());
                                                        }
                                                    }
                                                );
                                            })
                                        }}
                                    />

                                    <AddNewAssetButtons style={tw`bottom-10 right-9 md:right-10`} hideRefresh={true} hideTooltips={true} isLoading={isImageUploading} addNewLabel={ labels.current?.ADD +' '+ labels.current?.PHOTOS } onAddNew={()=>{
                                        /* trigger click on #image-picker */
                                        newPhotosInput.current.click();
                                    }} />

                                </View>

                                <View style={[tw`md:flex-row md:flex-wrap md:justify-between md:pb-5`, ( labels?.current?.ATTACHMENTS !== contentType || ! updateID ) && tw`hidden`]}>

                                    <ScrollView
                                        style={[{width:'100%',overflowX:tw.prefixMatch('md') ? 'visible' : 'hidden'}, tw`pt-4 rounded bg-white border border-gray4 mt-2`, attachments[updateID]?.length && tw`pl-4`]}
                                        contentContainerStyle={tw`w-full justify-start h-[${height - 200}px] md:h-[272px]`}
                                    >
                                        { attachments[updateID]?.length ? <View style={tw`md:flex-wrap md:flex-row pb-16 md:justify-start md:items-start`}>{ attachments[updateID] }</View> : <View></View> }
                                        { ! attachments[updateID]?.length &&
                                            <View style={tw`justify-center grow items-center mt-4`}>
                                                <Image
                                                    accessibilityHidden={true}
                                                    source={{ uri: require('../svg/attachment_lightgray.svg') }}
                                                    resizeMode="contain"
                                                    style={tw`h-28 w-28 md:h-40 md:w-40`}
                                                />
                                                <Heading style={tw`text-gray5 text-center p-5`} hlevel={tw.prefixMatch('md') ? 2 : 3}>{ labels.current?._404_ATTACHMENTS_TEXT }</Heading>
                                            </View>
                                        }
                                    </ScrollView>

                                    <AttachmentUploadInput
                                        passedRef={newAttachmentInput}
                                        onChange={(e)=>{
                                            if ( ! e.target.files[0] ) { return; }
                                            // if size is > 30 MB
                                            if ( e.target.files[0].size > 31457280 ) { toast.show(labels.current?.ATTACHMENT_TOO_LARGE_BODY.replace('%size%', '30 MB'), {type: 'danger', duration:10000}); return; }

                                            attachmentFileNameInput.current.value = e.target.files[0].name;
                                            attachmentMimeType.current = e.target.files[0].type;
                                            processAttachmentInput(e.target.files[0]).then((result)=>{
                                                attachmentData.current = result;
                                            }).catch((error)=>{
                                                toast.show(error, {type: 'danger', duration:10000});
                                            });

                                        }}
                                    />

                                    <AddNewAssetButtons style={tw`bottom-10 right-9 md:right-10`} hideRefresh={true} hideTooltips={true} addNewLabel={ labels.current?.ADD +' '+ labels.current?.ATTACHMENTS } onAddNew={()=>{
                                        setUpdateAttachmentID(0);

                                        attachmentData.current = '';
                                        if ( attachmentFileNameInput.current?.value ) { attachmentFileNameInput.current.value = ''; }
                                        attachmentMimeType.current = '';
                                        setAttachmentType(0);
                                        setAttachmentDesc('');
                                        if ( newAttachmentDescInput.current?.value ) { newAttachmentDescInput.current.value = ''; }

                                        setUpdateAttachment(true);
                                    }} />

                                </View>

                                <View style={[tw`md:flex-row md:flex-wrap md:justify-between md:pb-5`, ( labels?.current?.DOCUMENTS !== contentType || ! updateID ) && tw`hidden`]}>

                                    <ScrollView
                                        style={[{width:'100%',overflowX:tw.prefixMatch('md') ? 'visible' : 'hidden'}, tw`pt-4 rounded bg-white border border-gray4 mt-2`, documents[updateID]?.length && tw`pl-4`]}
                                        contentContainerStyle={tw`w-full justify-start h-[${height - 200}px] md:h-[272px]`}
                                    >
                                        { documents[updateID]?.length ? <View style={tw`md:flex-wrap md:flex-row pb-16 md:justify-start md:items-start`}>{ documents[updateID] }</View> : <View></View> }
                                        { ! documents[updateID]?.length &&
                                            <View style={tw`justify-center grow items-center mt-4`}>
                                                <Image
                                                    accessibilityHidden={true}
                                                    source={{ uri: require('../svg/attachment_lightgray.svg') }}
                                                    resizeMode="contain"
                                                    style={tw`h-28 w-28 md:h-40 md:w-40`}
                                                />
                                                <Heading style={tw`text-gray5 text-center p-5`} hlevel={tw.prefixMatch('md') ? 2 : 3}>{ labels.current?._404_DOCUMENTS_TEXT }</Heading>
                                            </View>
                                        }
                                    </ScrollView>

                                    <DocumentUploadInput
                                        passedRef={documentInput}
                                        onChange={(e)=>{
                                            if ( ! e.target.files[0] ) { return; }
                                            // if size is > 30 MB
                                            if ( e.target.files[0].size > 31457280 ) { toast.show(labels.current?.DOCUMENT_TOO_LARGE_BODY.replace('%size%', '30 MB'), {type: 'danger', duration:10000}); return; }

                                            documentFileNameInput.current.value = e.target.files[0].name;
                                            processDocumentInput(e.target.files[0]).then((result)=>{
                                                setDocumentData(result);
                                                documentMimeType.current = e.target.files[0].type;
                                                documentSize.current = e.target.files[0].size;
                                            }).catch((error)=>{
                                                toast.show(error, {type: 'danger', duration:10000});
                                            });
                                        }}
                                    />

                                    <AddNewAssetButtons style={tw`bottom-10 right-9 md:right-10`} hideRefresh={true} hideTooltips={true} addNewLabel={ labels.current?.ADD +' '+ labels.current?.DOCUMENT } onAddNew={()=>{
                                        setUpdateDocumentID(0);

                                        setDocumentData('');
                                        documentSize.current = 0;
                                        if ( documentFileNameInput.current?.value ) { documentFileNameInput.current.value = ''; }
                                        documentMimeType.current = '';
                                        setDocumentName('');
                                        if ( documentNameInput.current?.value ) { documentNameInput.current.value = ''; }
                                        setDocumentExpiration('');
                                        setDocumentExpirationMasked('');
                                        if ( documentExpirationInput.current?.value ) { documentExpirationInput.current.value = ''; }
                                        setDocumentType(0);
                                        setDocumentDesc('');
                                        if ( documentDescInput.current?.value ) { documentDescInput.current.value = ''; }

                                        setUpdateDocument(true);
                                    }} />

                                </View>
                            </ScrollView>

                            <HorizontalRule style={tw`md:hidden`} />
                                
                            <Button
                                style={[tw`m-5`, labels?.current?.DETAILS !== contentType && tw`hidden`]}
                                title={0 !== updateID ? labels.current?.UPDATE : labels.current?.ADD}
                                disabled={
                                    newType
                                    && newDateMasked
                                    && newDesc ? false : true}
                                validateGroup={newAssetValidation}
                                onPress={()=>{
                                    console.log('Log:'); 
                                    console.log({
                                        vessel_id: yachtID,
                                        location_id: ! newMechanicalID && newLocation ? newLocation : 0,
                                        mechanical_id: ! newLocation && newMechanicalID ? newMechanicalID : 0,
                                        equipment_id: newMechanicalID || ! newEquipmentID ? 0 : newEquipmentID,
                                        type_id: newType,
                                        date: MMDDYYYY_to_YYYYMMDD(newDateMasked),
                                        detail: newDesc,
                                        hours: newMechanicalID ? newHours : '',
                                        notes: newNotes,
                                    });

                                    if ( newAssetValidation ) { return; }

                                    doNewAssetValidation(true);
                                    setNewAssetMsg('');

                                    setTimeout(()=>
                                    {
                                        // check if fields are valid

                                        // only required fields should be:
                                        // newType
                                        // newDateMasked
                                        // newDesc

                                        if ( ! newType
                                            || ! newDesc
                                            || ! newDate
                                            || ! newDateMasked
                                            || ( ! newNotes && newNotesInput.current?.value.length > 0 )
                                        )
                                        {
                                            let errorMsg = [];

                                            if ( ! newType )
                                                { errorMsg.push(labels.current?.REQUEST +' '+labels.current?.TYPE); }
                                            if ( ! newDateMasked )
                                                { errorMsg.push(labels.current?.REQUEST +' '+labels.current?.DATE); }
                                            if ( ! newDate )
                                                { errorMsg.push(labels.current?.INVALID_DATE); }
                                            if ( ! newDesc )
                                                { errorMsg.push(labels.current?.REQUEST +' '+labels.current?.DETAIL); }
                                            if ( ! newNotes && newNotesInput.current?.value.length > 0 )
                                                { errorMsg.push(labels.current?.REQUEST_VALID +' '+labels.current?.NOTES); }

                                            setNewAssetMsgType('warning');
                                            setNewAssetMsg(errorMsg.join('\n'));
                                            doNewAssetValidation(false);
                                        }
                                        else
                                        {
                                            let payload = {
                                                vessel_id: yachtID,
                                                location_id: ! newMechanicalID && newLocation ? newLocation : 0,
                                                mechanical_id: ! newLocation && newMechanicalID ? newMechanicalID : 0,
                                                equipment_id: newMechanicalID || ! newEquipmentID ? 0 : newEquipmentID,
                                                type_id: newType,
                                                date: MMDDYYYY_to_YYYYMMDD(newDateMasked),
                                                detail: newDesc,
                                                hours: newMechanicalID ? newHours : '',
                                                notes: newNotes,
                                            };
                                            if ( 0 !== updateID ) { payload.id = updateID; }
                                            
                                            fetch(apiUrl, {
                                                method: 'POST',
                                                cache: 'no-cache',
                                                headers: {
                                                    'Content-Type': 'application/json'
                                                },
                                                body: JSON.stringify({
                                                    endpoint: 0 !== updateID ? 'logs/maintenance/update' : 'logs/maintenance/add', v: apiVersion,
                                                    device_id: localStorage.getItem('deviceID'),
                                                    client_key: localStorage.getItem('clientKey'),
                                                    payload: payload
                                                }),
                                            })
                                            .then((response) => {
                                                return response.text().then(function(text) {
                                                    const result = JSON.parse(text);
                                                    if ( result.error )
                                                    {
                                                        if ( '401' === result.error ){ setLoggedIn(false); result.error = 'Your session token has expired, please log in again.'; }
                                                        setNewAssetMsgType('warning');
                                                        setNewAssetMsg(result.error);
                                                        doNewAssetValidation(false);
                                                    }
                                                    else
                                                    {
                                                        setAddingNew(false); 
                                                        doNewAssetValidation(false);
                                                        toast.show((0 !== updateID ? labels.current?.TOAST_UPDATE : labels.current?.TOAST_ADD).replace('{object}',labels.current?._ASSET), {type: 'success', duration:2000});
                                        
                                                        setTimeout(()=>{
                                                            setLastUpdate(now()); // forces app to refetch all account data
                                                        }, 500);

                                                        setTimeout(()=>{
                                                            setNewDesc(''); newDescInput.current.value = '';
                                                            setNewType('');
                                                            setNewLocation('');
                                                            setNewDate(''); newDateInput.current.value = '';
                                                            setNewDateMasked('');
                                                            setNewHours(''); newHoursInput.current.value = '';
                                                            setNewNotes(''); newNotesInput.current.value = '';
                                                            setNewMechanicalID('');
                                                            setNewEquipmentID('');
                                                        }, 1500);
                                                    }
                                                });
                                            })
                                            .catch(error => {
                                                console.warn(error);
                                                setNewAssetMsgType('warning');
                                                if ( ! window.navigator.onLine ) { setNewAssetMsg('Internet connection failed. Please try again.'); }
                                                else { setNewAssetMsg('An error ocurred. See console log.'); }
                                                doNewAssetValidation(false);
                                            });
                                        }
                                    }, 250);
                                    
                                    
                                }}
                            />
                        </View>
                    </View>
                </View>
            </AnimatedView>

            <AnimatedView style={viewPaneAnimation}>

                <Pressable style={tw`hidden md:flex bg-blue/75 w-screen h-screen left-0 top-0 fixed`}
                    onPress={() => {setViewingAsset(false)}}
                ></Pressable>

                <View style={tw`md:w-188 md:rounded-md md:shadow-black md:shadow-opacity-25 md:shadow-radius-8`}>
                
                    <View style={tw`bg-blue md:bg-gray0 md:border-b-2 md:border-gray1 md:items-center h-20 p-5 md:px-9 pt-6.5 flex-row md:rounded-t-md`}>
                        <Pressable
                            style={tw`p-2 -ml-2 -mt-2`}
                            onPress={() => {setViewingAsset(false)}}
                        >
                            <Image
                                accessibilityLabel={ labels.current?.CANCEL }
                                source={{ uri: tw.prefixMatch('md') ? require('../svg/back.svg') : require('../svg/back_white.svg') }}
                                resizeMode="contain"
                                style={tw`h-6 w-6 top-px md:top-1 md:mr-4`}
                            />
                        </Pressable>
                        <Heading style={tw`mt-1.5 leading-4 mb-0 text-white md:hidden`} hlevel={1} styleHlevel={tw.prefixMatch('md') ? 2 : 4}>{ labels.current?._ASSET }</Heading>
                        <View style={tw`justify-between hidden md:flex md:flex-row grow mt-0 mb-0 items-start h-full`}>
                            <View style={tw`self-center`}>
                                <Heading style={tw`leading-4 mt-0 mb-0 text-ink`} hlevel={1} styleHlevel={2}>{viewingAssetObj.detail}</Heading>
                            </View>
                            
                            <View style={tw`items-end self-center`}>
                                <View style={tw`flex-row items-center`}>
                                    <Image
                                        accessibilityHidden={true}
                                        source={{ uri: require('../svg/date.svg') }}
                                        resizeMode="contain"
                                        style={tw`h-4 w-4 mr-2`}
                                    />
                                    <Span style={tw`text-gray6 text-sm leading-6 text-right`}>{ YYYYMMDD_to_DMJYYYY( viewingAssetObj.date ) }</Span>
                                </View>
                            </View>                       
                        </View>
                    </View>
                
                    <View style={tw`bg-blue h-8 z-0 -mb-3 md:hidden`}>
                        <View style={tw`bg-gray0 h-full w-full rounded-t-3xl`}></View>
                    </View>
        
                    <View style={[tw`bg-gray0 grow md:pr-4 md:pb-5 md:pt-8 md:pl-4 z-20 md:rounded-b-md w-full`, { height: tw.prefixMatch('md') ? 'max-content' : 'calc(100vh - 100px)'}]}>
                        <View style={tw`h-full md:h-max`}>
                            <View style={tw`w-full justify-between px-5 mb-4 flex-row md:hidden`}>
                                <View>
                                    <Span style={tw`font-bold`}>{viewingAssetObj.detail}</Span>
                                    <View style={tw`flex-row items-center`}>
                                        <Image
                                            accessibilityHidden={true}
                                            source={{ uri: require('../svg/date.svg') }}
                                            resizeMode="contain"
                                            style={tw`h-4 w-4 mr-2`}
                                        />
                                        <Span style={tw`text-gray6 text-sm leading-6`}>{ YYYYMMDD_to_DMJYYYY( viewingAssetObj.date ) }</Span>
                                    </View>
                                </View>
                            </View>
                            <HorizontalRule style={tw`mb-4 md:hidden`} />

                            { ( 0 !== photos[viewingAssetObj.id]?.length || 0 !== attachments[viewingAssetObj.id]?.length || 0 !== documents[viewingAssetObj.id]?.length ) &&
                                <PillMenu
                                    style={tw`w-auto mx-5.5 mb-3 md:mb-4 md:-mt-2`}
                                    selected={ contentType }
                                    menu={[
                                        { name:labels.current?.DETAILS, icon:{ uri: require('../svg/notes_white.svg') }, inactiveIcon:{ uri: require('../svg/notes.svg') }, onPress:()=>{ setContentType(labels.current?.DETAILS); } },
                                        ...(enabledFeatures.photos && 0 !== photos[viewingAssetObj.id]?.length ? [{ name:labels.current?.PHOTOS, icon:{ uri: require('../svg/photos_white.svg') }, inactiveIcon:{ uri: require('../svg/photos.svg') }, onPress:()=>{ setContentType(labels.current?.PHOTOS); } }] : []),
                                        ...(enabledFeatures.attachments && 0 !== attachments[viewingAssetObj.id]?.length ? [{ name: labels.current?.ATTACHMENTS, icon: { uri: require('../svg/attachment_white.svg') }, inactiveIcon: { uri: require('../svg/attachment.svg') }, onPress: () => { setContentType(labels.current?.ATTACHMENTS); } }] : []),
                                        ...(enabledFeatures.documents && 0 !== documents[viewingAssetObj.id]?.length ? [{ name: labels.current?.DOCUMENTS, icon: { uri: require('../svg/attachment_white.svg') }, inactiveIcon: { uri: require('../svg/attachment.svg') }, onPress: () => { setContentType(labels.current?.DOCUMENTS); } }] : [])
                                    ]}
                                />
                            }

                            <ScrollView
                                style={{width:'100%',overflowX:tw.prefixMatch('md') ? 'visible' : 'hidden'}}
                                contentContainerStyle={tw`w-full justify-start px-5`}
                            >
                                <View style={[tw`md:flex-row md:flex-wrap md:justify-between content-start md:min-h-[300px]`, labels?.current?.DETAILS !== contentType && tw`hidden`]}>
                                    
                                    <View style={tw`md:flex-row md:flex-wrap md:px-1 w-full max-w-full`}>
                                        <SpecsCard
                                            style={[tw`md:mr-2`, {width: tw.prefixMatch('md') ? 'calc(50% - 8px)' : '100%'}]}
                                            label={ labels.current?.TYPE }
                                            labelImage={{ uri: require('../svg/generalinfo.svg') }}
                                            value={ viewingAssetObj.type_id ? typeOptions.find(obj => {return obj.value == viewingAssetObj.type_id})?.label : '' }
                                        />
                                        <SpecsCard
                                            style={[tw`md:mr-2`, parseInt(viewingAssetObj?.mechanical_id) || ! parseInt(viewingAssetObj.equipment_id) ? tw`hidden` : {width: tw.prefixMatch('md') ? 'calc(50% - 8px)' : '100%'}]}
                                            label={ labels.current?.EQUIPMENT }
                                            labelImage={{ uri: require('../svg/equipment.svg') }}
                                            value={ viewingAssetObj.equipment_id ? allEquipmentOptions.find(obj => {return obj.value == viewingAssetObj.equipment_id})?.label : 'n/a' }
                                            onPress={() => { setViewingAsset(false); navigation.navigate('equipment', {yachtID:viewingAssetObj.vessel_id}) }}
                                        />
                                        <SpecsCard
                                            style={[tw`md:mr-2`, ! parseInt(viewingAssetObj?.mechanical_id) ? tw`hidden` : {width: tw.prefixMatch('md') ? 'calc(50% - 8px)' : '100%'}]}
                                            label={ labels.current?.ENGINE }
                                            labelImage={{ uri: require('../svg/engine.svg') }}
                                            value={ viewingAssetObj.mechanical_id ? allMechanicalOptions.find(obj => {return obj.value == viewingAssetObj.mechanical_id})?.label : '' }
                                            onPress={() => { setViewingAsset(false); navigation.navigate('engines', {yachtID:viewingAssetObj.vessel_id}) }}
                                        />
                                        <SpecsCard
                                            style={[tw`md:mr-2`, {width: tw.prefixMatch('md') ? 'calc(50% - 8px)' : '100%'}]}
                                            label={ labels.current?.LOCATION }
                                            labelImage={{ uri: require('../svg/location_blue.svg') }}
                                            value={ viewingAssetObj.mechanical_id ? allMechanicalOptions.find(obj => {return obj.value == viewingAssetObj.mechanical_id})?.description?.replace('Location: ', '') : ( viewingAssetObj.location_id ? locationOptions.find(obj => {return obj.value == viewingAssetObj.location_id})?.label : 'n/a' ) }
                                        />
                                        <SpecsCard
                                            style={[tw`md:mr-2`, ! parseInt(viewingAssetObj?.mechanical_id) ? tw`hidden` : {width: tw.prefixMatch('md') ? 'calc(50% - 8px)' : '100%'}]}
                                            label={ labels.current?.HOURS }
                                            labelImage={{ uri: require('../svg/enginehours_blue.svg') }}
                                            value={ ( viewingAssetObj.hours || 0 ) + ' ' + labels.current?.HOURS_SHORT }
                                        />
                                    </View>

                                    <View style={tw`p-1 max-w-full`}>
                                        <Heading hlevel={2} styleHlevel={tw.prefixMatch('md') ? 3 : 5} style={tw`mt-5`}>{ labels.current?.NOTES }</Heading>
                                        <Span>{ viewingAssetObj.notes }</Span>
                                    </View>

                                </View>

                                <View style={[tw`md:flex-row md:flex-wrap md:justify-between md:min-h-[300px]`, labels?.current?.PHOTOS !== contentType && tw`hidden`]}>
                                    <ScrollView
                                        style={[{width:'100%',overflowX:tw.prefixMatch('md') ? 'visible' : 'hidden'}, tw`px-4 max-w-full`, photos[updateID]?.length && tw`pl-4`]}
                                        contentContainerStyle={tw`w-full justify-start h-[${height - 290}px] md:h-[272px] mt-2`}
                                    >
                                        { photos[viewingAssetObj.id]?.length ? <View style={tw`flex-wrap flex-row justify-start items-start`}>{ photos[viewingAssetObj.id] }</View> : <View></View> }
                                    </ScrollView>
                                </View>

                                <View style={[tw`md:flex-row md:flex-wrap md:justify-between md:min-h-[300px]`, labels?.current?.ATTACHMENTS !== contentType && tw`hidden`]}>
                                    <ScrollView
                                        style={[{width:'100%',overflowX:tw.prefixMatch('md') ? 'visible' : 'hidden'}, tw`px-4 max-w-full`, attachments[updateID]?.length && tw`pl-4`]}
                                        contentContainerStyle={tw`w-full justify-start h-[${height - 290}px] md:h-[272px] mt-2`}
                                    >
                                        { attachments[viewingAssetObj.id]?.length ? <View style={tw`md:flex-wrap md:flex-row md:justify-start md:items-start`}>{ attachments[viewingAssetObj.id] }</View> : <View></View> }
                                    </ScrollView>
                                </View>

                                <View style={[tw`md:flex-row md:flex-wrap md:justify-between md:min-h-[300px]`, labels?.current?.DOCUMENTS !== contentType && tw`hidden`]}>
                                    <ScrollView
                                        style={[{width:'100%',overflowX:tw.prefixMatch('md') ? 'visible' : 'hidden'}, tw`px-4 max-w-full`, documents[updateID]?.length && tw`pl-4`]}
                                        contentContainerStyle={tw`w-full justify-start h-[${height - 290}px] md:h-[272px] mt-2`}
                                    >
                                        { documents[viewingAssetObj.id]?.length ? <View style={tw`md:flex-wrap md:flex-row md:justify-start md:items-start`}>{ documents[viewingAssetObj.id] }</View> : <View></View> }
                                    </ScrollView>
                                </View>
                            </ScrollView>
                        </View>
                    </View>
                </View>
            </AnimatedView>

            <AnimatedView style={viewPhotoAnimation}>

                <Pressable style={tw`hidden md:flex w-screen h-screen left-0 top-0 fixed`}
                    onPress={() => {setViewingPhoto(false)}}
                ></Pressable>

                <View style={tw`w-full md:w-[${viewingPhotoObj.width}px] md:max-w-[80vw] md:rounded-md md:shadow-black md:shadow-opacity-25 md:shadow-radius-8`}>

                    <View style={tw`bg-blue md:bg-gray0 md:border-b-2 md:border-gray1 md:items-center h-20 p-5 md:pl-9 pt-6.5 flex-row md:rounded-t-md`}>
                        <Pressable
                            style={tw`p-2 -ml-2 -mt-2`}
                            onPress={() => {setViewingPhoto(false)}}
                        >
                            <Image
                                accessibilityLabel={ labels.current?.CANCEL }
                                source={{ uri: tw.prefixMatch('md') ? require('../svg/back.svg') : require('../svg/back_white.svg') }}
                                resizeMode="contain"
                                style={tw`h-6 w-6 top-px md:top-1 md:mr-4`}
                            />
                        </Pressable>
                        <Heading style={tw`mt-1.5 leading-4 mb-0 text-white md:hidden`} hlevel={1} styleHlevel={tw.prefixMatch('md') ? 2 : 4}>{ labels.current?._ASSET +' '+ labels.current?.PHOTOS }</Heading>
                        <View style={tw`justify-between hidden md:flex md:flex-row grow mt-0 mb-0 items-start h-full`}>
                            <View style={tw`self-center`}>
                                <Heading style={tw`leading-4 mt-0 mb-0 text-ink`} hlevel={1} styleHlevel={4}>{ viewingPhotoObj.description }</Heading>
                            </View>

                            <View style={tw`self-center`}>
                                <View style={tw`flex-row items-center`}>
                                    <Image
                                        accessibilityHidden={true}
                                        source={{ uri: require('../svg/date.svg') }}
                                        resizeMode="contain"
                                        style={tw`h-4 w-4 mr-2`}
                                    />
                                    <Span style={tw`text-gray6 text-sm leading-6 text-right`}>{ date_DMJYYYY( viewingPhotoObj.modified_date ) }</Span>

                                    { 0 !== updateID && true === addingNew &&
                                        <Pressable
                                            style={(state) => [tw`p-2 rounded bg-gray2 ml-5`, state.hovered && tw`bg-red`]}
                                            onPress={() => {
                                                setDeleteFileModalAffectID(viewingPhotoObj.key);
                                                setDeleteFileModalActive(true);
                                            }}
                                        >
                                            {( state ) => (
                                                <Image
                                                    accessibilityLabel={labels.current?.DELETE}
                                                    source={ state.hovered ? { uri: require('../svg/delete_white.svg') } : { uri: require('../svg/delete.svg') }}
                                                    resizeMode="contain"
                                                    style={tw`h-6 w-6`}
                                                />
                                            )}
                                        </Pressable>
                                    }
                                </View>
                            </View>
                        </View>
                    </View>

                    <View style={tw`bg-blue h-8 z-0 -mb-3 md:hidden`}>
                        <View style={tw`bg-gray0 h-full w-full rounded-t-3xl`}></View>
                    </View>

                    <View style={[tw`bg-gray0 grow md:pr-0 md:pb-0 md:pt-0 md:pl-0 z-20 md:rounded-b-md w-full`, { height: tw.prefixMatch('md') ? 'max-content' : 'calc(100vh - 100px)'}]}>
                        <View style={tw`h-full md:h-max`}>
                            <View style={tw`w-full justify-between px-5 mb-4 flex-row md:hidden`}>
                                <View style={tw`w-full`}>
                                    <Span style={tw`font-bold`}>{ viewingPhotoObj.description }</Span>
                                    <View style={tw`flex-row items-center w-full justify-between`}>
                                        <View style={tw`flex-row items-center`}>
                                            <Image
                                                accessibilityHidden={true}
                                                source={{ uri: require('../svg/date.svg') }}
                                                resizeMode="contain"
                                                style={tw`h-4 w-4 mr-2`}
                                            />
                                            <Span style={tw`text-gray6 text-sm leading-6`}>{ date_DMJYYYY( viewingPhotoObj.modified_date ) }</Span>
                                        </View>

                                        { 0 !== updateID && true === addingNew &&
                                            <Pressable
                                                style={tw`p-2 rounded bg-gray2 ml-5`}
                                                onPress={() => {
                                                    setDeleteFileModalAffectID(viewingPhotoObj.key);
                                                    setDeleteFileModalActive(true);
                                                }}
                                            >
                                                <Image
                                                    accessibilityLabel={labels.current?.DELETE}
                                                    source={{ uri: require('../svg/delete.svg') }}
                                                    resizeMode="contain"
                                                    style={tw`h-6 w-6`}
                                                />
                                            </Pressable>
                                        }
                                    </View>
                                </View>
                            </View>
                            <HorizontalRule style={tw`md:hidden`} />
                            <ScrollView
                                style={{width:'100%',overflowX:tw.prefixMatch('md') ? 'visible' : 'hidden'}}
                                contentContainerStyle={tw`w-full justify-start px-0`}
                            >
                                <View style={tw`md:flex-row md:flex-wrap md:justify-between h-[${viewingPhotoObj.height}px] max-h-[${height - 142}px] md:max-h-[80vh]`}>
                                    { 'undefined' !== typeof viewingPhotoObj?.uri &&
                                        <Image
                                            source={{ uri: viewingPhotoObj.uri }}
                                            resizeMode="contain"
                                            style={tw`w-full max-w-full h-auto min-h-full md:rounded-b-md`}
                                        />
                                    }
                                </View>
                            </ScrollView>
                        </View>
                    </View>
                </View>
            </AnimatedView>

            <AnimatedView style={addNewAttachmentPaneAnimation}>

                <Pressable style={tw`hidden md:flex bg-blue/75 w-screen h-screen left-0 top-0 fixed`}
                    onPress={() => {setNewAssetMsg(''); setUpdateAttachment(false)}}
                ></Pressable>

                <View style={tw`md:w-108 md:rounded-md md:shadow-black md:shadow-opacity-25 md:shadow-radius-8`}>

                    <View style={tw`bg-blue md:bg-gray0 md:border-b-2 md:border-gray1 md:items-center h-20 p-5 md:px-9 pt-6.5 flex-row md:rounded-t-md`}>
                        <Pressable
                            style={tw`p-2 -ml-2 -mt-2`}
                            onPress={() => {setNewAssetMsg(''); setUpdateAttachment(false)}}
                        >
                            <Image
                                accessibilityLabel={ labels.current?.CANCEL }
                                source={{ uri: tw.prefixMatch('md') ? require('../svg/back.svg') : require('../svg/back_white.svg') }}
                                resizeMode="contain"
                                style={tw`h-6 w-6 top-px md:top-1 md:mr-4`}
                            />
                        </Pressable>
                        <Heading style={tw`mt-1.5 md:mt-0 leading-4 mb-0 text-white md:text-ink`} hlevel={1} styleHlevel={tw.prefixMatch('md') ? 2 : 4}>{ ( 0 !== updateAttachmentID ? labels.current?.EDIT : labels.current?.ADD ) +' '+ labels.current?.ATTACHMENT }</Heading>
                    </View>

                    <View style={tw`bg-blue h-8 z-0 -mb-3 md:hidden`}>
                        <View style={tw`bg-gray0 h-full w-full rounded-t-3xl`}></View>
                    </View>

                    <View style={[tw`bg-gray0 grow md:pr-4 md:pb-5 md:pt-8 md:pl-4 z-20 md:rounded-b-md w-full`, { height: tw.prefixMatch('md') ? 'max-content' : formHeight}]}>
                        <View style={tw`h-full md:h-max`}>
                            <ScrollView
                                style={{width:'100%',overflowX:tw.prefixMatch('md') ? 'visible' : 'hidden'}}
                                contentContainerStyle={tw`w-full justify-start px-5 pb-5 md:pb-0`}
                            >
                                { '' !== newAssetMsg &&
                                    <View style={tw`w-full md:pr-2`}>
                                        <Notification
                                            style={tw`my-2`}
                                            type={newAssetMsgType}
                                        >{newAssetMsg}</Notification>
                                    </View>
                                }

                                <View style={tw`md:flex-row md:flex-wrap md:justify-between`}>
                                    { 0 === updateAttachmentID &&
                                        <Pressable
                                            style={tw`mt-2 w-full`}
                                            onPress={()=>{newAttachmentInput.current.click();}}
                                        >
                                            <TextInput
                                                style={tw`pointer-events-none`}
                                                placeholder={ labels.current?.SELECT +' '+ labels.current?.ATTACHMENT_FILE +'*' }
                                                image={ attachmentData.current ? { uri: require('../svg/upload.svg') } : { uri: require('../svg/upload_gray.svg') }}
                                                // defaultValue={attachmentType}
                                                passedRef={attachmentFileNameInput}
                                                validateGroup={newAssetValidation}
                                                required
                                            />

                                            <Image
                                                accessibilityHidden={true}
                                                source={{ uri: require('../svg/chevron_down_blue.svg') }}
                                                resizeMode="contain"
                                                style={tw`h-3 w-3 absolute top-1/2 -translate-y-1/2 right-4`}
                                            />
                                        </Pressable>
                                    }

                                    <Pressable
                                        style={tw`mt-2 w-full`}
                                        onPress={()=>{setAttachmentTypeSelectVisible(true)}}
                                    >
                                        <TextInput
                                            style={tw`pointer-events-none`}
                                            placeholder={ labels.current?.TYPE +'*' }
                                            image={ attachmentType ? { uri: require('../svg/attachment.svg') } : { uri: require('../svg/attachment_gray.svg') }}
                                            // defaultValue={attachmentType}
                                            value={attachmentType ? attachmentTypeOptions.find(obj => {return obj.value === attachmentType})?.label : ''}
                                            validateGroup={newAssetValidation}
                                            required
                                        />

                                        <Image
                                            accessibilityHidden={true}
                                            source={{ uri: require('../svg/chevron_down_blue.svg') }}
                                            resizeMode="contain"
                                            style={tw`h-3 w-3 absolute top-1/2 -translate-y-1/2 right-4`}
                                        />
                                    </Pressable>

                                    <Span style={tw`text-sm text-gray6 my-2 w-full`}>{ '* ' + labels.current?.REQUIRED }</Span>

                                    <TextInput
                                        style={tw`mt-2 w-full`}
                                        autoCapitalize="on"
                                        placeholder={ labels.current?.DESCRIPTION }
                                        image={ attachmentDesc ? { uri: require('../svg/description.svg') } : { uri: require('../svg/description_gray.svg') }}
                                        pattern="^.{2,}$"
                                        validatedValue={(value) => {setAttachmentDesc(value)}}
                                        validateOnChange={true}
                                        validateGroup={newAssetValidation}
                                        stateValue={attachmentDesc}
                                        passedRef={newAttachmentDescInput}
                                    />
                                </View>

                            </ScrollView>

                            <HorizontalRule style={tw`md:hidden`} />

                            <Button
                                style={tw`m-5`}
                                title={0 !== updateAttachmentID ? labels.current?.UPDATE : labels.current?.ADD}
                                disabled={0 !== updateAttachmentID && attachmentType ? false : ( attachmentData.current && attachmentType ? false : true )}
                                validateGroup={newAssetValidation}
                                onPress={()=>{

                                    if ( newAssetValidation ) { return; }

                                    doNewAssetValidation(true);
                                    setNewAssetMsg('');

                                    setTimeout(()=>
                                    {
                                        // check if fields are valid

                                        if ( 0 === updateAttachmentID && ! attachmentData.current
                                            || ! attachmentType
                                        ){
                                            let errorMsg = [];

                                            if ( 0 === updateAttachmentID && ! attachmentData.current )
                                                { errorMsg.push(labels.current?.REQUEST_VALID +' '+labels.current?.ATTACHMENT_FILE); }
                                            if ( ! attachmentType )
                                                { errorMsg.push(labels.current?.REQUEST +' '+labels.current?._ASSET +' '+labels.current?.TYPE); }

                                            setNewAssetMsgType('warning');
                                            setNewAssetMsg(errorMsg.join('\n'));
                                            doNewAssetValidation(false);
                                        }
                                        else
                                        {
                                            if ( 0 === updateAttachmentID )
                                            {
                                                uploadAttachment(
                                                    attachmentData.current,
                                                    attachmentType,
                                                    attachmentDesc,
                                                    photoObjectType.current,
                                                    updateID,
                                                    yachtID,
                                                    toast,
                                                    0 === updateAttachmentID ? labels.current?.TOAST_ADD.replace('{object}',labels.current?.ATTACHMENT) : labels.current?.TOAST_UPDATE.replace('{object}',labels.current?.ATTACHMENT),
                                                    {apiUrl:apiUrl, apiVersion:apiVersion},
                                                    (status)=>{
                                                        doNewAssetValidation(false);
                                                        if ( 'success' === status )
                                                        {
                                                            setLastUpdate(now());

                                                            setUpdateAttachment(false);
                                                            setTimeout(()=>{
                                                                setUpdateAttachmentID(0);
                                                                attachmentData.current = '';
                                                                if ( attachmentFileNameInput.current?.value ) { attachmentFileNameInput.current.value = ''; }
                                                                attachmentMimeType.current = '';
                                                                setAttachmentType(0);
                                                                setAttachmentDesc('');
                                                                if ( newAttachmentDescInput.current?.value ) { newAttachmentDescInput.current.value = ''; }
                                                            }, 1500);
                                                        }
                                                    }
                                                );
                                            }
                                            else {
                                                let payload = {
                                                    object_type: photoObjectType.current,
                                                    object_id: updateID,
                                                    type_id: attachmentType,
                                                    description: attachmentDesc,
                                                    key: updateAttachmentID,
                                                };

                                                fetch(apiUrl, {
                                                    method: 'POST',
                                                    cache: 'no-cache',
                                                    headers: {
                                                        'Content-Type': 'application/json'
                                                    },
                                                    body: JSON.stringify({
                                                        endpoint: 'attachments/update', v: apiVersion,
                                                        device_id: localStorage.getItem('deviceID'),
                                                        client_key: localStorage.getItem('clientKey'),
                                                        payload: payload
                                                    }),
                                                })
                                                .then((response) => {
                                                    return response.text().then(function(text) {
                                                        const result = JSON.parse(text);
                                                        if ( result.error )
                                                        {
                                                            if ( '401' === result.error ){ setLoggedIn(false); result.error = 'Your session token has expired, please log in again.'; }
                                                            setNewAssetMsgType('warning');
                                                            setNewAssetMsg(result.error);
                                                            doNewAssetValidation(false);
                                                        }
                                                        else
                                                        {
                                                            setUpdateAttachment(false);
                                                            toast.show((0 !== updateAttachmentID ? labels.current?.TOAST_UPDATE : labels.current?.TOAST_ADD).replace('{object}',labels.current?.ATTACHMENT), {type: 'success', duration:2000});

                                                            setTimeout(()=>{
                                                                setLastUpdate(now()); // forces app to refetch all account data
                                                            }, 500);

                                                            setTimeout(()=>{
                                                                doNewAssetValidation(false);
                                                                setUpdateAttachmentID(0);
                                                                attachmentData.current = '';
                                                                if ( attachmentFileNameInput.current?.value ) { attachmentFileNameInput.current.value = ''; }
                                                                attachmentMimeType.current = '';
                                                                setAttachmentType(0);
                                                                setAttachmentDesc('');
                                                                if ( newAttachmentDescInput.current?.value ) { newAttachmentDescInput.current.value = ''; }
                                                            }, 1500);
                                                        }
                                                    });
                                                })
                                                .catch(error => {
                                                    console.warn(error);
                                                    setNewAssetMsgType('warning');
                                                    if ( ! window.navigator.onLine ) { setNewAssetMsg('Internet connection failed. Please try again.'); }
                                                    else { setNewAssetMsg('An error ocurred. See console log.'); }
                                                    doNewAssetValidation(false);
                                                });
                                            }
                                        }
                                    }, 250);


                                }}
                            />
                        </View>
                    </View>
                </View>
            </AnimatedView>

            <AnimatedView style={addNewDocumentPaneAnimation}>

                <Pressable style={tw`hidden md:flex bg-blue/75 w-screen h-screen left-0 top-0 fixed`}
                    onPress={() => {setNewAssetMsg(''); setUpdateDocument(false)}}
                ></Pressable>

                <View style={tw`md:w-108 md:rounded-md md:shadow-black md:shadow-opacity-25 md:shadow-radius-8`}>

                    <View style={tw`bg-blue md:bg-gray0 md:border-b-2 md:border-gray1 md:items-center h-20 p-5 md:px-9 pt-6.5 flex-row md:rounded-t-md`}>
                        <Pressable
                            style={tw`p-2 -ml-2 -mt-2`}
                            onPress={() => {setNewAssetMsg(''); setUpdateDocument(false)}}
                        >
                            <Image
                                accessibilityLabel={ labels.current?.CANCEL }
                                source={{ uri: tw.prefixMatch('md') ? require('../svg/back.svg') : require('../svg/back_white.svg') }}
                                resizeMode="contain"
                                style={tw`h-6 w-6 top-px md:top-1 md:mr-4`}
                            />
                        </Pressable>
                        <Heading style={tw`mt-1.5 md:mt-0 leading-4 mb-0 text-white md:text-ink`} hlevel={1} styleHlevel={tw.prefixMatch('md') ? 2 : 4}>{ ( 0 !== updateDocumentID ? labels.current?.EDIT : labels.current?.ADD ) +' '+ labels.current?.DOCUMENT }</Heading>
                    </View>

                    <View style={tw`bg-blue h-8 z-0 -mb-3 md:hidden`}>
                        <View style={tw`bg-gray0 h-full w-full rounded-t-3xl`}></View>
                    </View>

                    <View style={[tw`bg-gray0 grow md:pr-4 md:pb-5 md:pt-8 md:pl-4 z-20 md:rounded-b-md w-full`, { height: tw.prefixMatch('md') ? 'max-content' : formHeight}]}>
                        <View style={tw`h-full md:h-max`}>
                            <ScrollView
                                style={{width:'100%',overflowX:tw.prefixMatch('md') ? 'visible' : 'hidden'}}
                                contentContainerStyle={tw`w-full justify-start px-5 pb-5 md:pb-0`}
                            >
                                { '' !== newAssetMsg &&
                                    <View style={tw`w-full md:pr-2`}>
                                        <Notification
                                            style={tw`my-2`}
                                            type={newAssetMsgType}
                                        >{newAssetMsg}</Notification>
                                    </View>
                                }

                                <View style={tw`md:flex-row md:flex-wrap md:justify-between`}>

                                    <Pressable
                                        style={tw`mt-2 w-full`}
                                        onPress={()=>{setDocumentTypeSelectVisible(true)}}
                                    >
                                        <TextInput
                                            style={tw`pointer-events-none`}
                                            placeholder={ labels.current?.TYPE +'*' }
                                            image={ documentType ? { uri: require('../svg/attachment.svg') } : { uri: require('../svg/attachment_gray.svg') }}
                                            // defaultValue={documentType}
                                            value={documentType ? documentTypeOptions.find(obj => {return obj.value === documentType})?.label : ''}
                                            validateGroup={newAssetValidation}
                                            required
                                        />

                                        <Image
                                            accessibilityHidden={true}
                                            source={{ uri: require('../svg/chevron_down_blue.svg') }}
                                            resizeMode="contain"
                                            style={tw`h-3 w-3 absolute top-1/2 -translate-y-1/2 right-4`}
                                        />
                                    </Pressable>

                                    <TextInput
                                        style={tw`mt-2 w-full`}
                                        placeholder={ labels.current?.NAME +'*' }
                                        image={ documentNameInput.current?.value ? { uri: require('../svg/name.svg') } : { uri: require('../svg/name_gray.svg') }}
                                        validatedValue={(value) => {setDocumentName(value)}}
                                        validateOnChange={true}
                                        validateGroup={newAssetValidation}
                                        stateValue={documentName}
                                        passedRef={documentNameInput}
                                        required
                                    />

                                    <Span style={tw`text-sm text-gray6 my-2 w-full`}>{ '* ' + labels.current?.REQUIRED }</Span>

                                    <TextInput
                                        style={tw`mt-2 w-full`}
                                        autoCapitalize="on"
                                        placeholder={ labels.current?.DETAIL }
                                        image={ documentDescInput.current?.value ? { uri: require('../svg/description.svg') } : { uri: require('../svg/description_gray.svg') }}
                                        pattern="^.{2,}$"
                                        validatedValue={(value) => {setDocumentDesc(value)}}
                                        validateOnChange={true}
                                        validateGroup={newAssetValidation}
                                        stateValue={documentDesc}
                                        passedRef={documentDescInput}
                                    />

                                    { 0 === updateDocumentID &&
                                        <Pressable
                                            style={tw`mt-2 w-full`}
                                            onPress={()=>{documentInput.current.click();}}
                                        >
                                            <TextInput
                                                style={tw`pointer-events-none`}
                                                placeholder={ labels.current?.SELECT +' '+ labels.current?.DOCUMENT_FILE }
                                                image={ documentData ? { uri: require('../svg/upload.svg') } : { uri: require('../svg/upload_gray.svg') }}
                                                // defaultValue={documentType}
                                                passedRef={documentFileNameInput}
                                            />

                                            <Image
                                                accessibilityHidden={true}
                                                source={{ uri: require('../svg/chevron_down_blue.svg') }}
                                                resizeMode="contain"
                                                style={tw`h-3 w-3 absolute top-1/2 -translate-y-1/2 right-4`}
                                            />
                                        </Pressable>
                                    }

                                    <TextInput
                                        style={tw`mt-2 w-full`}
                                        keyboardType="numeric"
                                        mask="99/99/9999"
                                        autoCapitalize="off"
                                        placeholder={labels.current?.DATE +" "+ labels.current?.EXPIRES +" MM/DD/YYYY"}
                                        image={ documentExpiration ? { uri: require('../svg/calendar_blue.svg') } : { uri: require('../svg/calendar_gray.svg') }}
                                        pattern="^(0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])[- /.](19|20)[0-9]{2}$"
                                        allowPastDates={ true }
                                        validateGroup={newAssetValidation}
                                        validatedValue={(value) => {setDocumentExpiration(value)}}
                                        validateOnChange={true}
                                        stateValue={documentExpiration}
                                        passedRef={documentExpirationInput}
                                        value={documentExpirationMasked}
                                        onChangeText={(value)=>{setDocumentExpirationMasked(value)}}
                                        required
                                    />

                                </View>

                            </ScrollView>

                            <HorizontalRule style={tw`md:hidden`} />

                            <Button
                                style={tw`m-5`}
                                title={0 !== updateDocumentID ? labels.current?.UPDATE : labels.current?.ADD}
                                disabled={documentName && documentType ? false : true}
                                validateGroup={newAssetValidation}
                                onPress={()=>{

                                    if ( newAssetValidation ) { return; }

                                    doNewAssetValidation(true);
                                    setNewAssetMsg('');

                                    setTimeout(()=>
                                    {
                                        // check if fields are valid

                                        if ( ! documentType
                                            || ! documentName
                                        ){
                                            let errorMsg = [];

                                            if ( ! documentType )
                                                { errorMsg.push(labels.current?.REQUEST +' '+labels.current?.DOCUMENT +' '+labels.current?.TYPE); }

                                            if ( ! documentName )
                                                { errorMsg.push(labels.current?.REQUEST +' '+labels.current?.DOCUMENT +' '+labels.current?.NAME); }

                                            setNewAssetMsgType('warning');
                                            setNewAssetMsg(errorMsg.join('\n'));
                                            doNewAssetValidation(false);
                                        }
                                        else
                                        {
                                            if ( 0 === updateDocumentID )
                                            {
                                                uploadDocument(
                                                    documentData,
                                                    documentSize.current,
                                                    documentType,
                                                    documentName,
                                                    documentDesc || '',
                                                    MMDDYYYY_to_YYYYMMDD(documentExpirationMasked),
                                                    photoObjectType.current,
                                                    updateID,
                                                    yachtID,
                                                    toast,
                                                    0 === updateDocumentID ? labels.current?.TOAST_ADD.replace('{object}',labels.current?.DOCUMENT) : labels.current?.TOAST_UPDATE.replace('{object}',labels.current?.DOCUMENT),
                                                    {apiUrl:apiUrl, apiVersion:apiVersion, setLoggedIn:setLoggedIn},
                                                    (status)=>{
                                                        doNewAssetValidation(false);
                                                        if ( 'success' === status )
                                                        {
                                                            setLastUpdate(now());

                                                            setUpdateDocument(false);
                                                            setTimeout(()=>{
                                                                setUpdateDocumentID(0);
                                                                setDocumentData('');
                                                                if ( documentFileNameInput.current?.value ) { documentFileNameInput.current.value = ''; }
                                                                setDocumentType(0);
                                                                documentMimeType.current = '';
                                                                setDocumentName('');
                                                                if ( documentNameInput.current?.value ) { documentNameInput.current.value = ''; }
                                                                setDocumentDesc('');
                                                                if ( documentDescInput.current?.value ) { documentDescInput.current.value = ''; }
                                                                setDocumentExpiration('');
                                                                setDocumentExpirationMasked('');
                                                                if ( documentExpirationInput.current?.value ) { documentExpirationInput.current.value = ''; }
                                                            }, 1500);
                                                        }
                                                    }
                                                );
                                            }
                                            else {
                                                let payload = {
                                                    id: updateDocumentID,
                                                    name: documentName,
                                                    type_id: documentType,
                                                    details: documentDesc,
                                                    expiration_date: MMDDYYYY_to_YYYYMMDD(documentExpirationMasked)
                                                };

                                                fetch(apiUrl, {
                                                    method: 'POST',
                                                    cache: 'no-cache',
                                                    headers: {
                                                        'Content-Type': 'application/json'
                                                    },
                                                    body: JSON.stringify({
                                                        endpoint: 'documents/update', v: apiVersion,
                                                        device_id: localStorage.getItem('deviceID'),
                                                        client_key: localStorage.getItem('clientKey'),
                                                        payload: payload
                                                    }),
                                                })
                                                .then((response) => {
                                                    return response.text().then(function(text) {
                                                        const result = JSON.parse(text);
                                                        if ( result.error )
                                                        {
                                                            if ( '401' === result.error ){ setLoggedIn(false); result.error = 'Your session token has expired, please log in again.'; }
                                                            setNewAssetMsgType('warning');
                                                            setNewAssetMsg(result.error);
                                                            doNewAssetValidation(false);
                                                        }
                                                        else
                                                        {
                                                            setUpdateDocument(false);
                                                            toast.show((0 !== updateDocumentID ? labels.current?.TOAST_UPDATE : labels.current?.TOAST_ADD).replace('{object}',labels.current?.DOCUMENT), {type: 'success', duration:2000});

                                                            setTimeout(()=>{
                                                                setLastUpdate(now()); // forces app to refetch all account data
                                                            }, 500);

                                                            setTimeout(()=>{
                                                                doNewAssetValidation(false);
                                                                setUpdateDocumentID(0);
                                                                setDocumentData('');
                                                                if ( documentFileNameInput.current?.value ) { documentFileNameInput.current.value = ''; }
                                                                setDocumentType(0);
                                                                documentMimeType.current = '';
                                                                setDocumentName('');
                                                                if ( documentNameInput.current?.value ) { documentNameInput.current.value = ''; }
                                                                setDocumentDesc('');
                                                                if ( documentDescInput.current?.value ) { documentDescInput.current.value = ''; }
                                                                setDocumentExpiration('');
                                                                setDocumentExpirationMasked('');
                                                                if ( documentExpirationInput.current?.value ) { documentExpirationInput.current.value = ''; }
                                                            }, 1500);
                                                        }
                                                    });
                                                })
                                                .catch(error => {
                                                    console.warn(error);
                                                    setNewAssetMsgType('warning');
                                                    if ( ! window.navigator.onLine ) { setNewAssetMsg('Internet connection failed. Please try again.'); }
                                                    else { setNewAssetMsg('An error ocurred. See console log.'); }
                                                    doNewAssetValidation(false);
                                                });
                                            }
                                        }
                                    }, 250);


                                }}
                            />
                        </View>
                    </View>
                </View>
            </AnimatedView>

            <AnimatedView style={viewDocumentAnimation}>

                <Pressable style={tw`hidden md:flex bg-blue/75 w-screen h-screen left-0 top-0 fixed`}
                    onPress={() => {setViewingDocument(false)}}
                ></Pressable>

                <View style={tw`md:w-172 md:rounded-md md:shadow-black md:shadow-opacity-25 md:shadow-radius-8`}>

                    <View style={tw`bg-blue md:bg-gray0 md:border-b-2 md:border-gray1 md:items-center h-20 p-5 md:px-9 pt-6.5 flex-row md:rounded-t-md`}>
                        <Pressable
                            style={tw`p-2 -ml-2 -mt-2`}
                            onPress={() => {setViewingDocument(false)}}
                        >
                            <Image
                                accessibilityLabel={ labels.current?.CANCEL }
                                source={{ uri: tw.prefixMatch('md') ? require('../svg/back.svg') : require('../svg/back_white.svg') }}
                                resizeMode="contain"
                                style={tw`h-6 w-6 top-px md:top-1 md:mr-4`}
                            />
                        </Pressable>
                        <Heading style={tw`mt-1.5 leading-4 mb-0 text-white md:hidden`} hlevel={1} styleHlevel={tw.prefixMatch('md') ? 2 : 4}>{ labels.current?.DOCUMENT }</Heading>
                        <View style={tw`justify-between hidden md:flex md:flex-row grow mt-0 mb-0 items-start h-full`}>
                            <View style={tw`self-center`}>
                                <Heading style={tw`leading-4 mt-0 mb-0 text-ink`} hlevel={1} styleHlevel={2}>{ viewingDocumentObj.type_id ? documentTypeOptions.find(obj => {return obj.value == viewingDocumentObj.type_id})?.label : '' }</Heading>
                            </View>

                            <View style={tw`self-center`}>
                                <Tag style={tw`self-end mb-0`} color={viewingDocumentObj.expiration_color}>{ viewingDocumentObj.expiration_tag }</Tag>

                                { null !== viewingDocumentObj.expiration_date &&
                                    <View style={tw`flex-row items-center`}>
                                        <Image
                                            accessibilityHidden={true}
                                            source={{ uri: require('../svg/date.svg') }}
                                            resizeMode="contain"
                                            style={tw`h-4 w-4 mr-2`}
                                        />
                                        <Span style={tw`text-gray6 text-sm leading-6 text-right`}>{ ( is_date_in_past(viewingDocumentObj.expiration_tag) ? labels?.current?.EXPIRED : labels?.current?.EXPIRES ) + ' ' + YYYYMMDD_to_DMJYYYY( viewingDocumentObj.expiration_date ) }</Span>
                                    </View>
                                }
                            </View>
                        </View>
                    </View>

                    <View style={tw`bg-blue h-8 z-0 -mb-3 md:hidden`}>
                        <View style={tw`bg-gray0 h-full w-full rounded-t-3xl`}></View>
                    </View>

                    <View style={[tw`bg-gray0 grow md:pr-4 md:pb-5 md:pt-8 md:pl-4 z-20 md:rounded-b-md w-full`, { height: tw.prefixMatch('md') ? 'max-content' : 'calc(100vh - 100px)'}]}>
                        <View style={tw`h-full md:h-max`}>
                            <View style={tw`w-full justify-between px-5 mb-4 flex-row md:hidden`}>
                                <View style={tw`flex-col`}>
                                    <Span style={tw`font-bold`}>{ viewingDocumentObj.type_id ? documentTypeOptions.find(obj => {return obj.value == viewingDocumentObj.type_id})?.label : '' }</Span>

                                    { null !== viewingDocumentObj.expiration_date &&
                                        <View style={tw`flex-row items-center`}>
                                            <Image
                                                accessibilityHidden={true}
                                                source={{ uri: require('../svg/date.svg') }}
                                                resizeMode="contain"
                                                style={tw`h-4 w-4 mr-2`}
                                            />
                                            <Span style={tw`text-gray6 text-sm leading-6 text-right`}>{ ( is_date_in_past(viewingDocumentObj.expiration_tag) ? labels?.current?.EXPIRED : labels?.current?.EXPIRES ) + ' ' + YYYYMMDD_to_DMJYYYY( viewingDocumentObj.expiration_date ) }</Span>
                                        </View>
                                    }
                                </View>

                                <View style={tw`self-center`}>
                                    <Tag style={tw`self-end mb-0`} color={viewingDocumentObj.expiration_color}>{ viewingDocumentObj.expiration_tag }</Tag>
                                </View>
                            </View>
                            <HorizontalRule style={tw`mb-4 md:hidden`} />

                            <ScrollView
                                style={{width:'100%',overflowX:tw.prefixMatch('md') ? 'visible' : 'hidden'}}
                                contentContainerStyle={tw`w-full justify-start px-5`}
                            >
                                <View style={tw`md:flex-row md:flex-wrap md:justify-between content-start md:min-h-[300px]`}>
                                    <View style={tw`md:flex-row md:flex-wrap md:px-1 w-full max-w-full`}>
                                        <SpecsCard
                                            style={[tw`md:mr-2`, {width: tw.prefixMatch('md') ? 'calc(50% - 8px)' : '100%'}]}
                                            label={ labels.current?.NAME }
                                            labelImage={{ uri: require('../svg/name.svg') }}
                                            value={viewingDocumentObj.name}
                                        />
                                        <SpecsCard
                                            style={[tw`md:mr-2`, {width: tw.prefixMatch('md') ? 'calc(50% - 8px)' : '100%'}]}
                                            label={ labels.current?.TYPE }
                                            labelImage={{ uri: require('../svg/attachment.svg') }}
                                            value={ viewingDocumentObj.type_id ? documentTypeOptions.find(obj => {return obj.value == viewingDocumentObj.type_id})?.label : '' }
                                        />
                                        <SpecsCard
                                            style={[tw`md:mr-2`, {width: tw.prefixMatch('md') ? 'calc(50% - 8px)' : '100%'}]}
                                            label={ labels.current?.DATE +" "+ labels.current?.EXPIRES }
                                            labelImage={{ uri: require('../svg/calendar_blue.svg') }}
                                            value={ viewingDocumentObj.expiration_date ? YYYYMMDD_to_MMDDYYYY(viewingDocumentObj.expiration_date) : 'N/A' }
                                        />
                                        { '' !== viewingDocumentObj.uri && null !== viewingDocumentObj.uri &&
                                            <SpecsCard
                                                style={[tw`md:mr-2`, {width: tw.prefixMatch('md') ? 'calc(50% - 8px)' : '100%'}]}
                                                label={ labels.current?.VIEW +' '+ labels.current?.DOCUMENT_FILE }
                                                labelImage={{ uri: require('../svg/url.svg') }}
                                                onPress={() => {  window.open(viewingDocumentObj.uri, '_blank'); }}
                                            />
                                        }
                                    </View>

                                    <View style={tw`w-full p-1 max-w-full`}>
                                        <Heading hlevel={2} styleHlevel={tw.prefixMatch('md') ? 3 : 5} style={tw`mt-5`}>{ labels.current?.DETAILS }</Heading>
                                        <Span>{ viewingDocumentObj.details }</Span>
                                    </View>
                                </View>

                            </ScrollView>
                        </View>
                    </View>
                </View>
            </AnimatedView>

            <SelectMenu
                badge={false}
                title={ labels.current?.SELECT +' '+ labels.current?.TYPE }
                optionSet="radio"
                buttonTitle={ labels.current?.SELECT }
                active={documentTypeSelectVisible}
                setActive={setDocumentTypeSelectVisible}
                selectedValue={documentType}
                options={documentTypeOptions}
                onValueChange={(value) => {setDocumentType(value)}}
                scrollable={documentTypeOptions.length > 8}
                searchable={documentTypeOptions.length > 8}
            />

            <SelectMenu
                badge={false}
                title={ labels.current?.SELECT +' '+ labels.current?.TYPE }
                optionSet="radio"
                buttonTitle={ labels.current?.SELECT }
                active={attachmentTypeSelectVisible}
                setActive={setAttachmentTypeSelectVisible}
                selectedValue={attachmentType}
                options={attachmentTypeOptions}
                onValueChange={(value) => {setAttachmentType(value)}}
                scrollable={attachmentTypeOptions.length > 8}
                searchable={attachmentTypeOptions.length > 8}
            />
                    
            <SelectMenu
                badge={false}
                title={ labels.current?.VIEW }
                optionSet="radio"
                buttonTitle={ labels.current?.SORT }
                active={filterVisible}
                setActive={setFilterVisible}
                selectedValue={getFilter}
                options={typeFilterOptions}
                onValueChange={(value) => {setFilter(value)}}
                scrollable={typeFilterOptions.length > 8}
                searchable={typeFilterOptions.length > 8}
            />

            <SelectMenu
                badge={false}
                title={ labels.current?.SELECT +' '+ labels.current?.TYPE }
                optionSet="radio"
                buttonTitle={ labels.current?.SELECT }
                active={typeSelectVisible}
                setActive={setTypeSelectVisible}
                selectedValue={newType}
                options={typeOptions}
                onValueChange={(value) => {setNewType(value)}}
                scrollable={typeOptions.length > 8}
                searchable={typeOptions.length > 8}
            />

            <SelectMenu
                badge={false}
                title={ labels.current?.SELECT +' '+ labels.current?.LOCATION }
                optionSet="radio"
                buttonTitle={ labels.current?.SELECT }
                active={locationSelectVisible}
                setActive={setLocationSelectVisible}
                selectedValue={newLocation}
                options={locationOptions}
                onValueChange={(value) => {setNewLocation(value); setNewMechanicalID(0);}}
                scrollable={locationOptions.length > 8}
                searchable={locationOptions.length > 8}
            />

            <SelectMenu
                badge={false}
                title={ labels.current?.SELECT +' '+ labels.current?.ENGINE }
                optionSet="radio"
                buttonTitle={ labels.current?.SELECT }
                active={mechanicalSelectVisible}
                setActive={setMechanicalSelectVisible}
                selectedValue={newMechanicalID}
                options={mechanicalOptions}
                onValueChange={(value) => {setNewMechanicalID(value); setNewLocation(0); setNewEquipmentID(0);}}
                scrollable={mechanicalOptions.length > 8}
                searchable={mechanicalOptions.length > 8}
            />

            <SelectMenu
                badge={false}
                title={ labels.current?.SELECT +' '+ labels.current?.EQUIPMENT }
                optionSet="radio"
                buttonTitle={ labels.current?.SELECT }
                active={equipmentSelectVisible}
                setActive={setEquipmentSelectVisible}
                selectedValue={newEquipmentID}
                options={allEquipmentOptions}
                onValueChange={(value) => {setNewEquipmentID(value); setNewLocation(getLocationFromAssetID(value, yachtID, 'equipment', 'types.inventory.locations')); setNewMechanicalID(0);}}
                scrollable={allEquipmentOptions.length > 8}
                searchable={allEquipmentOptions.length > 8}
            />

            <Modal
                active={modalActive}
                setActive={setModalActive}
            >
                <Heading hlevel={2} styleHlevel={3}>{ labels.current?.DELETE_CONFIRM_TITLE }</Heading>
                <Span>{ iDsContainingFiles.current.includes(modalAffectID) ? labels.current?.DELETE_CONFIRM2_BODY : labels.current?.DELETE_CONFIRM_BODY }</Span>
                <Span style={tw`text-red mt-2`}>{ labels.current?.PERMANENT_ACTION }</Span>
                <View style={tw`flex-row mt-5`}>
                    <Button
                        title={ labels.current?.CANCEL }
                        style={tw`w-full shrink py-2 bg-white border border-blue`}
                        styleHover={tw`bg-white`}
                        styleText={tw`text-blue`}
                        styleHoverText={tw`text-blue`}
                        onPress={()=>{setModalActive(false)}}
                    />
                    <Button
                        title={ labels.current?.DELETE }
                        image={{ uri: require('../svg/delete_white.svg') }}
                        style={tw`ml-2 w-full shrink py-2 bg-red border border-red`}
                        styleHover={tw`bg-red`}
                        styleText={tw`text-white`}
                        styleHoverText={tw`text-white`}
                        onPress={()=>{
                            setPrimaryMsg('');

                            fetch(apiUrl, {
                                method: 'POST',
                                cache: 'no-cache',
                                headers: {
                                    'Content-Type': 'application/json'
                                },
                                body: JSON.stringify({
                                    endpoint: 'logs/maintenance/delete', v: apiVersion,
                                    client_key: localStorage.getItem('clientKey'),
                                    device_id: localStorage.getItem('deviceID'),
                                    payload: {
                                       id: modalAffectID,
                                    }
                                }),
                            })
                            .then((response) => {
                                return response.text().then(function(text) {
                                    const result = JSON.parse(text);
                                    if ( result.error )
                                    {
                                        if ( '401' === result.error ){ setLoggedIn(false); result.error = 'Your session token has expired, please log in again.'; }
                                        setPrimaryMsgType('warning');
                                        setPrimaryMsg(result.error);
                                    }
                                    else
                                    {
                                        setModalAffectID(0);
                                        setModalActive(false);
                                        toast.show((labels.current?.TOAST_DELETE).replace('{object}',labels.current?._ASSET), {type: 'success', duration:2000});
                                        
                                        setTimeout(()=>{
                                            setLastUpdate(now()); // forces app to refetch all account data
                                        }, 250);
                                    }
                                });
                            })
                            .catch(error => {
                                console.warn(error);
                                setPrimaryMsgType('warning');
                                if ( ! window.navigator.onLine ) { setPrimaryMsg('Internet connection failed. Please try again.'); }
                                else { setPrimaryMsg('An error ocurred. See console log.'); }
                            });
                        }}
                    />
                </View>
            </Modal>

            <Modal
                active={deleteFileModalActive}
                setActive={setDeleteFileModalActive}
                doWhileExit={()=>{ attachmentMimeType.current = ''; documentMimeType.current = ''; }}
            >
                <Heading hlevel={2} styleHlevel={3}>{ labels.current?.DELETE_CONFIRM_TITLE }</Heading>
                <Span>{ labels.current?.DELETE_CONFIRM_BODY }</Span>
                <Span style={tw`text-red mt-2`}>{ labels.current?.PERMANENT_ACTION }</Span>
                <View style={tw`flex-row mt-5`}>
                    <Button
                        title={ labels.current?.CANCEL }
                        style={tw`w-full shrink py-2 bg-white border border-blue`}
                        styleHover={tw`bg-white`}
                        styleText={tw`text-blue`}
                        styleHoverText={tw`text-blue`}
                        onPress={()=>{setDeleteFileModalActive(false)}}
                    />
                    <Button
                        title={ labels.current?.DELETE }
                        image={{ uri: require('../svg/delete_white.svg') }}
                        style={tw`ml-2 w-full shrink py-2 bg-red border border-red`}
                        styleHover={tw`bg-red`}
                        styleText={tw`text-white`}
                        styleHoverText={tw`text-white`}
                        onPress={()=>{
                            setPrimaryMsg('');
                            let payload = {
                                object_type: photoObjectType.current,
                                object_id: updateID,
                                vessel_id: yachtID,
                                key: deleteFileModalAffectID,
                            };
                            if ( '' !== attachmentMimeType.current )
                            {
                                payload.mime_type = attachmentMimeType.current;
                            }
                            if ( '' !== documentMimeType.current )
                            {
                                payload.id = payload.key;
                                delete payload.key;
                            }

                            fetch(apiUrl, {
                                method: 'POST',
                                cache: 'no-cache',
                                headers: {
                                    'Content-Type': 'application/json'
                                },
                                body: JSON.stringify({
                                    endpoint: ('' !== attachmentMimeType.current ? 'attachments/delete' : ( '' !== documentMimeType.current ? 'documents/delete' : 'images/delete')), v: apiVersion,
                                    client_key: localStorage.getItem('clientKey'),
                                    device_id: localStorage.getItem('deviceID'),
                                    payload: payload
                                }),
                            })
                            .then((response) => {
                                return response.text().then(function(text) {
                                    const result = JSON.parse(text);
                                    if ( result.error )
                                    {
                                        if ( '401' === result.error ){ setLoggedIn(false); result.error = 'Your session token has expired, please log in again.'; }
                                        toast.show(result.error, {type: 'danger', duration:10000});
                                    }
                                    else
                                    {
                                        setDeleteFileModalAffectID('');
                                        setDeleteFileModalActive(false);
                                        setViewingPhoto(false);
                                        toast.show((labels.current?.TOAST_DELETE).replace('{object}',labels._ASSET+' '+( '' !== attachmentMimeType.current ? labels.ATTACHMENT : ('' !== documentMimeType.current ? labels.DOCUMENT : labels.PHOTO))), {type: 'success', duration:2000});
                                        attachmentMimeType.current = '';
                                        documentMimeType.current = '';

                                        setTimeout(()=>{
                                            setLastUpdate(now()); // forces app to refetch all account data
                                        }, 250);
                                    }
                                });
                            })
                            .catch(error => {
                                console.warn(error);
                                if ( ! window.navigator.onLine ) { toast.show('Internet connection failed. Please try again.', {type: 'danger', duration:10000}); }
                                else { toast.show('An error ocurred. See console log.', {type: 'danger', duration:10000}); }
                            });
                        }}
                    />
                </View>
            </Modal>
        </View>
    )
}

export default YachtLogsView;
