import React from 'react';
import { Image, View, ScrollView, Pressable } from 'react-native';
import { create, useDeviceContext } from 'twrnc';
import { Button, Span, Heading, PinInput, Notification } from '../elements';
import { useSpring, animated } from 'react-spring';
import { mask } from "react-native-mask-text";
import { obscurePhoneNumber, getLabel } from '../helpers';
import { GlobalContext } from '../global';


function VerifyAccountView ({route, navigation})
{
    const tw = create( require(`../../tailwind.config.js`) );
    useDeviceContext(tw);
    
    const AnimatedView = animated(View);
    
    let {
        version,
        apiUrl,
        apiVersion,
        recaptchaKey,
        userLangID,
        loggedIn, setLoggedIn,
        setShownIntro,
        userFirstName, setUserFirstName,
        userLastName, setUserLastName,
        userEmail,
        userPhone, setUserPhone,
        userPhonePrefix, setUserPhonePrefix,
    } = React.useContext(GlobalContext);

    React.useEffect(()=>
    {
        if ( loggedIn ) {
            setTimeout(()=>
            {
                // console.log('loginCode is navigating to yachts');
                let attempted_view = false; // localStorage.getItem('attempted-view');
                let view = attempted_view || 'yachts';
                localStorage.removeItem('attempted-view');

                navigation.replace(view);
            },1000);
        }
    },[loggedIn]);

    let { sid_mobile /*, sid_email*/ } = route.params;    

    if ( ! userFirstName
        || ! userLastName
        || ! userEmail
        || ! userPhone
        || ! userPhonePrefix )
    {
        navigation.navigate('welcome');
    }

    const [doingTransition, setDoingTransition] = React.useState(false);
    const [verifyingEmail /*, setVerifyingEmail*/] = React.useState(false);
    const [verifyingPhone, setVerifyingPhone] = React.useState(true);
    const [verified] = React.useState(false);
    
    const [maskedPhoneNum, setMaskedPhoneNum] = React.useState('');
    
    const [userEmailCode, setUserEmailCode] = React.useState('');
    const [emailValidation, doEmailValidation] = React.useState(false);
    const [invalidEmail, invalidateEmail] = React.useState(false);
    
    const [userPhoneCode, setUserPhoneCode] = React.useState('');
    const [phoneValidation, doPhoneValidation] = React.useState(false);
    const [invalidPhone, invalidatePhone] = React.useState(false);
    
    const [invalidMsg, setInvalidMsg] = React.useState('Please review your codes');
    
    const [codeResent, setCodeResent] = React.useState(false);
    const [codeResentStatus, setCodeResentStatus] = React.useState('success');
    const [codeResentMsg, setCodeResentMsg] = React.useState('Codes were resent to the email and mobile phone number you provided. If you still do not receive it, click the Back button and double-check the email you provided.');

    React.useEffect(() =>
    {
        if ( maskedPhoneNum ) { return false; }
        let phoneNum = obscurePhoneNumber( userPhone );
        setMaskedPhoneNum(userPhonePrefix +' '+ phoneNum);
    },[maskedPhoneNum, userPhone, userPhonePrefix]);

    let doingTransitionAnimationMain = useSpring(
    {
        from: { 
            willChange: 'opacity',
            zIndex: 3,
            pointerEvents: 'none',
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            opacity: 0,
        },
        to: {  
            zIndex: 3,
            pointerEvents: ! doingTransition 
                ? 'none'
                : 'auto',
            opacity: ! doingTransition 
                ? 0
                : 1
        },
        config: { mass: 1, tension: 190, friction: 30, clamp: true }
    });
    let emailVerifyAnimationMain = useSpring(
    {
        from: { 
            willChange: 'opacity',
            zIndex: 1,
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            opacity: 0,
        },
        to: {  
            zIndex: 2,
            opacity: ! verifyingEmail
                    ? 0
                    : 1
        },
        config: { mass: 1, tension: 190, friction: 30, clamp: true }
    });
    let phoneVerifyAnimationMain = useSpring(
    {
        from: { 
            willChange: 'opacity',
            zIndex: 1,
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            opacity: 0,
        },
        to: {  
            zIndex: 2,
            opacity: ! verifyingPhone
                    ? 0
                    : 1
        },
        config: { mass: 1, tension: 190, friction: 30, clamp: true }
    });
    let verifiedAnimationMain = useSpring(
    {
        from: { 
            willChange: 'opacity',
            zIndex: 1,
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            opacity: 0,
        },
        to: {  
            zIndex: 2,
            opacity: ! verified
                    ? 0
                    : 1
        },
        config: { mass: 1, tension: 190, friction: 30, clamp: true }
    });
    let progressBarRevealAnimation = useSpring(
    {
        from: { 
            willChange: 'opacity',
            opacity: 0,
            pointerEvents: 'none',
            width: tw.prefixMatch('md') ? '320px' : 'calc(100% - 24px)',
            top: tw.prefixMatch('md') ? '18px' : '10px',
            margin: '0 auto',
            flexShrink: 1,
        },
        to: {
            opacity: ! verifyingEmail && ! verifyingPhone && ! verified
                    ? 0
                    : 1,
            pointerEvents: ! verifyingEmail && ! verifyingPhone && ! verified
                    ? 'none'
                    : 'auto',
        },
        config: { mass: 1, tension: 190, friction: 30, clamp: true }
    });
    let progressBarAnimation = useSpring(
    {
        from: { 
            willChange: 'width',
            width: '0%',
            backgroundColor: tw.color('white'),
            height: '4px',
            borderRadius: '4px',
        },
        to: {  
            width: verified ? '100%' : ( userPhoneCode.length > 0 ? '75%' : ( verifyingPhone ? '50%' : ( userEmailCode.length > 0 ? '25%' : '0%' ) ) )
        },
        config: { mass: 1, tension: 190, friction: 30, clamp: true }
    });

    return (
        <View label="verifyAccount" style={tw`bg-blue min-h-screen`}>
            <View style={tw`flex-row p-5 md:p-8`}>
                { ! verified &&
                    <Pressable
                        style={tw`-mr-6`}
                        onPress={() => {
                            /* if ( ! verifyingEmail && ! verifyingPhone ) */
                            if ( ! verifyingPhone )
                            { 
                                navigation.navigate('welcome')
                            }
                            // else if ( verifyingEmail )
                            // { 
                            //     setUserEmailCode(''); 
                            //     setVerifyingEmail(false);
                            // }
                            else if ( verifyingPhone )
                            { 
                                setUserPhoneCode(''); 
                                setVerifyingPhone(false); 
                                // setUserEmailCode(''); 
                                // setVerifyingEmail(true); 
                            }
                        }}
                    >
                        <Image
                            accessibilityLabel={ getLabel('LOGIN_RETURN_LINK') + getLabel('LOGIN_RETURN_TEXT') }
                            source={{ uri: require('../svg/back_white.svg') }}
                            resizeMode="contain"
                            style={tw`h-6 w-6 pointer-events-none`}
                        />
                    </Pressable>
                }
                
                <AnimatedView style={progressBarRevealAnimation}>
                    <View style={tw`rounded w-[136px] h-1 bg-[#0360a3] mx-auto`}>
                        <AnimatedView style={progressBarAnimation}></AnimatedView>
                        <View style={tw`bg-blue w-2 h-2 left-1/2 -translate-x-1/2 -top-2`}></View>
                    </View>
                </AnimatedView>
            </View>
            <ScrollView
                centerContent={true}
                style={{width:'100%'}}
                contentContainerStyle={tw`w-full md:items-center relative`}
            >
                { /* ! verifyingEmail && ! verifyingPhone && ! verified && */
                    ! verifyingPhone && ! verified &&
                    <View style={tw`bg-blue h-full w-full p-5 md:p-8 items-center`}>
                        <View style={tw`h-full w-full md:w-80 justify-start md:justify-center`}>
                            <Image
                                accessibilityHidden={true}
                                source={{ uri: require('../svg/padlock.svg') }}
                                resizeMode="contain"
                                style={tw`h-16 w-16`}
                            />
                            <Heading style={tw`text-white my-9 text-2xl`} hlevel={1} >
                                { getLabel('VERIFY_LOGIN_TITLE') }
                            </Heading>
                            <View style={tw`flex-row`}>
                                <Span style={tw`text-white rounded-full bg-[#0360a3] text-center w-8 h-8 leading-8`}>1</Span>
                                <Span style={tw`text-white ml-4 leading-8`}>{ getLabel('VERIFY_MOBILE_TITLE') }</Span>
                            </View>
                        </View>
                    </View>
                }
                { /* verifyingEmail && 
                    <AnimatedView style={emailVerifyAnimationMain}>
                        <View style={tw`bg-blue h-full w-full p-5 md:p-8 items-center`}>
                            <View style={tw`h-full w-full md:w-80 justify-start md:justify-center`}>
                                { codeResent && 
                                    <Notification 
                                        style={tw`my-4 md:w-80`}
                                        type={codeResentStatus}
                                    >{codeResentMsg}</Notification>
                                }
                                { invalidEmail && 
                                    <Notification 
                                        style={tw`mt-0 mb-6 md:w-80`}
                                        type="warning"
                                    >{invalidMsg}</Notification>
                                }
                                <Heading style={tw`text-white mt-0 md:mt-4 mb-7`} hlevel={2} >
                                    { getLabel('VERIFY_EMAIL_TITLE') }
                                </Heading>
                                <Span style={tw`text-white mb-7`}>{ getLabel('VERIFY_EMAIL_TEXT') }</Span>
                                <PinInput
                                    style={tw`w-full md:w-80`}
                                    styleInput={tw`bg-[#0360a3] text-white my-4 w-full border-transparent`}
                                    styleFocus={tw`border-white`}
                                    keyboardType="numeric"
                                    pattern="^\d{4}$"
                                    validateOnChange={true}
                                    validateGroup={emailValidation}
                                    validatedValue={(value) => {setUserEmailCode(value)}}
                                    required
                                />
                                <Pressable
                                    onPress={() => {
                                        window.grecaptcha.ready(function() {
                                            window.grecaptcha.execute(recaptchaKey, {action: 'registration/email-resend'}).then(function(token)
                                            {
                                                fetch(apiUrl, {
                                                    method: 'POST',
                                                    cache: 'no-cache',
                                                    headers: {
                                                        'Content-Type': 'application/json'
                                                    },
                                                    body: JSON.stringify({
                                                        endpoint: 'user/add', v: apiVersion,
                                                        device_id: localStorage.getItem('deviceID'),
                                                        payload: {
                                                            email: userEmail,
                                                            country_code: userPhonePrefix,
                                                            mobile: userPhone,
                                                            first_name: userFirstName,
                                                            last_name: userLastName,
                                                            captcha: token,
                                                        }
                                                    }),
                                                })
                                                .then((response) => {
                                                    return response.text().then(function(text) {
                                                        const result = JSON.parse(text);
                                                        if ( result.error )
                                                        {
                                                            setCodeResentMsg(result.error);
                                                            setCodeResentStatus('warning');
                                                            setCodeResent(true);
                                                        }
                                                        else
                                                        {
                                                            setCodeResentMsg( getLabel('') );
                                                            sid_mobile = result.sid_mobile;
                                                            sid_email = result.sid_email;

                                                            setCodeResentMsg('Codes were resent to the email and mobile phone number you provided. If you still do not receive it, click the Back button and double-check the email you provided.');
                                                            setCodeResentStatus('success');
                                                            setCodeResent(true);
                                                        }
                                                    });
                                                })
                                                .catch(error => {
                                                    console.warn(error);
                                                    setCodeResentMsg('An Error Occurred');
                                                    setCodeResentStatus('warning');
                                                    setCodeResent(true);
                                                });
                                            });
                                        });
                                    }}
                                >
                                    <Span style={tw`text-sm text-white mt-6`}>{ getLabel('VERIFY_RESEND_CODE_LINK') }</Span>
                                </Pressable>
                            </View>
                        </View>
                    </AnimatedView>
                    */
                }
                { verifyingPhone && 
                    <AnimatedView style={phoneVerifyAnimationMain}>
                        <View style={tw`bg-blue h-full w-full p-5 md:p-8 items-center`}>
                            <View style={tw`h-full w-full md:w-80 justify-start md:justify-center`}>
                                { codeResent && 
                                    <Notification 
                                        style={tw`my-4 md:w-80`}
                                        type={codeResentStatus}
                                    >{codeResentMsg}</Notification>
                                }
                                { invalidPhone && ! codeResent &&
                                    <Notification 
                                        style={tw`mt-0 mb-6 md:w-80`}
                                        type="warning"
                                    >{invalidMsg}</Notification>
                                }
                                <Heading style={tw`text-white mt-0 md:mt-4 mb-7`} hlevel={2} >
                                    { getLabel('VERIFY_MOBILE_TITLE') }
                                </Heading>
                                <Span style={tw`text-white mb-7`}>
                                    { getLabel('VERIFY_MOBILE_TEXT') }
                                </Span>
                                <PinInput
                                    style={tw`w-full md:w-80`}
                                    styleInput={tw`bg-[#0360a3] text-white my-4 w-full border-transparent`}
                                    styleFocus={tw`border-white`}
                                    keyboardType="numeric"
                                    pattern="^\d{4}$"
                                    validateOnChange={true}
                                    validateGroup={phoneValidation}
                                    validatedValue={(value) => {setUserPhoneCode(value)}}
                                    required
                                />
                                <Pressable
                                    onPress={() => {
                                        window.grecaptcha.ready(function() {
                                            window.grecaptcha.execute(recaptchaKey, {action: 'registration/sms-resend'}).then(function(token)
                                            {
                                                fetch(apiUrl, {
                                                    method: 'POST',
                                                    cache: 'no-cache',
                                                    headers: {
                                                        'Content-Type': 'application/json'
                                                    },
                                                    body: JSON.stringify({
                                                        endpoint: 'user/add', v: apiVersion,
                                                        device_id: localStorage.getItem('deviceID'),
                                                        payload: {
                                                            email: userEmail,
                                                            country_code: userPhonePrefix,
                                                            mobile: userPhone,
                                                            first_name: userFirstName,
                                                            last_name: userLastName,
                                                            captcha: token,
                                                        }
                                                    }),
                                                })
                                                .then((response) => {
                                                    return response.text().then(function(text) {
                                                        const result = JSON.parse(text);
                                                        if ( result.error )
                                                        {
                                                            setCodeResentMsg(result.error);
                                                            setCodeResentStatus('warning');
                                                            setCodeResent(true);
                                                        }
                                                        else
                                                        {
                                                            setUserPhoneCode('');
                                                            setVerifyingPhone(true);
                                                            invalidatePhone(false);
                                                            // setVerifyingPhone(false);
                                                            // setUserEmailCode('');
                                                            // setVerifyingEmail(true);

                                                            sid_mobile = result.sid_mobile;
                                                            // sid_email = result.sid_email;

                                                            setCodeResentMsg( getLabel('VERIFY_RESEND_CONFIRM_MOBILE') );
                                                            setCodeResentStatus('success');
                                                            setCodeResent(true);
                                                        }
                                                    });
                                                })
                                                .catch(error => {
                                                    console.warn(error);
                                                    setCodeResentMsg('An Error Occurred');
                                                    setCodeResentStatus('warning');
                                                    setCodeResent(true);
                                                });
                                            });
                                        });
                                    }}
                                >
                                    <Span style={tw`text-sm text-white mt-6`}>{ getLabel('VERIFY_RESEND_CODE_LINK') }</Span>
                                </Pressable>
                            </View>
                        </View>
                    </AnimatedView>
                }
                { verified && 
                    <AnimatedView style={verifiedAnimationMain}>
                        <View style={tw`bg-blue h-full w-full p-5 md:p-8 items-center`}>
                            <View style={tw`h-full w-full md:w-80 justify-start md:justify-center`}>
                                <Image
                                    accessibilityHidden={true}
                                    source={{ uri: require('../svg/verified.svg') }}
                                    resizeMode="contain"
                                    style={tw`h-16 w-16`}
                                />
                                <Heading style={tw`text-white mt-9 mb-7`} hlevel={2} >
                                    Account verified!
                                </Heading>
                                <Span style={tw`text-white`}>
                                    Your account has been verified successfully and you are now logged in.
                                </Span>
                            </View>
                        </View>
                    </AnimatedView>
                }
                <AnimatedView style={doingTransitionAnimationMain}>
                    <View style={tw`bg-blue h-full w-full p-5 md:p-8 items-center`}></View>
                </AnimatedView>
            </ScrollView>
            <View style={tw`flex-row p-5 md:p-8 mb-7 mg:mb-0`}>
                { /* ! verifyingEmail && ! verifyingPhone && ! verified && */
                    ! verifyingPhone && ! verified &&
                    <Button
                        style={tw`bg-white w-full mx-auto md:w-80 border border-transparent`}
                        styleText={tw`text-blue`}
                        styleHover={tw`bg-blue border-white`}
                        styleHoverText={tw`text-white`}
                        onPress={() => { 
                            setDoingTransition(true);
                            setTimeout(() => 
                            {
                                // setVerifyingEmail(true); 
                                setVerifyingPhone(true); 
                                setDoingTransition(false);
                            }, 250);
                        }}
                        title="Continue"
                    />
                }
                { /* verifyingEmail &&
                    <Button
                        title="Next"
                        style={tw`bg-white w-full mx-auto md:w-80 border border-transparent`}
                        styleText={tw`text-blue`}
                        styleHover={tw`bg-blue border-white`}
                        styleHoverText={tw`text-white`}
                        styleLoading={tw`bg-blue border-white`}
                        validateGroup={emailValidation}
                        onPress={() => { 
                            doEmailValidation(true);
                            setTimeout(() => 
                            {
                                if ( userEmailCode )
                                {
                                    setDoingTransition(true);
                                    setTimeout(() => 
                                    {
                                        invalidateEmail(false);
                                        setVerifyingEmail(false);
                                        setVerifyingPhone(true);
                                        setDoingTransition(false);
                                        doEmailValidation(false);
                                    }, 250);
                                }
                                else 
                                {
                                    invalidateEmail(true);
                                    doEmailValidation(false);
                                }
                            }, 250);
                        }}
                    />
                */ }
                { verifyingPhone &&
                    <Button
                        title={ getLabel('VERIFY') }
                        style={tw`bg-white w-full mx-auto md:w-80 border border-transparent`}
                        styleText={tw`text-blue`}
                        styleHover={tw`bg-blue border-white`}
                        styleHoverText={tw`text-white`}
                        styleLoading={tw`bg-blue border-white`}
                        validateGroup={phoneValidation}
                        onPress={() => { 
                            doPhoneValidation(true);
                            setTimeout(() => 
                            {
                                if ( userPhoneCode )
                                {
                                    window.grecaptcha.ready(function() {
                                        window.grecaptcha.execute(recaptchaKey, {action: 'registration/verify'}).then(function(token)
                                        {
                                            fetch(apiUrl, {
                                                method: 'POST',
                                                cache: 'no-cache',
                                                headers: {
                                                    'Content-Type': 'application/json'
                                                },
                                                body: JSON.stringify({
                                                    endpoint: 'user/add', v: apiVersion,
                                                    device_id: localStorage.getItem('deviceID'),
                                                    payload: {
                                                       email: userEmail,
                                                       country_code: userPhonePrefix,
                                                       mobile: userPhone,
                                                       first_name: userFirstName,
                                                       last_name: userLastName,
                                                       sid_mobile: sid_mobile,
                                                       code_mobile: userPhoneCode,
                                                       device_id: localStorage.getItem('deviceID'),
                                                       // sid_email: sid_email,
                                                       // code_email: userEmailCode,
                                                       lang_id: userLangID,
                                                       captcha: token,
                                                    }
                                                }),
                                            })
                                            .then((response) => {
                                                return response.text().then(function(text) {
                                                    const result = JSON.parse(text);
                                                    if ( result.error )
                                                    {
                                                        setCodeResent(false);
                                                        setInvalidMsg(result.error);
                                                        setDoingTransition(true);
                                                        setTimeout(() =>
                                                        {
                                                            /*
                                                            // kick back to verify email
                                                            doEmailValidation(false);
                                                            doPhoneValidation(false);
                                                            */
                                                            // kick back to verify phone
                                                            doPhoneValidation(false);

                                                            setUserPhoneCode('');
                                                            setVerifyingPhone(true);
                                                            // setUserEmailCode('');
                                                            // setVerifyingEmail(true);

                                                            setDoingTransition(false);
                                                            invalidatePhone(true);
                                                        }, 250);
                                                    }
                                                    else
                                                    {
                                                        // console.log('registration successfull, logging in user...');
                                                        if ( typeof window.AF === 'function' ) {
                                                            window.AF('pba', 'event', {eventType: 'EVENT', eventValue: {}, eventName: 'af_complete_registration'});
                                                        }
                                                        if ( typeof window.singularSdk === 'function' ) {
                                                            window.singularSdk.login(result['obj_user']['id']);
                                                            window.singularSdk.event("sng_complete_registration");
                                                        }
                                                        if ( typeof window.gtag === 'function' ) {
                                                            window.gtag('event', 'conversion', {'send_to': 'AW-11018297141/5WoyCNzIrfUYELW-94Up'});
                                                        }
                                                        localStorage.setItem( 'clientKey', result['x-bearer-token'] );

                                                        setDoingTransition(true);
                                                        setShownIntro(false);
                                                        setLoggedIn(true);
                                                        setTimeout(()=>{doPhoneValidation(false);setDoingTransition(false);}, 990);
                                                    }
                                                });
                                            })
                                            .catch(error => {
                                                console.warn(error);

                                                setCodeResent(false);
                                                setInvalidMsg(error);
                                                setDoingTransition(true);
                                                setTimeout(() =>
                                                {
                                                    /*
                                                    // kick back to verify email
                                                    doEmailValidation(false);
                                                    doPhoneValidation(false);
                                                    */
                                                    // kick back to verify phone
                                                    doPhoneValidation(false);

                                                    setUserPhoneCode('');
                                                    setVerifyingPhone(false);
                                                    // setUserEmailCode('');
                                                    // setVerifyingEmail(true);

                                                    setDoingTransition(false);
                                                    invalidatePhone(true);
                                                }, 250);
                                            });
                                        });
                                    });
                                }
                                else 
                                {
                                    setCodeResent(false);
                                    invalidatePhone(true);
                                    doPhoneValidation(false);
                                }
                            }, 250);
                        }}
                    />
                    /* <Button
                        style={tw`bg-white w-full mx-auto md:w-80 border border-transparent`}
                        styleText={tw`text-blue`}
                        styleHover={tw`bg-blue border-white`}
                        styleHoverText={tw`text-white`}
                        styleLoading={tw`bg-blue border-white`}
                        validateGroup={phoneValidation}
                        onPress={() => { 
                            doPhoneValidation(true);
                            setTimeout(() => 
                            {
                                if ( userPhoneCode )
                                {
                                    setDoingTransition(true);
                                    setTimeout(() => 
                                    {
                                        invalidatePhone(false);
                                        setVerifyingPhone(false);
                                        setVerified(true);
                                        setDoingTransition(false);
                                        doPhoneValidation(false);
                                    }, 250);
                                }
                                else 
                                {
                                    invalidatePhone(true);
                                    doPhoneValidation(false);
                                }
                            }, 250);
                        }}
                        title="Next"
                    /> */
                }
                { verified &&
                    <Button
                        style={tw`bg-white w-full mx-auto md:w-80 border border-transparent`}
                        styleText={tw`text-blue`}
                        styleHover={tw`bg-blue border-white`}
                        styleHoverText={tw`text-white`}
                        onPress={() => { setShownIntro(false); setLoggedIn(true); }}
                        title="Continue"
                    />
                }
            </View>
            <Span style={tw`absolute bottom-4 left-6 text-white/50 text-xs pointer-events-none`}>{version}</Span>
        </View>
    );
}

export default VerifyAccountView;
