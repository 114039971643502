import React from 'react';
import { View } from 'react-native';
import { create, useDeviceContext } from 'twrnc';
import { GlobalContext } from '../global';

function RegisterView ({navigation})
{
    const tw = create( require(`../../tailwind.config.js`) );
    useDeviceContext(tw);

    let {
        loggedIn,
    } = React.useContext(GlobalContext);

    React.useEffect(()=>
    {
        if ( ! loggedIn ) { navigation.navigate('loading', {createAccount: true}); }
        else { navigation.replace('yachts'); }
    },[loggedIn, navigation]);

    return (
    <View label="welcome" style={tw`bg-blue min-h-screen`}>
    </View>
    );
}

export default RegisterView;
