import React, {useState} from 'react';
import { Image, View } from 'react-native';
import { useSpring, animated, config } from 'react-spring';
import { create, useDeviceContext } from 'twrnc';
import { Span } from '../elements';
import { goPrevScreen, makeCopyright, fetchLabels, getCurrentRoute } from '../helpers';
import { GlobalContext } from '../global';


function LoadingView ({route, navigation})
{
    const tw = create( require(`../../tailwind.config.js`) );
    const AnimatedView = animated(View);
    useDeviceContext(tw);

    let {
        version,
        apiUrl,
        apiVersion,
        labels,
        loggedIn, setLoggedIn,
        navigationRef,
    } = React.useContext(GlobalContext);

    const copyright = makeCopyright(version);

    const [isLoading, setIsLoading] = useState(true);

    React.useEffect(()=>
    {        
        let thisLastRoute = navigation.getState() ? navigation.getState().routes[navigation.getState().routes.length - 2]?.name : 'yachts';
        let thisRoute = navigation.getState() ? navigation.getState().routes[navigation.getState().routes.length - 1]?.name : false;
        console.log(thisRoute);

        if ( loggedIn )
        {
            console.log('verifying client key...');
            fetch(apiUrl, {
                method: 'POST',
                cache: 'no-cache',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    endpoint: 'system/session/validate', v: apiVersion,
                    client_key: localStorage.getItem('clientKey'),
                    device_id: localStorage.getItem('deviceID')
                }),
            })
            .then((response) => {
                return response.text().then(function(text) {
                    const result = JSON.parse(text);
                    if ( result.error )
                    {
                        if ( 'account' === thisLastRoute ) { localStorage.setItem('attempted-view', thisLastRoute); } // only applies to account for now
                        console.warn(result.error);
                        console.log('client not valid; logging out');
                        let deviceID = localStorage.getItem('deviceID');
                        let last_ver = localStorage.getItem('last_ver');
                        let labels_ver = localStorage.getItem('labels_ver');
                        let labels_values = localStorage.getItem('labels');
                        let filterStates = localStorage.getItem('filterStates');
                        let attempted_view = localStorage.getItem('attempted-view');
                        let attempted_view_args = localStorage.getItem('attempted-view-args');
                        localStorage.clear();
                        if ( last_ver ) { localStorage.setItem( 'last_ver', labels_ver ); }
                        if ( labels_ver ) { localStorage.setItem( 'labels_ver', labels_ver ); }
                        if ( deviceID ) { localStorage.setItem( 'deviceID', deviceID ); }
                        if ( labels_values ) { localStorage.setItem( 'labels', labels_values ); }
                        if ( filterStates ) { localStorage.setItem( 'filterStates', filterStates ); }
                        if ( attempted_view ) { localStorage.setItem( 'attempted-view', attempted_view ); }
                        if ( attempted_view_args ) { localStorage.setItem( 'attempted-view-args', attempted_view_args ); }
                        setLoggedIn(false);
                        if ( labels ) {
                            if ( 'undefined' != typeof route.params ) { navigation.navigate('welcome', {createAccount: true}); } else { navigation.navigate('welcome'); }
                            setIsLoading(false);
                        }
                    }
                    else
                    {
                        // setLoggedIn(true);
                        if ( 'login' === thisRoute || 'verify' === thisRoute ) { return; } // if we're logging in/Registering let loginCodeView/verifyAccountView be responsible for navigation update
                        if ( labels ) {
                            goPrevScreen(navigation, 'yachts');
                            setIsLoading(false);
                        }
                    }
                });
            })
            .catch(error => {
                if ( 'account' === thisLastRoute ) { localStorage.setItem('attempted-view', thisLastRoute); } // only applies to account for now
                console.warn(error);
                console.log('client not valid; logging out');
                setTimeout(() => {
                    setLoggedIn(false);
                    let deviceID = localStorage.getItem('deviceID');
                    let last_ver = localStorage.getItem('last_ver');
                    let labels_ver = localStorage.getItem('labels_ver');
                    let labels_values = localStorage.getItem('labels');
                    let filterStates = localStorage.getItem('filterStates');
                    let attempted_view = localStorage.getItem('attempted-view');
                    let attempted_view_args = localStorage.getItem('attempted-view-args');
                    localStorage.clear();
                    if ( last_ver ) { localStorage.setItem( 'last_ver', labels_ver ); }
                    if ( labels_ver ) { localStorage.setItem( 'labels_ver', labels_ver ); }
                    if ( deviceID ) { localStorage.setItem( 'deviceID', deviceID ); }
                    if ( labels_values ) { localStorage.setItem( 'labels', labels_values ); }
                    if ( filterStates ) { localStorage.setItem( 'filterStates', filterStates ); }
                    if ( attempted_view ) { localStorage.setItem( 'attempted-view', attempted_view ); }
                    if ( attempted_view_args ) { localStorage.setItem( 'attempted-view-args', attempted_view_args ); }
                    if ( labels ) {
                        if ( 'undefined' != typeof route.params ){ navigation.navigate('welcome', {createAccount: true}) } else { navigation.navigate('welcome'); }
                        setIsLoading(false);
                    }
                }, 750);
            });
        }
        else{
            console.log('no client key found; logging out');
            setTimeout(() => {
                if ( 'account' === thisLastRoute ) { localStorage.setItem('attempted-view', thisLastRoute); } // only applies to account for now
                setLoggedIn(false);
                let deviceID = localStorage.getItem('deviceID');
                let last_ver = localStorage.getItem('last_ver');
                let labels_ver = localStorage.getItem('labels_ver');
                let labels_values = localStorage.getItem('labels');
                let filterStates = localStorage.getItem('filterStates');
                let attempted_view = localStorage.getItem('attempted-view');
                let attempted_view_args = localStorage.getItem('attempted-view-args');
                localStorage.clear();
                if ( last_ver ) { localStorage.setItem( 'last_ver', labels_ver ); }
                if ( labels_ver ) { localStorage.setItem( 'labels_ver', labels_ver ); }
                if ( deviceID ) { localStorage.setItem( 'deviceID', deviceID ); }
                if ( labels_values ) { localStorage.setItem( 'labels', labels_values ); }
                if ( filterStates ) { localStorage.setItem( 'filterStates', filterStates ); }
                if ( attempted_view ) { localStorage.setItem( 'attempted-view', attempted_view ); }
                if ( attempted_view_args ) { localStorage.setItem( 'attempted-view-args', attempted_view_args ); }
                if ( labels ) {
                    if ( 'undefined' != typeof route.params ){ navigation.navigate('welcome', {createAccount: true}) } else { navigation.navigate('welcome'); }
                    setIsLoading(false);
                }
            }, 750);
        }
    },[navigation, loggedIn, labels] );

    let loadingAnimation = useSpring(
    {
        from: {
            willChange: 'opacity',
            opacity: 0,
        },
        to: {           
            opacity: isLoading ? 1 : 0,
        },
        delay: isLoading ? 250 : 0,
        config: config.default,
    });

    return (
    <View label="loading" style={tw`bg-blue min-h-screen`}>
        <View style={tw`bg-blue h-full w-full justify-center`}>
            <Image
                accessibilityLabel="YachtWave logo"
                source={{ uri: require('../svg/LogoWhite.svg') }}
                resizeMode="contain"
                style={tw`h-28 lg:h-40`}
            />
            <AnimatedView style={loadingAnimation}>
                <Image
                    accessibilityHidden={true}
                    source={{ uri: require('../svg/loader.svg') }}
                    resizeMode="contain"
                    style={tw`absolute h-9 w-9 left-1/2 top-1/2 -translate-x-1/2 mt-7`}
                />
            </AnimatedView>
        </View>

        <Span style={tw`hidden md:flex md:w-80 absolute bottom-5 left-6 text-white/50 text-xs pointer-events-none`}>{copyright}</Span>
    </View>
    );
}

export default LoadingView;
